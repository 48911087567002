/* eslint-disable no-use-before-define */
/* eslint-disable import/order */
import {
  Grid,
  Typography,
  useMediaQuery,
  Box,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef } from "react";
import CardCommon from "../../../card/CardCommon";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import CancelIcon from "@material-ui/icons/Cancel";
import { CustomTheme } from "../../../../types/customTheme";
import MultipleSelectorTextField from "../../selector/MultipleSelectorTextField";
import _ from "lodash";
import DatePicker from "../../datePicker/DatePicker";
import MobileDatePicker from "../../mobileDatePicker/MobileDatePicker";
import DateRangePickerCustomer from "../../DateRangePickerCustomer";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    rangeTextFieldLeftSide: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      backgroundColor: theme.palette.background.entity_highlight_background,
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
      [`& fieldset`]: {
        cursor: "pointer",
        border: "none",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    rangeTextFieldRideSide: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      backgroundColor: theme.palette.background.entity_highlight_background,
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
      [`& fieldset`]: {
        cursor: "pointer",
        border: "none",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    textField: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      backgroundColor: theme.palette.background.entity_highlight_background,
      [`& fieldset`]: {
        borderRadius: "10px",
        border: `1px solid ${theme.palette.background.entity_border}`,
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.background.entity_border}`,
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.background.entity_border}`,
      },
    },
    shiftDateStyle: {
      display: "flex",
      placeItems: "center",
      alignItems: "center",
      justifyContent: "end",
      paddingRight: "12px",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "start",
        paddingRight: "0px",
        paddingBottom: "12px",
        paddingLeft: "16px",
      },
    },
    someTextField: {
      minHeight: "34px",
    },
    cancelIconStyle: {
      fontSize: "18px",
      color: "gray",
      cursor: "pointer",
      marginLeft: "8px",
    },
    tabPanel: {
      backgroundColor: theme.palette.background.default,
    },
    datePickerStyle: {
      zIndex: 6,
      marginTop: "6px",
      marginLeft: "18px",
    },
  }),
);

const useClickOutside = (handler: any) => {
  const domNode: any = useRef();
  useEffect(() => {
    const maybeHandler = (event: any) => {
      if (!domNode?.current?.contains(event.target)) {
        handler();
      }
    };
    document.addEventListener("mousedown", maybeHandler);
    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

export interface Props {
  locationSelectorList: any;
  handleAllSelectedList: any;
  getLocationSelectorTypingList: any;
  searchLocationName: any;
  setSearchLocationName: any;
  locationSelectedList: any;
  handleChangeCreatedDateRange: any;
  createdSelectionRange: any;
  createdStartDate: any;
  createdEndDate: any;
  handleChangeLastOrderDateRange: any;
  lastOrderSelectionRange: any;
  lastOrderStartDate: any;
  lastOrderEndDate: any;
  handleClearLastOrderDateRange: any;
  handleClearFirstOrderDateRange: any;
  marketingSelectedList: any;
}

const FilterCard: React.FunctionComponent<Props> = ({
  locationSelectorList,
  handleAllSelectedList,
  getLocationSelectorTypingList,
  searchLocationName,
  setSearchLocationName,
  locationSelectedList,
  handleChangeCreatedDateRange,
  createdSelectionRange,
  createdStartDate,
  createdEndDate,
  handleChangeLastOrderDateRange,
  lastOrderSelectionRange,
  lastOrderStartDate,
  lastOrderEndDate,
  handleClearLastOrderDateRange,
  handleClearFirstOrderDateRange,
  marketingSelectedList,
}) => {
  const [currentDatePickerOpen, setCurrentDatePickerOpen] =
    React.useState(false);
  const [lastOrderDatePickerOpen, setLastOrderDatePickerOpen] =
    React.useState(false);

  /* After clicking outside the date range selector is closed. */
  const domNodeCurrentDatePicker = useClickOutside(() => {
    setCurrentDatePickerOpen(false);
  });

  const domNodeLastOrderDatePicker = useClickOutside(() => {
    setLastOrderDatePickerOpen(false);
  });

  const horizontalMaxWidth = useMediaQuery("(max-width: 960px)");
  const classes = useStyles();
  const maxWidth = useMediaQuery("(max-width: 618px)");
  return (
    <div style={{ marginBottom: "32px" }}>
      <CardCommon backgroundColor={"entity_background"}>
        <div style={{ margin: "12px" }}>
          <CardCommon backgroundColor={"entity_highlight_background"}>
            <Grid container>
              <Grid item xs={12} style={{ padding: "6px 0px 14px 2px" }}>
                <MultipleSelectorTextField
                  selectorList={locationSelectorList}
                  handleSelectedList={handleAllSelectedList}
                  getLocationSelectorTypingList={getLocationSelectorTypingList}
                  searchLocationName={searchLocationName}
                  setSearchLocationName={setSearchLocationName}
                  selectedList={locationSelectedList}
                />
              </Grid>
            </Grid>
          </CardCommon>
        </div>
        <Grid
          container
          spacing={2}
          style={{
            marginBottom: "10px",
          }}
        >
          <Grid
            ref={domNodeLastOrderDatePicker}
            item
            md={3}
            xs={12}
            sm={6}
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              marginTop: "12px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ display: "flex" }}>
                <Typography style={{ fontWeight: "bold" }} variant="body2">
                  Last Order Date Range
                </Typography>
                {lastOrderStartDate && lastOrderEndDate && (
                  <CancelIcon
                    className={classes.cancelIconStyle}
                    onClick={handleClearLastOrderDateRange}
                  />
                )}
              </div>
            </div>
            <DateRangePickerCustomer
              open={lastOrderDatePickerOpen}
              setOpen={setLastOrderDatePickerOpen}
              startDate={lastOrderStartDate}
              endDate={lastOrderEndDate}
              handleChangeDateRange={handleChangeLastOrderDateRange}
              dateSelectionRange={lastOrderSelectionRange}
              horizontalMaxWidth={horizontalMaxWidth}
            />
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
            sm={6}
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              marginTop: "12px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ display: "flex" }}>
                <Typography style={{ fontWeight: "bold" }} variant="body2">
                  First Order Date Range
                </Typography>
                {createdStartDate && createdEndDate && (
                  <CancelIcon
                    className={classes.cancelIconStyle}
                    onClick={handleClearFirstOrderDateRange}
                  />
                )}
              </div>
            </div>
            <DateRangePickerCustomer
              open={currentDatePickerOpen}
              setOpen={setCurrentDatePickerOpen}
              startDate={createdStartDate}
              endDate={createdEndDate}
              handleChangeDateRange={handleChangeCreatedDateRange}
              dateSelectionRange={createdSelectionRange}
              horizontalMaxWidth={horizontalMaxWidth}
            />
          </Grid>
          <div className={classes.datePickerStyle}>
            <div ref={domNodeCurrentDatePicker}>
              {currentDatePickerOpen &&
                (!maxWidth ? (
                  <Box
                    // boxShadow={3}
                    borderRadius={30}
                    style={{
                      borderRadius: 30,
                      // border: `1px solid ${theme.palette.background.entity_border}`,
                    }}
                  >
                    <DatePicker
                      handleChangeDateRange={handleChangeCreatedDateRange}
                      dateSelectionRange={createdSelectionRange}
                      horizontalMaxWidth={horizontalMaxWidth}
                      setOpen={setCurrentDatePickerOpen}
                    />{" "}
                  </Box>
                ) : (
                  <CardCommon backgroundColor={"entity_highlight_background"}>
                    <MobileDatePicker
                      handleChangeDateRange={handleChangeCreatedDateRange}
                      dateSelectionRange={createdSelectionRange}
                      setOpen={setCurrentDatePickerOpen}
                    />
                  </CardCommon>
                ))}
            </div>
            <div ref={domNodeLastOrderDatePicker}>
              {lastOrderDatePickerOpen &&
                (!maxWidth ? (
                  <Box
                    // boxShadow={3}
                    borderRadius={30}
                    style={{
                      borderRadius: 30,
                      // border: `1px solid ${theme.palette.background.entity_border}`,
                    }}
                  >
                    <DatePicker
                      handleChangeDateRange={handleChangeLastOrderDateRange}
                      dateSelectionRange={lastOrderSelectionRange}
                      horizontalMaxWidth={horizontalMaxWidth}
                      setOpen={setLastOrderDatePickerOpen}
                    />{" "}
                  </Box>
                ) : (
                  <CardCommon backgroundColor={"entity_highlight_background"}>
                    <MobileDatePicker
                      handleChangeDateRange={handleChangeLastOrderDateRange}
                      dateSelectionRange={lastOrderSelectionRange}
                      setOpen={setLastOrderDatePickerOpen}
                    />
                  </CardCommon>
                ))}
            </div>
          </div>
          {/* <Grid
            item
            md={3}
            xs={12}
            sm={6}
            style={{
              marginTop: "12px",
            }}
          >
            <Typography style={{ fontWeight: "bold" }} variant="body2">
              Marketing
            </Typography>
            <MultipleSelectorColor
              selectorTextFieldName={"type"}
              disabled={false}
              selectorList={marketingSelectorList}
              handleAllSelectedList={handleAllSelectedList}
              selectedList={marketingSelectedList}
            />
          </Grid> */}
        </Grid>
      </CardCommon>
    </div>
  );
};

export default FilterCard;
