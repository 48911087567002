import React, { useEffect, useState } from "react";
import { useRouteMatch, useLocation } from "react-router";
import { Box } from "@material-ui/core";
import _ from "lodash";

import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import { getCookie } from "../../../../utils/cookies";
import { eatprestoOrderFilterObject } from "../../../../utils/consts/list";
import SaleReport from "../../../../components/common/SaleReport";
import { fetchAllDailySaleReportInfoInEatpresto } from "../../../../services/eatprestoApp/dailySaleReportService";
import moment from "moment";
import DailyOrderReportTab from "./DailyOrderReportTab";

export interface DailyOrderReportProps {}

/**
 * DailyOrderReport Component
 *
 * The DailyOrderReport component is responsible for displaying the payment summary report
 * for a specific location within the PrestoExpress application. It utilizes Material-UI
 * components and various utility functions to fetch and display payment-related information.
 */
const DailyOrderReport: React.FunctionComponent<DailyOrderReportProps> = () => {
  const [orderReportNodeListDaily, setOrderReportNodeListDaily] = useState<any>(
    [],
  );
  const [orderReportNodeListWeekly, setOrderReportNodeListWeekly] =
    useState<any>([]);
  const [orderReportNodeListMonthly, setOrderReportNodeLisMonthly] =
    useState<any>([]);
  const [error, setError] = useState("");
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [filterDetails, setFilterDetails] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterData] = useState("");
  const [basedOnShift, setBasedOnShift] = useState(false);
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [locationSelectedList, setLocationSelectedList] = useState([]);

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");
  const { search } = useLocation();
  const params: any = new URLSearchParams(search);

  const convertData = (input: any, orderTimeStart: any, orderTimeEnd: any) => {
    const output: any = [];
    const dateMap: any = {};
    const currentDate = new Date(orderTimeStart);
    const finalDate = new Date(orderTimeEnd);

    while (currentDate <= finalDate) {
      const dateString = moment(currentDate).format("YYYY-MM-DD");
      dateMap[dateString] = {
        date: dateString,
        orders: 0,
        total: 0,
        cash: 0,
        card: 0,
        takeout: 0,
        delivery: 0,
      };

      currentDate.setDate(currentDate.getDate() + 1);
    }

    input.forEach((order: any) => {
      const {
        orderDate,
        numberOfOrders,
        totalOrderAmount,
        paymentType,
        deliveryType,
      } = order;

      if (!dateMap[orderDate]) {
        dateMap[orderDate] = {
          date: orderDate,
          orders: 0,
          total: 0,
          cash: 0,
          card: 0,
          takeout: 0,
          delivery: 0,
        };
      }

      dateMap[orderDate].orders += numberOfOrders;
      dateMap[orderDate].total += totalOrderAmount;

      if (paymentType === "cash") {
        dateMap[orderDate].cash += totalOrderAmount;
      } else if (paymentType === "card") {
        dateMap[orderDate].card += totalOrderAmount;
      }

      if (deliveryType === "takeout") {
        dateMap[orderDate].takeout += totalOrderAmount;
      } else if (deliveryType === "delivery") {
        dateMap[orderDate].delivery += totalOrderAmount;
      }
    });
    for (const date in dateMap) {
      const item = dateMap[date];
      if (parseFloat(item.total) === 0) {
        // Format numerical values to two decimal places
        item.orders = "-";
        item.total = "-";
        item.cash = "-";
        item.card = "-";
        item.takeout = "-";
        item.delivery = "-";
      } else {
        // Format numerical values to two decimal places
        item.total = item.total === 0 ? "0" : item.total.toFixed(2);
        item.cash = item.cash === 0 ? "0" : item.cash.toFixed(2);
        item.card = item.card === 0 ? "0" : item.card.toFixed(2);
        item.takeout = item.takeout === 0 ? "0" : item.takeout.toFixed(2);
        item.delivery = item.delivery === 0 ? "0" : item.delivery.toFixed(2);
      }
      output.push(item);
    }

    // Sort the output array by date
    output.sort(
      (a: any, b: any) =>
        new Date(a.date).getTime() - new Date(b.date).getTime(),
    );

    return output;
  };

  const convertToWeeklySummary = (data: any) => {
    const weeks: any = {};
    data.forEach((item: any) => {
      const date = new Date(item.date);
      const currentYear = new Date(item.date).getFullYear();

      const weekNumber = `${currentYear} ${getISOWeek(date)}${
        getISOWeek(date) === 1
          ? "st"
          : getISOWeek(date) === 2
          ? "nd"
          : getISOWeek(date) === 3
          ? "rd"
          : "th"
      } Week`;
      if (!weeks[weekNumber]) {
        weeks[weekNumber] = {
          date: weekNumber,
          orders: 0,
          total: 0,
          cash: 0,
          card: 0,
          takeout: 0,
          delivery: 0,
        };
      }
      if (item.total !== "-") {
        weeks[weekNumber].orders += item.orders;
        weeks[weekNumber].total += parseFloat(item.total);
        weeks[weekNumber].cash += parseFloat(item.cash);
        weeks[weekNumber].card += parseFloat(item.card);
        weeks[weekNumber].takeout += parseFloat(item.takeout);
        weeks[weekNumber].delivery += parseFloat(item.delivery);
      }
    });

    // Format numerical values to two decimal places
    for (const week in weeks) {
      if (parseFloat(weeks[week].total) === 0) {
        weeks[week].orders = "-";
        weeks[week].total = "-";
        weeks[week].cash = "-";
        weeks[week].card = "-";
        weeks[week].takeout = "-";
        weeks[week].delivery = "-";
      } else {
        weeks[week].total =
          weeks[week].total === 0 ? "0" : weeks[week].total.toFixed(2);
        weeks[week].cash =
          weeks[week].cash === 0 ? "0" : weeks[week].cash.toFixed(2);
        weeks[week].card =
          weeks[week].card === 0 ? "0" : weeks[week].card.toFixed(2);
        weeks[week].takeout =
          weeks[week].takeout === 0 ? "0" : weeks[week].takeout.toFixed(2);
        weeks[week].delivery =
          weeks[week].delivery === 0 ? "0" : weeks[week].delivery.toFixed(2);
      }
    }

    return Object.values(weeks);
  };

  const getISOWeek = (date: any) => {
    const temp: any = new Date(date);
    const dayNumber = (temp.getDay() + 6) % 7; // Adjust day number to start from Monday
    temp.setDate(temp.getDate() - dayNumber + 3); // Adjust date to nearest Thursday (January 4th)

    const startOfYear: any = new Date(temp.getFullYear(), 0, 1);
    const weekNumber = Math.floor(
      (temp - startOfYear) / (24 * 60 * 60 * 1000 * 7) + 1,
    );

    return weekNumber;
  };

  const groupByMonth = (data: any) => {
    const output: any = {};

    data.forEach((item: any) => {
      const [year, month] = item.date.split("-");

      const monthName = moment(item.date).format("MMMM");

      const key = `${year}-${month}`;

      if (!output[key]) {
        output[key] = {
          date: `${year} ${monthName}`,
          orders: 0,
          total: 0,
          cash: 0,
          card: 0,
          takeout: 0,
          delivery: 0,
        };
      }

      if (item.total !== "-") {
        output[key].orders += item.orders;
        output[key].total += parseFloat(item.total);
        output[key].cash += parseFloat(item.cash);
        output[key].card += parseFloat(item.card);
        output[key].takeout += parseFloat(item.takeout);
        output[key].delivery += parseFloat(item.delivery);
      }
    });

    // Format numerical values to two decimal places
    for (const month in output) {
      if (parseFloat(output[month].total) === 0) {
        output[month].orders = "-";
        output[month].total = "-";
        output[month].cash = "-";
        output[month].card = "-";
        output[month].takeout = "-";
        output[month].delivery = "-";
      } else {
        output[month].total =
          output[month].total === 0 ? "0" : output[month].total.toFixed(2);
        output[month].cash =
          output[month].cash === 0 ? "0" : output[month].cash.toFixed(2);
        output[month].card =
          output[month].card === 0 ? "0" : output[month].card.toFixed(2);
        output[month].takeout =
          output[month].takeout === 0 ? "0" : output[month].takeout.toFixed(2);
        output[month].delivery =
          output[month].delivery === 0
            ? "0"
            : output[month].delivery.toFixed(2);
      }
    }

    return Object.values(output);
  };

  const handleOrderMonthlyWeeklyYearly = (
    response: any,
    orderTimeStart: any,
    orderTimeEnd: any,
  ) => {
    if (!_.isEmpty(response)) {
      const output = convertData(response, orderTimeStart, orderTimeEnd);
      const outputWeekly = convertToWeeklySummary(output);
      const outputMonthly = groupByMonth(output);
      setOrderReportNodeListWeekly(outputWeekly);
      setOrderReportNodeListDaily(output);
      setOrderReportNodeLisMonthly(outputMonthly);
    } else {
      setOrderReportNodeListWeekly([]);
      setOrderReportNodeListDaily([]);
      setOrderReportNodeLisMonthly([]);
    }

    setIsLoading(false);
  };

  /**
   * Asynchronous function to fetch payment report information based on filter criteria.
   * It updates the component state with the fetched data or handles errors appropriately.
   */
  const getOrderReportInfo = async (filter: any, basedOnShift: boolean) => {
    // Clear the existing payment report node list
    setOrderReportNodeListDaily([]);
    try {
      // Fetch payment report information from the server
      const response = await fetchAllDailySaleReportInfoInEatpresto(
        idToken,
        match.params.locationId,
        filter,
      );
      handleOrderMonthlyWeeklyYearly(
        response.data.data,
        filter.orderTimeStart,
        filter.orderTimeEnd,
      );
    } catch (err) {
      setIsLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Fetches and updates the location selector list based on the provided search name.
   *
   * @param {string} searchName - The search name used for filtering locations.
   */
  const getAllFilterLocation = async (searchName: any) => {
    try {
      // Fetch all filter locations using the provided search name
      const response = await fetchAllFilterLocations(searchName);

      // Process the fetched data to create a list of
      if (!_.isEmpty(response.data.data)) {
        const locationList = response.data.data.map((location: any) => ({
          id: location.id,
          label: location.businessDisplayName,
        }));

        // Update the location selector list and set the flag indicating location information is retrieved
        setLocationSelectorList(locationList);
      } else {
        setLocationSelectorList([]);
      }

      setIsGetLocationInfo(true);
    } catch (error) {
      // Handle errors by setting an error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * useEffect hook to set the document title and fetch initial location filter data.
   * It runs only once when the component mounts.
   */
  useEffect(() => {
    // Set the document title for the page
    document.title = "Eatpresto - Daily Orders Report";

    // Fetch initial location filter data with an empty searchName
    getAllFilterLocation("");
  }, []);

  /**
   * Handles the filter data and triggers the update of the payment report.
   */
  const handleFilterData = (filterData: any, basedOnShift: boolean) => {
    setIsLoading(true);

    // Fetch and update the payment report information based on the provided filter data and shift flag
    getOrderReportInfo(filterData, basedOnShift);

    // Set the filter data and shift flag
    setFilterData(filterData);
    setBasedOnShift(basedOnShift);
  };

  /**
   * Handles the click event on the text, opens the filter card, and scrolls to the top.
   */
  const handleOnClickText = () => {
    setOpenFilterCard(true);

    // Scroll to the top of the window with smooth behavior
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /*Get location list API call after typing. */
  const handleLocationSelectorTypingList = (searchName: any) => {};

  return (
    <>
      <Box>
        <SaleReport
          handleFilterData={handleFilterData}
          locationSelectorList={locationSelectorList}
          handleLocationSelectorTypingList={handleLocationSelectorTypingList}
          isGetLocationInfo={isGetLocationInfo}
          setFilterDetails={setFilterDetails}
          availableFilter={eatprestoOrderFilterObject}
          isOpenSkeletonLoading={isLoading}
          nodeList={orderReportNodeListDaily}
          filterDetails={filterDetails}
          title={""}
          isChart={false}
          filterTitle="Daily Orders report for"
          topic="Daily Orders Report"
          isNeedFilterDetails={true}
          locationSelectedList={locationSelectedList}
          setLocationSelectedList={setLocationSelectedList}
          openFilterCard={openFilterCard}
          setOpenFilterCard={setOpenFilterCard}
          handleOnClickText={handleOnClickText}
          isReport={true}
          payInOut={false}
          isInitialLoad={isInitialLoad}
          setIsInitialLoad={setIsInitialLoad}
          initialParamSize={5}
          isOrderReport={true}
          isWeekDateRange={true}
        >
          <DailyOrderReportTab
            orderReportNodeListDaily={orderReportNodeListDaily}
            filterDetails={filterDetails}
            locationSelectedList={locationSelectedList}
            handleOnClickText={handleOnClickText}
            orderReportNodeListWeekly={orderReportNodeListWeekly}
            orderReportNodeListMonthly={orderReportNodeListMonthly}
          />
        </SaleReport>

        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>
    </>
  );
};

export default withAuthority(DailyOrderReport, Authorities.DASHBOARD_READ);
