import {
  Box,
  Divider,
  Grid,
  Hidden,
  Menu,
  MenuItem,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  fetchAllLocations,
  getPublicLocationInfo,
} from "../../../../services/locationApp/locationService";
import { useRouteMatch } from "react-router-dom";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { AxiosError } from "axios";
import { convertDateTimeFormatInventory } from "../../../../utils/ConvertDateTimeFormat";
import _ from "lodash";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { Skeleton } from "@material-ui/lab";
import { CustomTheme } from "../../../../types/customTheme";
import {
  getPublicSingleStockOrder,
  getPublicStockOrderItem,
  stockOrderStatusUpdate,
} from "../../../../services/publice/stockOrder";
import MenuButtonCommon from "../../../../components/buttons/MenuButtonCommon";
import { handleDownloadPdf } from "./convertPdf/convertDataToPdf";
import { handleDownloadCsv } from "./convertPdf/convertDataToExcel";
import { CapitalizeFirstLetter } from "../../../../utils/ReplaceIcon";

const getColorByStatus = (statusInitial: any, theme: any) => {
  switch (statusInitial.toLowerCase()) {
    case "ordered":
      return "#FFB400";
    case "order":
      return "#39B3D1";
    case "delivered":
      return "#5BB995";
    case "draft":
      return "#F4F5F7";
    default:
      return "#5BB995";
  }
};

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    customDividerNotOpenSidebar: {
      backgroundColor: "#61687a",
      height: "1px",
      borderRadius: "10px",
      width: "100%",
    },
    customDividerNotOpenSidebarItem: {
      backgroundColor: "#61687a",
      height: "0.8px",
      borderRadius: "10px",
      width: "100%",
    },
    rowOdd: {
      backgroundColor: "#B8B8B8",
      borderRadius: "10px",
      border: `1px solid "#B8B8B8"`,
    },
    status: {
      marginLeft: "52px",
    },
    statusColor: {
      height: "36px",
      width: "24px",
      borderRadius: "10px",
      marginRight: "4px",
      marginLeft: "12px",
      marginTop: "4px",
      marginBottom: "4px",
    },
  }),
);

export interface HomeProps {}

const CorePage: React.FunctionComponent<HomeProps> = () => {
  const [restaurantList, setRestaurantList] = useState<any>({});
  const [stockOrderNode, setStockOrderNode] = useState<any>([]);
  const [stockOrderItemNode, setStockOrderItemNode] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [pdfHeaders, setPdfHeaders] = useState([
    { title: "SKU", field: "sku" },
    { title: "Product Name", field: "stockItemName" },
    { title: "Order Qty", field: "orderQty" },
    { title: "Barcode", field: "barcode" },
  ]);
  const [excelHeaders, setExcelHeaders] = useState([
    { header: "SKU", key: "sku", width: 52, height: 68 },
    { header: "Product Name", key: "stockItemName", width: 52, height: 68 },
    { header: "Order Qty", key: "orderQty", width: 52, height: 68 },
    { header: "Barcode", key: "barcode", width: 52, height: 68 },
  ]);
  const [nodesPdfExcel, setNodesPdfExcel] = useState<any>([]);

  useEffect(() => {
    if (!_.isEmpty(stockOrderItemNode)) {
      const output: any = [];
      stockOrderItemNode.map((data: any) => {
        output.push({
          orderQty: data.orderQty,
          stockItemName: data.stockItemName,
          sku: data.sku,
          barcode: data.barcode,
        });
      });
      setNodesPdfExcel(output);
    }
  }, [stockOrderItemNode]);

  const match: any = useRouteMatch();
  /* Get all location information using API call and response data set to locationList state. */
  const fetchLocations = useCallback(async () => {
    try {
      const res = await getPublicLocationInfo(match.params.locationId);
      setRestaurantList(res.data.data);
      fetchSingleStockOrder();
    } catch (error) {
      // Type assertion using 'as' keyword
      const apiError = error as AxiosError;
      if (apiError.response) {
        setError(apiError.response.data.message);
      }
      setIsLoading(false);
    }
  }, []);

  const fetchSingleStockOrder = useCallback(async () => {
    try {
      const res = await getPublicSingleStockOrder(
        match.params.locationId,
        match.params.orderId,
      );
      if (res.data.status === 404) {
        setError(res.data.error);
      } else {
        setStockOrderNode(res.data.data);
        fetchSingleStockOrderItem();
      }
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  }, []);

  const fetchSingleStockOrderItem = useCallback(async () => {
    try {
      const res = await getPublicStockOrderItem(
        match.params.locationId,
        match.params.orderId,
      );
      if (res.data.status === 404) {
        setError(res.data.error);
      } else {
        setStockOrderItemNode(res.data.data);
      }
      setIsLoading(false);
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchLocations();
  }, []);

  React.useEffect(() => {
    document.title = "Purchase Order";
  }, []);

  /* Open PDF / Excel selection menu */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /* Close PDF / Excel selection menu */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  const selectedAction = queryString.parse(window.location.search).isAction;

  const updateStockOrderStatus = useCallback(async () => {
    try {
      const res = await stockOrderStatusUpdate(
        match.params.locationId,
        match.params.orderId,
      );
      if (res.data.status === 404) {
        setError(res.data.error);
      }
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(stockOrderNode)) {
      if (selectedAction === "true" && stockOrderNode.status === "ordered") {
        updateStockOrderStatus();
      }
    }
  }, [stockOrderNode]);

  const open = Boolean(anchorEl);
  const theme: CustomTheme = useTheme()
  return (
    <>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "60px",
          marginBottom: "60px",
        }}
      >
        <div
          style={{
            width: "80%",
            backgroundColor: "white",
            minHeight: "500px",
          }}
        >
          {isLoading ? (
            <div style={{ margin: "12px" }}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "Start" }}
                >
                  <Skeleton
                    className={classes.rowOdd}
                    variant="text"
                    style={{ width: "300px" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "Start",
                    marginTop: "12px",
                  }}
                >
                  <Skeleton
                    className={classes.rowOdd}
                    variant="text"
                    style={{ width: "200px" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "Start",
                    marginTop: "12px",
                  }}
                >
                  <Skeleton
                    className={classes.rowOdd}
                    variant="text"
                    style={{ width: "200px" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "Start",
                    marginTop: "12px",
                  }}
                >
                  <Skeleton
                    className={classes.rowOdd}
                    variant="text"
                    style={{ width: "200px" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "Start",
                    marginTop: "12px",
                  }}
                >
                  <Skeleton
                    className={classes.rowOdd}
                    variant="text"
                    style={{ width: "200px" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "Start",
                    marginTop: "12px",
                  }}
                >
                  <Skeleton
                    className={classes.rowOdd}
                    variant="text"
                    style={{ width: "200px" }}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "12px" }}>
                <Grid item xs={12}>
                  <Skeleton
                    className={classes.rowOdd}
                    variant="text"
                    style={{ width: "200px" }}
                  />
                </Grid>

                <Skeleton
                  className={classes.rowOdd}
                  height={40}
                  variant="rect"
                  style={{ width: "100%", marginTop: "8px" }}
                />
                <Skeleton
                  className={classes.rowOdd}
                  height={40}
                  variant="rect"
                  style={{ width: "100%", marginTop: "8px" }}
                />
                <Skeleton
                  className={classes.rowOdd}
                  height={40}
                  variant="rect"
                  style={{ width: "100%", marginTop: "8px" }}
                />
                <Skeleton
                  className={classes.rowOdd}
                  height={40}
                  variant="rect"
                  style={{ width: "100%", marginTop: "8px" }}
                />
                <Skeleton
                  className={classes.rowOdd}
                  height={40}
                  variant="rect"
                  style={{ width: "100%", marginTop: "8px" }}
                />
              </Grid>
            </div>
          ) : (
            <div style={{ margin: "24px" }}>
              <Hidden xsDown>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: " space-between",
                    }}
                  >
                    <Typography variant="h5" align="left">
                      {`Purchase Order from ${restaurantList?.businessDisplayName}`}
                    </Typography>
                    <div style={{ width: "140px" }}>
                      <MenuButtonCommon
                        id="basic-button"
                        variant="contained"
                        style={{
                          fontSize: 11,
                          height: "38px",
                          paddingLeft: "16px",
                          paddingRight: "16px",
                        }}
                        color="blue"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                      >
                        <GetAppIcon style={{ color: "white" }} />
                        <Typography
                          variant="body1"
                          style={{ paddingLeft: "16px", color: "white" }}
                        >
                          Export
                        </Typography>
                      </MenuButtonCommon>
                    </div>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      PaperProps={{
                        elevation: 0,
                        style: {
                          borderRadius: "10px",
                          border: `1px solid #DFE1E6`,
                          backgroundColor: "white",
                        },
                      }}
                      style={{ top: "56px" }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          handleDownloadPdf(
                            "p",
                            pdfHeaders,
                            nodesPdfExcel,
                            restaurantList,
                            stockOrderNode,
                          );
                          handleClose();
                        }}
                      >
                        Export as PDF
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleDownloadCsv(
                            excelHeaders,
                            nodesPdfExcel,
                            restaurantList,
                            stockOrderNode,
                          );
                          handleClose();
                        }}
                      >
                        Export as CSV
                      </MenuItem>
                    </Menu>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "Start",
                      marginTop: "24px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{ fontWeight: "bold", marginRight: "58px" }}
                    >
                      Restaurant Info
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "24px",
                    }}
                  >
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      Supplier Info
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "Start",
                      marginTop: "12px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold", marginRight: "76px" }}
                    >
                      Name
                    </Typography>
                    <Typography variant="body1" align="left">
                      {restaurantList?.businessDisplayName}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "12px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold", marginRight: "12px" }}
                    >
                      Name
                    </Typography>
                    <Typography variant="body1" align="right">
                      {stockOrderNode?.supplierId?.name}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "Start",
                      marginTop: "12px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold", marginRight: "60px" }}
                    >
                      Address
                    </Typography>
                    <Typography variant="body1" align="left">
                      {restaurantList?.addressFormatted}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "12px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold", marginRight: "12px" }}
                    >
                      Address
                    </Typography>
                    <Typography variant="body1" align="right">
                      {stockOrderNode?.supplierId?.address}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "Start",
                      marginTop: "12px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold", marginRight: "38px" }}
                    >
                      Contact No
                    </Typography>
                    <Typography variant="body1" align="left">
                      {restaurantList?.contactNo}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "12px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold", marginRight: "12px" }}
                    >
                      Contact No
                    </Typography>
                    <Typography variant="body1" align="right">
                      {stockOrderNode?.supplierId?.contactNo}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "Start",
                      marginTop: "12px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold", marginRight: "80px" }}
                    >
                      Email
                    </Typography>
                    <Typography variant="body1" align="left">
                      {restaurantList?.email}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "12px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold", marginRight: "12px" }}
                    >
                      Email
                    </Typography>
                    <Typography variant="body1" align="right">
                      {stockOrderNode?.supplierId?.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden smUp>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h5" align="left">
                      {`Purchase Order from ${restaurantList?.businessDisplayName}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: "12px" }}>
                    <MenuButtonCommon
                      id="basic-button"
                      variant="contained"
                      style={{
                        fontSize: 11,
                        height: "38px",
                        paddingLeft: "16px",
                        paddingRight: "16px",
                      }}
                      color="blue"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      <GetAppIcon style={{ color: "white" }} />
                      <Typography
                        variant="body1"
                        style={{ paddingLeft: "16px", color: "white" }}
                      >
                        Export
                      </Typography>
                    </MenuButtonCommon>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      PaperProps={{
                        elevation: 0,
                        style: {
                          borderRadius: "10px",
                          border: `1px solid #DFE1E6`,
                          backgroundColor: "white",
                        },
                      }}
                      style={{ top: "56px" }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          handleDownloadPdf(
                            "p",
                            pdfHeaders,
                            nodesPdfExcel,
                            restaurantList,
                            stockOrderNode,
                          );
                          handleClose();
                        }}
                      >
                        Export as PDF
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleDownloadCsv(
                            excelHeaders,
                            nodesPdfExcel,
                            restaurantList,
                            stockOrderNode,
                          );
                          handleClose();
                        }}
                      >
                        Export as CSV
                      </MenuItem>
                    </Menu>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "Start",
                      marginTop: "12px",
                    }}
                  >
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      Restaurant Info
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "12px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold", fontSize: "12px" }}
                    >
                      Name
                    </Typography>
                    <Typography
                      variant="body1"
                      align="right"
                      style={{ fontSize: "12px" }}
                    >
                      {restaurantList?.businessDisplayName}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "12px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold", fontSize: "12px" }}
                    >
                      Address
                    </Typography>
                    <Typography
                      variant="body1"
                      align="right"
                      style={{ fontSize: "12px" }}
                    >
                      {restaurantList?.addressFormatted}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "12px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold", fontSize: "12px" }}
                    >
                      Contact No
                    </Typography>
                    <Typography
                      variant="body1"
                      align="right"
                      style={{ fontSize: "12px" }}
                    >
                      {restaurantList?.contactNo}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "12px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold", fontSize: "12px" }}
                    >
                      Email
                    </Typography>
                    <Typography
                      variant="body1"
                      align="right"
                      style={{ fontSize: "12px" }}
                    >
                      {restaurantList?.email}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      marginTop: "12px",
                    }}
                  >
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      Supplier Info
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "12px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold", fontSize: "12px" }}
                    >
                      Name
                    </Typography>
                    <Typography
                      variant="body1"
                      align="right"
                      style={{ fontSize: "12px" }}
                    >
                      {stockOrderNode?.supplierId?.name}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "12px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold", fontSize: "12px" }}
                    >
                      Address
                    </Typography>
                    <Typography
                      variant="body1"
                      align="right"
                      style={{ fontSize: "12px" }}
                    >
                      {stockOrderNode?.supplierId?.address}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "12px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold", fontSize: "12px" }}
                    >
                      Contact No
                    </Typography>
                    <Typography
                      variant="body1"
                      align="right"
                      style={{ fontSize: "12px" }}
                    >
                      {stockOrderNode?.supplierId?.contactNo}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "12px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold", fontSize: "12px" }}
                    >
                      Email
                    </Typography>
                    <Typography
                      variant="body1"
                      align="right"
                      style={{ fontSize: "12px" }}
                    >
                      {stockOrderNode?.supplierId?.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Hidden>
              <Grid container>
                {/* <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "Start",
                    marginTop: "12px",
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{ fontWeight: "bold", marginRight: "20px" }}
                  >
                    Delivery Date
                  </Typography>
                  <Typography variant="body1">
                    {convertDateTimeFormatInventory(
                      stockOrderNode?.deliveredOn,
                    )}
                  </Typography>
                </Grid> */}
                <Grid item xs={12} style={{ marginTop: "12px" }}>
                  <Divider className={classes.customDividerNotOpenSidebar} />
                </Grid>
              </Grid>
              <Hidden xsDown>
                <Grid container style={{ marginTop: "12px" }}>
                  <Grid
                    item
                    xs={1}
                    md={3}
                    style={{
                      display: "flex",
                      justifyContent: "Start",
                      fontWeight: "bold",
                    }}
                  >
                    SKU
                  </Grid>
                  <Grid item xs={3} style={{ fontWeight: "bold" }}>
                    Product Name
                  </Grid>
                  <Grid item xs={3} style={{ fontWeight: "bold" }}>
                    Order Qty
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    md={3}
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      fontWeight: "bold",
                    }}
                  >
                    Barcode
                  </Grid>
                  {!_.isEmpty(stockOrderItemNode) &&
                    stockOrderItemNode.map((data: any, index: number) => (
                      <Grid
                        item
                        xs={12}
                        style={{ marginTop: index === 0 ? "12px" : "0px" }}
                      >
                        <Divider
                          className={classes.customDividerNotOpenSidebarItem}
                        />
                        <Grid container>
                          <Grid
                            item
                            xs={1}
                            md={3}
                            style={{
                              display: "flex",
                              justifyContent: "Start",
                              marginTop: "12px",
                            }}
                          >
                            {data.sku}
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            md={3}
                            style={{ marginTop: "12px" }}
                          >
                            {data.stockItemName}
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            md={3}
                            style={{ marginTop: "12px" }}
                          >
                            {data.orderQty}
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            md={3}
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            <div style={{ marginTop: "12px" }}>
                              {data.barcode}
                            </div>

                            <Box
                              className={classes.statusColor}
                              style={{
                                backgroundColor: getColorByStatus(data.status, theme),
                              }}
                            ></Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </Hidden>
              <Hidden smUp>
                <Grid container style={{ marginTop: "12px" }}>
                  <Grid
                    item
                    xs={3}
                    style={{
                      display: "flex",
                      justifyContent: "Start",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    SKU
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Product Name
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    md={3}
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Order Qty
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    md={3}
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    Barcode
                  </Grid>
                  {!_.isEmpty(stockOrderItemNode) &&
                    stockOrderItemNode.map((data: any, index: number) => (
                      <Grid
                        item
                        xs={12}
                        style={{ marginTop: index === 0 ? "12px" : "0px" }}
                      >
                        <Divider
                          className={classes.customDividerNotOpenSidebarItem}
                        />
                        <Grid container>
                          <Grid
                            item
                            xs={3}
                            style={{
                              display: "flex",
                              justifyContent: "Start",
                              marginTop: "12px",
                              fontSize: "12px",
                            }}
                          >
                            {data.sku}
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            style={{ marginTop: "12px", fontSize: "12px" }}
                          >
                            {data.stockItemName}
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            md={3}
                            style={{ marginTop: "12px", fontSize: "12px" }}
                          >
                            {data.orderQty}
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            md={3}
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            <div
                              style={{ marginTop: "12px", fontSize: "12px" }}
                            >
                              {data.barcode}
                            </div>

                            <Box
                              className={classes.statusColor}
                              style={{
                                backgroundColor: getColorByStatus(data.status, theme),
                              }}
                            ></Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </Hidden>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CorePage;
