import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import _ from "lodash";

import { CustomTheme } from "../../../types/customTheme";
import IncrementDecrementTextboxNewss from "../../common/IncrementDecrementTextbox/IncrementDecrementTextboxNewss";

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    display: "flex",
    alignItems: "start",
  },
  departmentStyle: {
    display: "block",
  },
}));

export interface AddMapProductProps {
  menuGroups: any;
  menuDepartments: any;
  menuNode: any;
  registerElementWithTitle: any;
  setFilterDepartment: any;
  initialOutputMultiplier: any;
  outputMultiplierObj: any;
  setOutputMultiplierObj: any;
  isOpenWasteStockMapProductModal: any;
  checkedProductIdList: any;
  setCheckedProductIdList: any;
  displaySuffix: any;
  setIsEdit: any;
  initialOrderQty: any;
  activeGroupId: any;
  searchValue: any;
  isError: any;
  setIsError: any;
}

/**
 * This component, 'AddMapProduct', represents a UI element for adding and configuring map products
 * with various options, such as selecting departments, products, and setting output multipliers.
 * It provides a responsive layout for both small and large screens, including a drawer for mobile views.
 * The component handles user interactions like checkbox selection and multiplier adjustment.
 */
const AddMapProduct: React.FunctionComponent<AddMapProductProps> = ({
  menuGroups,
  menuDepartments,
  menuNode,
  registerElementWithTitle,
  setFilterDepartment,
  initialOutputMultiplier,
  outputMultiplierObj,
  setOutputMultiplierObj,
  isOpenWasteStockMapProductModal,
  checkedProductIdList,
  setCheckedProductIdList,
  displaySuffix,
  setIsEdit,
  initialOrderQty,
  activeGroupId,
  searchValue,
  isError,
  setIsError,
}) => {
  const [groupId, setGroupId] = useState(0);

  /**
   * This useEffect checks if the create stock items modal is open,
   * if menuGroups is available and contains items, and sets the groupId accordingly.
   */
  useEffect(() => {
    // Check if the create stock items modal is open, menuGroups is available, and it has items
    const isModalOpenAndGroupsExist =
      isOpenWasteStockMapProductModal &&
      menuGroups &&
      Object.values(menuGroups).length > 0;

    if (isModalOpenAndGroupsExist) {
      // Get the details of the first group from the menuGroups object
      const firstGroupDetails: any = Object.values(menuGroups)[0];
      // Set the groupId to the ID of the first group, if available
      setGroupId(firstGroupDetails?.id);
    }
  }, [menuGroups, isOpenWasteStockMapProductModal]);

  /**
   * This useEffect is responsible for filtering and setting the departments in the context of the "Create Stock Items" modal.
   * It checks if the modal is open, menuDepartments is available, and then filters departments based on the selected groupId.
   * The filtered departments are set using setFilterDepartment.
   */
  useEffect(() => {
    // Check if the create stock items modal is open and menuDepartments is available
    if (isOpenWasteStockMapProductModal && menuDepartments) {
      // Filter menuDepartments to get departments belonging to the selected groupId
      const abc = Object.values(menuDepartments);

      abc.sort((a: any, b: any) => {
        return parseInt(a.view_order) - parseInt(b.view_order);
      });
      const sortedObj: any = abc.reduce((acc: any, curr: any) => {
        acc[`a${curr.id}`] = curr;
        return acc;
      }, {});

      const filterMenuDepartment = Object.values(sortedObj).filter(
        (department: any) => department.group_id.toString() === groupId,
      );
      // Set the filtered departments using setFilterDepartment
      setFilterDepartment(filterMenuDepartment);
    }
  }, [menuDepartments, groupId, isOpenWasteStockMapProductModal]);

  /**
   * Handles the change in checkbox state, updating checked product IDs and output multipliers.
   *
   * @param {object} event - The checkbox change event.
   * @param {object} products - The products associated with the checkbox.
   */
  const handleChangeCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>,
    products: any,
  ) => {
    // Clone the state variables
    let updatedCheckedProductIdList = [...checkedProductIdList];
    const updatedOutputMultiplierObj = { ...outputMultiplierObj };
    if (products && Object.values(products).length > 0) {
      // When a checkbox is checked
      if (!checkedProductIdList.includes(products.id)) {
        // Loop through the products associated with the checkbox
        // Add product ID to the list twice
        updatedCheckedProductIdList.push(products.id);

        // Initialize or update the output multiplier for each product
        updatedOutputMultiplierObj[products.id] = {
          productId: products.id,
          qty: initialOutputMultiplier,
        };
      } else {
        // Filter out the checked product IDs and their multipliers
        updatedCheckedProductIdList = updatedCheckedProductIdList.filter(
          (productId) => productId !== products.id,
        );

        delete updatedOutputMultiplierObj[products.id];
      }
      if (isError) {
        const error = isError.filter((data: any) => data !== products.id);
        setIsError(error);
      }
    }

    // Update state variables with the modified lists and multipliers
    setCheckedProductIdList(updatedCheckedProductIdList);
    setOutputMultiplierObj(updatedOutputMultiplierObj);
    setIsEdit(true);
  };

  /**
   * Updates the output multiplier for a specific product.
   * @param {string} productId - The ID of the product to update.
   * @param {object} event - The event object containing the new value.
   */
  const handleOutputMultiplier = (
    productId: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (
      Number.isNaN(parseFloat(event.target.value)) ||
      parseFloat(event.target.value) >= 0
    ) {
      // Clone the outputMultiplierObj to avoid mutating the state directly
      const updatedOutputMultiplierObj = { ...outputMultiplierObj };

      // Update the output multiplier for the specified product
      updatedOutputMultiplierObj[productId] = {
        productId: productId,
        qty: event.target.value,
      };

      // Update the state with the new outputMultiplierObj
      setOutputMultiplierObj(updatedOutputMultiplierObj);
      setIsEdit(true);
      if (isError) {
        const error = isError.filter((data: any) => data !== productId);
        setIsError(error);
      }
    }
  };

  const handleOrderQtyEmpty = (productId: any, value: any) => {
    // Clone the outputMultiplierObj to avoid mutating the state directly
    const updatedOutputMultiplierObj = { ...outputMultiplierObj };
    const cloneCheckedProductIdList = _.cloneDeep(checkedProductIdList);

    // Update the output multiplier for the specified product
    updatedOutputMultiplierObj[productId] = {
      productId: productId,
      qty: value,
    };

    // Update the state with the new outputMultiplierObj
    setOutputMultiplierObj(updatedOutputMultiplierObj);
    cloneCheckedProductIdList.push(productId);
    setCheckedProductIdList(cloneCheckedProductIdList);
    setIsEdit(true);
  };
  /**
   * Handles the increment action for the output multiplier of a product.
   * @param {string} productId - The ID of the product to increment.
   */
  const handleIncrement = (productId: string) => {
    // Clone the outputMultiplierObj to avoid mutating the state directly
    const updatedOutputMultiplierObj = { ...outputMultiplierObj };

    const outputMultiplierValue = updatedOutputMultiplierObj[productId].qty;

    // Check if the qty has a decimal part
    if (!_.isEmpty(outputMultiplierValue.toString().split(".")[1])) {
      if (outputMultiplierValue.toString().split(".")[1] === "0") {
        updatedOutputMultiplierObj[productId].qty = (
          Number(outputMultiplierValue) + 1
        ).toString();
      } else {
        // If it has a decimal part, round up to the nearest integer
        updatedOutputMultiplierObj[productId].qty = Math.ceil(
          outputMultiplierValue,
        );
      }
    } else {
      // If it's an integer, increment it by 1 and convert to string
      updatedOutputMultiplierObj[productId].qty = (
        Number(outputMultiplierValue) + 1
      ).toString();
    }

    // Update the state with the updated outputMultiplierObj
    setOutputMultiplierObj(updatedOutputMultiplierObj);
    setIsEdit(true);
    if (isError) {
      const error = isError.filter((data: any) => data !== productId);
      setIsError(error);
    }
  };

  /**
   * Handles the decrement action for the output multiplier of a product.
   * @param {string} productId - The ID of the product to decrement.
   */
  const handleDecrement = (productId: string) => {
    // Clone the outputMultiplierObj to avoid mutating the state directly
    const updatedOutputMultiplierObj = { ...outputMultiplierObj };

    // Retrieve the current output multiplier value for the specified product
    const outputMultiplierValue = updatedOutputMultiplierObj[productId].qty;

    // Check if the qty has a decimal part
    if (!_.isEmpty(outputMultiplierValue.toString().split(".")[1])) {
      // If it has a decimal part, and the value is greater than or equal to 1, decrement it
      if (Math.floor(outputMultiplierValue) - 1 > 0) {
        updatedOutputMultiplierObj[productId].qty =
          Math.floor(outputMultiplierValue) - 1;
      }
    } else {
      // If it's an integer and greater than or equal to 1, decrement it
      if (Number(outputMultiplierValue) - 1 > 0) {
        updatedOutputMultiplierObj[productId].qty = (
          Number(outputMultiplierValue) - 1
        ).toString();
      }
    }

    // Update the state with the updated outputMultiplierObj
    setOutputMultiplierObj(updatedOutputMultiplierObj);
    setIsEdit(true);
    if (isError) {
      const error = isError.filter((data: any) => data !== productId);
      setIsError(error);
    }
  };

  const classes = useStyles();
  const theme: CustomTheme = useTheme();
  const matchesSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesSmallScreenXs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            {!_.isEmpty(menuNode) ? (
              Object.values(menuNode).map(
                (group: any, menuIndex: any) =>
                  ((group.id >= 0 && activeGroupId === menuIndex) ||
                    searchValue) && (
                    <div
                      ref={(ref) =>
                        registerElementWithTitle(`group ${group.id}`, ref)
                      }
                    >
                      {!_.isEmpty(group.department) &&
                        Object.values(group.department).map(
                          (department: any, index: any) =>
                            department.name !== "Online Hidden" &&
                            department.name !== "Templates" && (
                              <div
                                ref={(ref) =>
                                  registerElementWithTitle(
                                    `department ${department.id}`,
                                    ref,
                                  )
                                }
                              >
                                <div
                                  style={{
                                    backgroundColor:
                                      theme.palette.background
                                        .entity_background,
                                    border: `1px solid ${theme.palette.background.entity_background}`,
                                    overflowY: "auto",
                                    boxShadow: "none",
                                    position: "sticky",
                                    zIndex: 10,
                                    top: matchesSmallScreenXs
                                      ? "250px"
                                      : matchesSmallScreen
                                      ? "133px"
                                      : "130px",
                                    paddingTop: "4px",
                                    paddingBottom: "4px",
                                  }}
                                >
                                  <Card
                                    style={{
                                      backgroundColor:
                                        theme.palette.background.paper,
                                      borderRadius: "10px",
                                      border: `1px solid ${theme.palette.background.entity_highlight_background}`,
                                      marginBottom: "12px",
                                      marginTop: "12px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: "5px 10px",
                                      }}
                                    >
                                      <div style={{ display: "block" }}>
                                        <Typography>{group.name}</Typography>
                                        <Typography
                                          variant="h5"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          {department.name}
                                        </Typography>
                                      </div>
                                    </div>

                                    {/* </Button> */}
                                  </Card>
                                </div>
                                <div
                                  style={{
                                    marginTop:
                                      matchesSmallScreenXs && index === 0
                                        ? "216px"
                                        : matchesSmallScreen && index === 0
                                        ? "98px"
                                        : menuIndex === 0 && index === 0
                                        ? "124px"
                                        : "0px",
                                  }}
                                ></div>
                                <Grid
                                  container
                                  spacing={1}
                                  style={{
                                    marginTop: index === 0 ? "28px" : "4px",
                                    marginBottom: "8px",
                                  }}
                                >
                                  {!_.isEmpty(department.products) &&
                                    Object.values(department.products).map(
                                      (products: any, indexProduct) => (
                                        <Grid
                                          item
                                          xs={12}
                                          sm={6}
                                          md={4}
                                          lg={3}
                                          style={
                                            (matchesSmallScreen &&
                                              indexProduct === 0 &&
                                              index === 0) ||
                                            (matchesSmallScreen &&
                                              !matchesSmallScreenXs &&
                                              indexProduct === 1 &&
                                              index === 0)
                                              ? { marginTop: "32px" }
                                              : { marginTop: "4px" }
                                          }
                                        >
                                          <Card
                                            style={{
                                              backgroundColor:
                                                checkedProductIdList.includes(
                                                  products.id,
                                                )
                                                  ? isError.includes(
                                                      products.id,
                                                    )
                                                    ? theme.palette.custom.red
                                                        .main
                                                    : "#84803D"
                                                  : theme.palette.background
                                                      .entity_highlight_background,
                                              borderRadius: "10px",
                                              border: `1px solid ${theme.palette.background.entity_highlight_background}`,
                                              overflowY: "auto",
                                              boxShadow: "none",
                                              cursor: "pointer",
                                            }}
                                            onClick={(event: any) => {
                                              checkedProductIdList.includes(
                                                products.id,
                                              )
                                                ? handleChangeCheckBox(
                                                    event,
                                                    products,
                                                  )
                                                : handleOrderQtyEmpty(
                                                    products.id,
                                                    initialOrderQty,
                                                  );
                                            }}
                                          >
                                            <div
                                              style={{
                                                padding: "4px 8px",
                                                width: "100%",
                                              }}
                                            >
                                              <Typography
                                                align="left"
                                                style={{
                                                  fontSize: "14px",
                                                  marginLeft: "4px",
                                                  marginBottom: "2px",
                                                  display: "flex",
                                                  justifyContent: "start",
                                                }}
                                              >
                                                {products.name}
                                              </Typography>
                                              <div>
                                                <IncrementDecrementTextboxNewss
                                                  handleOrderQty={
                                                    handleOutputMultiplier
                                                  }
                                                  value={
                                                    outputMultiplierObj[
                                                      products.id
                                                    ]?.qty
                                                  }
                                                  id={products.id}
                                                  handleIncrement={
                                                    handleIncrement
                                                  }
                                                  handleDecrement={
                                                    handleDecrement
                                                  }
                                                  isDisable={
                                                    !checkedProductIdList.includes(
                                                      products.id,
                                                    )
                                                  }
                                                  displaySuffix={displaySuffix}
                                                  handleOrderQtyEmpty={
                                                    handleOrderQtyEmpty
                                                  }
                                                  isNeedNegativeIcon={true}
                                                />
                                              </div>
                                            </div>
                                          </Card>
                                        </Grid>
                                      ),
                                    )}
                                </Grid>
                              </div>
                            ),
                        )}
                    </div>
                  ),
              )
            ) : (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography variant="h5">No Search Item</Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AddMapProduct;
