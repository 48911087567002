import {
  Box,
  Card,
  Grid,
  Hidden,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { CustomTheme } from "../../../../../../types/customTheme";
import IncrementDecrementTextboxNewss from "../../../../../../components/common/IncrementDecrementTextbox/IncrementDecrementTextboxNewss";
import StockLevelRow from "../../../../../../components/common/StockLevelRow";

const useStyles = makeStyles((theme: CustomTheme) => ({
  departmentCardStyle: {
    backgroundColor: "#84803D",
    "&:hover": {
      backgroundColor: "#a5a380",
    },
    borderRadius: "10px",
    border: `1px solid ${theme.palette.background.entity_highlight_background}`,
    overflowY: "auto",
    boxShadow: "none",
    cursor: "pointer",
  },
  departmentDisableCardStyle: {
    backgroundColor: theme.palette.background.entity_highlight_background,
    borderRadius: "10px",
    border: `1px solid ${theme.palette.background.entity_highlight_background}`,
    overflowY: "auto",
    boxShadow: "none",
    cursor: "pointer",
  },
  statusColorRed: {
    height: "50px",
    width: "24px",
    borderRadius: "10px",
    backgroundColor: theme.palette.custom.red.main,
  },
  statusColorRedMobile: {
    height: "100px",
    width: "24px",
    borderRadius: "10px",
    backgroundColor: theme.palette.custom.red.main,
  },
  statusColorYellow: {
    height: "50px",
    width: "24px",
    borderRadius: "10px",
    backgroundColor: theme.palette.custom.yellow.main,
  },
  statusColorYellowMobile: {
    height: "100px",
    width: "24px",
    borderRadius: "10px",
    backgroundColor: theme.palette.custom.yellow.main,
  },
  statusColorGreen: {
    height: "50px",
    width: "24px",
    borderRadius: "10px",
    backgroundColor: theme.palette.custom.green.main,
  },
  statusColorGreenMobile: {
    height: "100px",
    width: "24px",
    borderRadius: "10px",
    backgroundColor: theme.palette.custom.green.main,
  },
}));

export interface DesktopNavProps {
  item: any;
  registerElementWithTitle: any;
  selectedStockOrderItemIdList: any;
  handleDecrement: any;
  selectedStockOrderItemObject: any;
  handleOrderQty: any;
  handleIncrement: any;
  handleChangeCheckBox: any;
  index: any;
  handleOrderQtyEmpty: any;
  stockLevelNode: any;
}

/**
 * This component represents the view for displaying stock order items when the active group ID is zero.
 * It shows the stock order items organized by departments, allowing users to select items, adjust quantities,
 * and perform various actions.
 */
const StockOrderItemActiveGroupIdZero: React.FunctionComponent<
  DesktopNavProps
> = ({
  item,
  registerElementWithTitle,
  selectedStockOrderItemIdList,
  handleDecrement,
  selectedStockOrderItemObject,
  handleOrderQty,
  handleIncrement,
  handleChangeCheckBox,
  index,
  handleOrderQtyEmpty,
  stockLevelNode,
}) => {
  const [sortDepartmentData, setSortDepartmentData] = useState<any>([]);
  const classes = useStyles();

  useEffect(() => {
    const sortedArray = [...item.stockDepartmentId].sort((a, b) =>
      a.name.localeCompare(b.name),
    );
    setSortDepartmentData(sortedArray);
  }, [item.stockDepartmentId]);

  const handleStatus = (stockOrder: any) => {
    const { id } = stockOrder;
    if (stockLevelNode[id]) {
      const { stockLevel, warningThreshold, criticalThreshold } =
        stockLevelNode[id];

      if (stockLevel < criticalThreshold) {
        return <Box className={classes.statusColorRed}></Box>;
      } else if (stockLevel < warningThreshold) {
        return <Box className={classes.statusColorYellow}></Box>;
      } else {
        return <Box className={classes.statusColorGreen}></Box>;
      }
    } else {
      const { stockLevel, warningThreshold, criticalThreshold } = stockOrder;
      if (0 < criticalThreshold) {
        return <Box className={classes.statusColorRed}></Box>;
      } else if (0 < warningThreshold) {
        return <Box className={classes.statusColorYellow}></Box>;
      } else {
        return <Box className={classes.statusColorGreen}></Box>;
      }
    }
  };

  const handleStatusMobile = (stockOrder: any) => {
    const { id } = stockOrder;
    if (stockLevelNode[id]) {
      const { stockLevel, warningThreshold, criticalThreshold } =
        stockLevelNode[id];

      if (stockLevel < criticalThreshold) {
        return <Box className={classes.statusColorRedMobile}></Box>;
      } else if (stockLevel < warningThreshold) {
        return <Box className={classes.statusColorYellowMobile}></Box>;
      } else {
        return <Box className={classes.statusColorGreenMobile}></Box>;
      }
    } else {
      const { stockLevel, warningThreshold, criticalThreshold } = stockOrder;
      if (0 < criticalThreshold) {
        return <Box className={classes.statusColorRedMobile}></Box>;
      } else if (0 < warningThreshold) {
        return <Box className={classes.statusColorYellowMobile}></Box>;
      } else {
        return <Box className={classes.statusColorGreenMobile}></Box>;
      }
    }
  };

  const theme: CustomTheme = useTheme();
  const tabletScreenSize = useMediaQuery(theme.breakpoints.down("sm"));
  const mobileScreenSize = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div>
      {!_.isEmpty(sortDepartmentData) &&
        sortDepartmentData.map((department: any, departmentIndex: any) => (
          <div
            ref={(ref) =>
              registerElementWithTitle(`department ${department.id}`, ref)
            }
          >
            <div
              style={{
                backgroundColor: theme.palette.background.entity_background,
                border: `1px solid ${theme.palette.background.entity_background}`,
                overflowY: "auto",
                boxShadow: "none",
                position: "sticky",
                zIndex: 10,
                top: mobileScreenSize
                  ? "350px"
                  : tabletScreenSize
                  ? "240px"
                  : "176px",
                paddingTop: "4px",
                paddingBottom: "4px",
              }}
            >
              <Card
                style={{
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: "10px",
                  border: `1px solid ${theme.palette.background.entity_highlight_background}`,
                  marginBottom: "12px",
                  marginTop: "12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "5px 10px",
                  }}
                >
                  <div style={{ display: "block" }}>
                    <Typography>{item.name}</Typography>
                    <Typography variant="h5" style={{ fontWeight: "bold" }}>
                      {department.name}
                    </Typography>
                  </div>
                </div>
              </Card>
            </div>
            <div style={{ marginTop:  index === 0 && departmentIndex === 0 ? "170px" : "0px" }}></div>
            <div
              style={{
                marginTop:
                  mobileScreenSize && index === 0 && departmentIndex === 0
                    ? "350px"
                    : tabletScreenSize  && index === 0 && departmentIndex === 0
                    ? "230px"
                    : "0px",
              }}
            ></div>
            <Grid
              container
              spacing={1}
              style={{
                marginTop: index === 0 && departmentIndex === 0 ? "28px" : "4px",
                marginBottom: "8px",
              }}
            >
              {!_.isEmpty(department.item) &&
                department.item.map((stockOrder: any) => (
                  <Grid item xs={12} style={{ marginTop: "4px" }}>
                    <Card
                      className={
                        selectedStockOrderItemIdList.includes(stockOrder.id)
                          ? classes.departmentCardStyle
                          : classes.departmentDisableCardStyle
                      }
                      onClick={(event: any) => {
                        selectedStockOrderItemIdList.includes(stockOrder.id)
                          ? handleChangeCheckBox(
                              event,
                              stockOrder.id,
                              stockOrder,
                            )
                          : handleOrderQtyEmpty(stockOrder.id, "10");
                      }}
                    >
                      <Hidden xsDown>
                        <div
                          style={{
                            padding: "7px 8px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div>{handleStatus(stockOrder)}</div>
                            <div style={{ minWidth: "200px" }}>
                              <Typography
                                align="left"
                                style={{
                                  fontSize: "14px",
                                  marginLeft: "12px",
                                  marginBottom: "2px",
                                  display: "flex",
                                  justifyContent: "start",
                                }}
                              >
                                {stockOrder.name}
                              </Typography>
                            </div>
                              <StockLevelRow
                                autoUnitConvert={stockOrder.autoUnitConvert}
                                showRemainderSep={stockOrder.showRemainderSep}
                                displaySuffix={stockOrder.displaySuffix}
                                inputMultiplier={stockOrder.inputMultiplier}
                                inputSuffix={stockOrder.inputSuffix}
                                stockLevel={
                                  stockLevelNode[stockOrder.id]
                                    ? stockLevelNode[stockOrder.id].stockLevel
                                    : "0"
                                }
                              />
                          </div>
                          <div style={{ width: "300px" }}>
                            <IncrementDecrementTextboxNewss
                              handleOrderQty={handleOrderQty}
                              value={
                                selectedStockOrderItemObject[stockOrder.id]
                                  ?.orderQty
                              }
                              id={stockOrder.id}
                              handleIncrement={handleIncrement}
                              handleDecrement={handleDecrement}
                              isDisable={
                                !selectedStockOrderItemIdList.includes(
                                  stockOrder.id,
                                )
                              }
                              displaySuffix={stockOrder.inputSuffix}
                              handleOrderQtyEmpty={handleOrderQtyEmpty}
                              isNeedNegativeIcon={false}
                            />
                          </div>
                        </div>
                      </Hidden>
                      <Hidden smUp>
                        <div
                          style={{
                            padding: "7px 8px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>{handleStatusMobile(stockOrder)}</div>

                          <div style={{ display: "block", width: "100%" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginRight: "4px",
                              }}
                            >
                              <div style={{ minWidth: "220px" }}>
                                <Typography
                                  align="left"
                                  style={{
                                    fontSize: "14px",
                                    marginLeft: "12px",
                                    marginBottom: "2px",
                                    display: "flex",
                                    justifyContent: "start",
                                  }}
                                >
                                  {stockOrder.name}
                                </Typography>
                              </div>
                              <StockLevelRow
                                autoUnitConvert={stockOrder.autoUnitConvert}
                                showRemainderSep={stockOrder.showRemainderSep}
                                displaySuffix={stockOrder.displaySuffix}
                                inputMultiplier={stockOrder.inputMultiplier}
                                inputSuffix={stockOrder.inputSuffix}
                                stockLevel={
                                  stockLevelNode[stockOrder.id]
                                    ? stockLevelNode[stockOrder.id].stockLevel
                                    : "0"
                                }
                              />
                            </div>
                            <div style={{ margin: "0px 4px 0px 12px" }}>
                              <IncrementDecrementTextboxNewss
                                handleOrderQty={handleOrderQty}
                                value={
                                  selectedStockOrderItemObject[stockOrder.id]
                                    ?.orderQty
                                }
                                id={stockOrder.id}
                                handleIncrement={handleIncrement}
                                handleDecrement={handleDecrement}
                                isDisable={
                                  !selectedStockOrderItemIdList.includes(
                                    stockOrder.id,
                                  )
                                }
                                displaySuffix={stockOrder.inputSuffix}
                                handleOrderQtyEmpty={handleOrderQtyEmpty}
                                isNeedNegativeIcon={false}
                              />
                            </div>
                          </div>
                        </div>
                      </Hidden>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </div>
        ))}
    </div>
  );
};

export default StockOrderItemActiveGroupIdZero;
