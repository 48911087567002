import * as React from "react";
import {
  Typography,
} from "@material-ui/core";
import _ from "lodash";


export interface WizardModalProps {
 
}
/* Common modal UI.. */
const StepEight: React.FunctionComponent<WizardModalProps> = ({

}) => {
  return (
    <>
      <Typography variant="h6">
        Stock item creation completed successfully
      </Typography>
      <Typography variant="body1">
        What action would you like to take next?
      </Typography>
    </>
  );
};

export default StepEight;
