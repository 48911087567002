import React, { useEffect, useState } from "react";
import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import { useRouteMatch } from "react-router";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { useTheme } from "@material-ui/core";
import _ from "lodash";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import { getCookie } from "../../../../utils/cookies";
import ItemsReportInfoNode from "./ItemsReportInfoNode";
import { salesItemsFilterObject } from "../../../../utils/consts/list";
import SaleReport from "../../../../components/common/SaleReport";
import PageHeader from "../../../../components/common/PageHeader/PageHeader";
import { CustomTheme } from "../../../../types/customTheme";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";
import { fetchAllItemsReportInfo } from "../../../../services/salesApp/itemsReportService";
import {
  fetchGlobalDepartmentInfo,
  fetchGlobalGroupInfo,
  fetchGlobalProductsInfo,
} from "../../../../services/menuApp/printerMenuService";

// const cloneData = [
//   {
//     created: "2024-03-26T12:32:40.226+00:00",
//     department: [
//       {
//         created: "2024-03-26T12:32:40.763+00:00",
//         groupId: 1,
//         id: 198,
//         locationId: "7713dce7-51ec-4870-b251-4bce1c9249f8",
//         name: "Add Price",
//         product: {
//           1212: [
//             {
//               amount: 14,
//               discount: 0,
//               itemName: " Misc",
//               qty: 1,
//               unitPrice: "14.00",
//               vatAmount: 2.33,
//               vatPercent: 20,
//               viewOrder: 0,
//             },
//           ],
//         },
//         updated: "2024-03-26T12:32:40.763+00:00",
//         valid: true,
//         version: 0,
//         viewOrder: 1,
//       },
//       {
//         created: "2024-03-26T12:32:40.763+00:00",
//         groupId: 1,
//         id: 198,
//         locationId: "7713dce7-51ec-4870-b251-4bce1c9249f8",
//         name: "Add Prices",
//         product: {
//           1212: [
//             {
//               amount: 14,
//               discount: 0,
//               itemName: " Misc",
//               qty: 1,
//               unitPrice: "14.00",
//               vatAmount: 2.33,
//               vatPercent: 20,
//               viewOrder: 0,
//             },
//           ],
//         },
//         updated: "2024-03-26T12:32:40.763+00:00",
//         valid: true,
//         version: 0,
//         viewOrder: 1,
//       },
//       {
//         created: "2024-03-26T12:32:40.763+00:00",
//         groupId: 1,
//         id: 198,
//         locationId: "7713dce7-51ec-4870-b251-4bce1c9249f8",
//         name: "Add Price",
//         product: {
//           1212: [
//             {
//               amount: 14,
//               discount: 0,
//               itemName: " Misc",
//               qty: 1,
//               unitPrice: "14.00",
//               vatAmount: 2.33,
//               vatPercent: 20,
//               viewOrder: 0,
//             },
//           ],
//         },
//         updated: "2024-03-26T12:32:40.763+00:00",
//         valid: true,
//         version: 0,
//         viewOrder: 1,
//       },
//       {
//         created: "2024-03-26T12:32:40.763+00:00",
//         groupId: 1,
//         id: 198,
//         locationId: "7713dce7-51ec-4870-b251-4bce1c9249f8",
//         name: "Add Prices",
//         product: {
//           1212: [
//             {
//               amount: 14,
//               discount: 0,
//               itemName: " Misc",
//               qty: 1,
//               unitPrice: "14.00",
//               vatAmount: 2.33,
//               vatPercent: 20,
//               viewOrder: 0,
//             },
//           ],
//         },
//         updated: "2024-03-26T12:32:40.763+00:00",
//         valid: true,
//         version: 0,
//         viewOrder: 1,
//       },
//       {
//         created: "2024-03-26T12:32:40.763+00:00",
//         groupId: 1,
//         id: 198,
//         locationId: "7713dce7-51ec-4870-b251-4bce1c9249f8",
//         name: "Add Price",
//         product: {
//           1212: [
//             {
//               amount: 14,
//               discount: 0,
//               itemName: " Misc",
//               qty: 1,
//               unitPrice: "14.00",
//               vatAmount: 2.33,
//               vatPercent: 20,
//               viewOrder: 0,
//             },
//           ],
//         },
//         updated: "2024-03-26T12:32:40.763+00:00",
//         valid: true,
//         version: 0,
//         viewOrder: 1,
//       },
//       {
//         created: "2024-03-26T12:32:40.763+00:00",
//         groupId: 1,
//         id: 198,
//         locationId: "7713dce7-51ec-4870-b251-4bce1c9249f8",
//         name: "Add Prices",
//         product: {
//           1212: [
//             {
//               amount: 14,
//               discount: 0,
//               itemName: " Misc",
//               qty: 1,
//               unitPrice: "14.00",
//               vatAmount: 2.33,
//               vatPercent: 20,
//               viewOrder: 0,
//             },
//           ],
//         },
//         updated: "2024-03-26T12:32:40.763+00:00",
//         valid: true,
//         version: 0,
//         viewOrder: 1,
//       },
//     ],
//     id: 1,
//     locationId: "7713dce7-51ec-4870-b251-4bce1c9249f8",
//     name: "Default Menu",
//     updated: "2024-03-26T12:32:40.226+00:00",
//     valid: false,
//     version: 0,
//     viewOrder: 0,
//   },
//   {
//     created: "2024-03-26T12:32:40.226+00:00",
//     department: [
//       {
//         created: "2024-03-26T12:32:40.763+00:00",
//         groupId: 1,
//         id: 198,
//         locationId: "7713dce7-51ec-4870-b251-4bce1c9249f8",
//         name: " Price",
//         product: {
//           1212: [
//             {
//               amount: 14,
//               discount: 0,
//               itemName: " Misc",
//               qty: 1,
//               unitPrice: "14.00",
//               vatAmount: 2.33,
//               vatPercent: 20,
//               viewOrder: 0,
//             },
//           ],
//         },
//         updated: "2024-03-26T12:32:40.763+00:00",
//         valid: true,
//         version: 0,
//         viewOrder: 1,
//       },
//       {
//         created: "2024-03-26T12:32:40.763+00:00",
//         groupId: 1,
//         id: 198,
//         locationId: "7713dce7-51ec-4870-b251-4bce1c9249f8",
//         name: "Prices",
//         product: {
//           1212: [
//             {
//               amount: 14,
//               discount: 0,
//               itemName: " Misc",
//               qty: 1,
//               unitPrice: "14.00",
//               vatAmount: 2.33,
//               vatPercent: 20,
//               viewOrder: 0,
//             },
//           ],
//         },
//         updated: "2024-03-26T12:32:40.763+00:00",
//         valid: true,
//         version: 0,
//         viewOrder: 1,
//       },
//     ],
//     id: 3,
//     locationId: "7713dce7-51ec-4870-b251-4bce1c9249f8",
//     name: "test",
//     updated: "2024-03-26T12:32:40.226+00:00",
//     valid: false,
//     version: 0,
//     viewOrder: 0,
//   },
// ];

export interface paymentReportProps {}

/* Get the sale item details using API call and get the location information */
const ItemSalesByCategoryReport: React.FunctionComponent<
  paymentReportProps
> = () => {
  const [itemsReportNodeListInitial, setItemsReportNodeListInitial] =
    useState<any>();
  const [error, setError] = useState("");
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(true);
  const [filterDetails, setFilterDetails] = useState("");
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [locationSelectedList, setLocationSelectedList] = useState([]);
  const [itemsSalesByCategoryNode, setItemsSalesByCategoryNode] = useState([]);
  const [itemsSalesByCategoryNodeInitial, setItemsSalesByCategoryNodeInitial] = useState([]);
  const [selectedCatMenuTitle, setSelectedCatMenuTitle] = useState("");
  const [menuNodeList, setMenuNodeList] = useState<any>([]);
  const [departmentList, setDepartmentList] = useState<any>([]);
  const [nodes, setNodes] = useState<any>({ nodes: [] });

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");

  /* Get all menu option information using API call and response data set to menuOptionNodeList state. */
  const getGroupInfo = async (itemSalesReport: any) => {
    try {
      const res = await fetchGlobalGroupInfo(match.params.locationId);
      getDepartmentInfo(itemSalesReport, res.data.data);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Get all category menu information using API call and response data set to menuNodeList state. */
  const getDepartmentInfo = async (itemSalesReport: any, groupNode: any) => {
    try {
      const res = await fetchGlobalDepartmentInfo(match.params.locationId);
      getProductInfo(itemSalesReport, groupNode, res.data.data);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsOpenSkeletonLoading(false);
    }
  };

  const getProductInfo = async (
    itemSalesReport: any,
    groupNode: any,
    departmentNode: any,
  ) => {
    try {
      const res = await fetchGlobalProductsInfo(match.params.locationId);
      const menu = await handleMenuRestructure(
        groupNode,
        departmentNode,
        res.data.data,
        itemSalesReport,
      );
      setSelectedCatMenuTitle("All");
      setItemsSalesByCategoryNode(menu);
      setItemsSalesByCategoryNodeInitial(menu)
      setIsOpenSkeletonLoading(false);
    } catch (err) {
      console.log("sdf342asdasdas", err);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsOpenSkeletonLoading(false);
    }
  };

  const handleMenuRestructure = (
    groupNode: any,
    departmentNode: any,
    productNode: any,
    itemSalesReport: any,
  ) => {
    if (
      !_.isEmpty(groupNode) &&
      !_.isEmpty(departmentNode) &&
      !_.isEmpty(productNode) &&
      !_.isEmpty(itemSalesReport)
    ) {
      const filterDepartment = departmentNode.sort(
        (a: any, b: any) => a.viewOrder - b.viewOrder,
      );
      const filterProduct = productNode.sort(
        (a: any, b: any) => a.viewOrder - b.viewOrder,
      );
      const filterGroup = groupNode.sort(
        (a: any, b: any) => a.viewOrder - b.viewOrder,
      );
      const linkedId: any = [];
      const output: any = [];
      const groupList: any = [{ id: "all", name: "All" }];
      filterGroup.forEach((groupItem: any) => {
        const groupObject: any = {
          ...groupItem,
          department: [],
        };
        const matchingDepartments = filterDepartment.filter(
          (departmentItem: any) =>
            departmentItem.groupId === groupItem.id &&
            departmentItem.name !== "Online Hidden" &&
            departmentItem.name !== "Templates" &&
            departmentItem.name !== "Hidden Food" &&
            departmentItem.name !== "Hidden Drinks",
        );
        matchingDepartments.forEach((departmentItem: any) => {
          const departmentObject: any = {
            ...departmentItem,
            product: {},
          };
          const matchingProducts = filterProduct.filter(
            (productItem: any) =>
              productItem.departmentId === departmentItem.id,
          );
          matchingProducts.forEach((productItem: any) => {
            if (!departmentObject.product[productItem.id]) {
              departmentObject.product[productItem.id] = {};
            } else {
              linkedId.push(`${productItem.departmentId}*${productItem.id}`);
            }

            if (itemSalesReport[productItem.id]) {
              itemSalesReport[productItem.id].map((data: any) => {
                data["viewOrder"] = productItem.viewOrder;
              });

              departmentObject.product[productItem.id] =
                itemSalesReport[productItem.id];
              console.log(
                "As324adasdasd",
                departmentObject.product[productItem.id],
                itemSalesReport[productItem.id],
              );
            }
          });
          // Convert object to array of key-value pairs
          // Extract keys and values into separate arrays
          const keys = Object.keys(departmentObject.product);
          const values = Object.values(departmentObject.product);

          // Create a new object using the sorted keys and values
          const outputs: any = {};
          if (!_.isEmpty(values)) {
            keys.forEach((key, index) => {
              if (!_.isEmpty(values[index])) {
                outputs[key] = values[index];
              }
            });
          }
          if (Object.values(outputs).length > 0) {
            departmentObject.product = outputs;
          }
          if (
            !_.isEmpty(departmentObject) &&
            Object.values(outputs).length > 0
          ) {
            groupObject.department.push(departmentObject);
          }
        });
        if (!_.isEmpty(groupObject) && groupObject.department.length > 0) {
          output.push(groupObject);
        }
      });
      output.filter((data: any) => {
        groupList.push({ id: data.id, name: data.name });
      });
      console.log("sdfffffff123asdas", groupList);
      setMenuNodeList(groupList);

      // setLinkedIdList(linkedId);
      return output;
    }
  };

  /* Get the sale items details */
  const getSaleItemReportInfo = async (filter: any, basedOnShift: boolean) => {
    try {
      const res = await fetchAllItemsReportInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );
      
      getGroupInfo(res.data.data);
    } catch (err) {
      setIsOpenSkeletonLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Get location details. */
  const getAllFilterLocation = async (searchName: any) => {
    fetchAllFilterLocations(searchName)
      .then((res) => {
        let locationList: any = [];
        /* Setting up the list of locations as needed to select locations. */
        if (!_.isEmpty(res.data.data)) {
          res.data.data.map((location: any) => {
            locationList.push({
              id: location.id,
              label: location.businessDisplayName,
            });
          });
          const locationName = getFilterListFromArrayObject(
            locationList,
            match.params.locationId,
          );
        }
        setLocationSelectorList(locationList);
        setIsGetLocationInfo(true);
      })
      .catch(() => {
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };

  useEffect(() => {
    document.title = "Sale - Item Sales";
    getAllFilterLocation("");
  }, []);

  // Get filter data
  const handleFilterData = (filterData: any, basedOnShift: boolean) => {
    setIsOpenSkeletonLoading(true);
    getSaleItemReportInfo(filterData, basedOnShift);
  };

  const handleOnClickText = () => {
    setOpenFilterCard(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /*Get location list API call after typing. */
  const handleLocationSelectorTypingList = (searchName: any) => {};

  const handleChangeCatMenu = (event: any) => {
    const customDepartmentList: any = [];

    if (event.id === "all") {
      const cloneItemsReportNodeList = _.cloneDeep(itemsSalesByCategoryNodeInitial);

      setItemsSalesByCategoryNode(itemsSalesByCategoryNodeInitial);
      itemsSalesByCategoryNode.map((group: any, index: any) => {
        group.department.map((department: any, indexDep: any) => {
          if (event.id === group.id) {
            customDepartmentList.push({
              id: department.id,
              name: department.name,
            });
          }
        });
      });
    } else {
      const cloneItemsReportNodeList = _.cloneDeep(itemsSalesByCategoryNodeInitial);

      const filter = cloneItemsReportNodeList?.filter(
        (group: any) => group.id === event.id,
      );
      setItemsSalesByCategoryNode(filter);

      cloneItemsReportNodeList.map((group: any, index: any) => {
        group.department.map((department: any, indexDep: any) => {
          if (event.id === group.id) {
            customDepartmentList.push({
              id: department.id,
              name: department.name,
            });
          }
        });
      });
    }

    setSelectedCatMenuTitle(event.name);
    setDepartmentList(customDepartmentList);
  };
  return (
    <>
      <SaleReport
        handleFilterData={handleFilterData}
        locationSelectorList={locationSelectorList}
        handleLocationSelectorTypingList={handleLocationSelectorTypingList}
        setFilterDetails={setFilterDetails}
        availableFilter={salesItemsFilterObject}
        isOpenSkeletonLoading={isOpenSkeletonLoading}
        nodeList={itemsSalesByCategoryNode}
        filterDetails={filterDetails}
        title={""}
        isChart={false}
        isGetLocationInfo={isGetLocationInfo}
        isSaleItem={true}
        locationSelectedList={locationSelectedList}
        setLocationSelectedList={setLocationSelectedList}
        openFilterCard={openFilterCard}
        setOpenFilterCard={setOpenFilterCard}
        handleOnClickText={handleOnClickText}
        topic="Sales Items Report"
        isReport={true}
        isWeekDateRange={true}
        selectedCatMenuTitle={selectedCatMenuTitle}
        menuNodeList={menuNodeList}
        handleChangeCatMenu={handleChangeCatMenu}
        departmentList={departmentList}
        isNeedDropdown={true}
      >
        <ItemsReportInfoNode
          filterDetails={filterDetails}
          locationSelectedList={locationSelectedList}
          handleOnClickText={handleOnClickText}
          itemsSalesByCategoryNode={itemsSalesByCategoryNode}
          setDepartmentList={setDepartmentList}
          nodes={nodes}
          setNodes={setNodes}
        />
      </SaleReport>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(ItemSalesByCategoryReport, Authorities.SALE_READ);
