import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Hidden,
  createStyles,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import Filter from "../Filter";
import SkeleTonLoading from "../Loading/SkeleTonLoading";
import NoSalesFound from "../NoSalesFound";
import _ from "lodash";
import SaleSubTitle from "./SaleSubTitle/SaleSubTitle";
import SaleTitle from "./SaleTitle/SaleTitle";
import SalePageTitleAndLocationDetails from "./SalePageTitleAndLocationDetails";
import moment from "moment";
import PageHeaderMobile from "../PageHeader/PageHeaderMoblie";
import FilterCustomer from "../FilterCustomer";
import CategoryMenuDropDown from "./CategoryMenuDropDown";
import CatMenuPopUp from "./CatMenuPopUp";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    avatarStyle: {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.main,
      color: "white",
      width: "54px",
      height: "54px",
      marginLeft: "16px",
    },
    menuStyle: {
      padding: "20px",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
      backgroundColor: theme.palette.background.imgBackground,
    },
    cardStyle: {
      backgroundColor: theme.palette.background.default,
      boxShadow: "none",
    },
    categoryTitle: {
      backgroundColor: theme.palette.background.default,
      position: "sticky",
      top: 194,
      zIndex: 1,
      fontSize: "20px",
      [theme.breakpoints.up("sm")]: {
        top: 200,
        paddingTop: "20px",
        fontSize: "32px",
      },
      [theme.breakpoints.up("1280")]: {
        fontSize: "32px",
        top: 62,
        paddingTop: "52px",
      },
    },
  }),
);

export interface paymentReportProps {
  handleFilterData: any;
  locationSelectorList: any;
  handleLocationSelectorTypingList: any;
  isGetLocationInfo: any;
  getUserInfo?: any;
  userListFilter?: any;
  setFilterDetails: any;
  availableFilter: any;
  isOpenSkeletonLoading: any;
  nodeList: any;
  filterDetails: any;
  children: React.ReactNode;
  title?: string;
  isChart: any;
  payInOut?: any;
  isUserGet?: any;
  isSale?: any;
  isSaleItem?: any;
  topic?: any;
  getPreviousFilterInfo?: any;
  filterTitle?: any;
  isNeedFilterDetails?: any;
  isShift?: any;
  isOrder?: any;
  setCurrentStartDate?: any;
  setCurrentEndDate?: any;
  setPreviousStartDate?: any;
  setPreviousEndDate?: any;
  previousStartDate?: any;
  previousEndDate?: any;
  isDailySale?: any;
  locationSelectedList: any;
  setLocationSelectedList: any;
  openFilterCard: any;
  setOpenFilterCard: any;
  handleOnClickText?: any;
  isReport: boolean;
  isInventory?: any;
  isWeekDateRange?: any;
  errorTitle?: any;
  isCustomer?: any;
  isInitialLoad?: any;
  setIsInitialLoad?: any;
  initialParamSize?: any;
  isOrderReport?: any;
  selectedCatMenuTitle?: any;
  menuNodeList?: any;
  handleChangeCatMenu?: any;
  departmentList?: any;
  isNeedDropdown?: any;
}
/* Set the filter, sale title, sale sub title, and table using filter details */
const SaleReport: React.FunctionComponent<paymentReportProps> = ({
  handleFilterData,
  locationSelectorList,
  handleLocationSelectorTypingList,
  isGetLocationInfo,
  getUserInfo,
  userListFilter,
  setFilterDetails,
  availableFilter,
  isOpenSkeletonLoading,
  nodeList,
  filterDetails,
  children,
  title,
  isChart,
  payInOut,
  isUserGet,
  isSale,
  isSaleItem,
  topic,
  getPreviousFilterInfo,
  filterTitle,
  isNeedFilterDetails,
  isShift,
  isOrder,
  setCurrentStartDate,
  setCurrentEndDate,
  setPreviousStartDate,
  setPreviousEndDate,
  previousStartDate,
  previousEndDate,
  isDailySale = false,
  locationSelectedList,
  setLocationSelectedList,
  openFilterCard,
  setOpenFilterCard,
  handleOnClickText,
  isReport,
  isInventory,
  isWeekDateRange,
  errorTitle,
  isCustomer,
  isInitialLoad,
  setIsInitialLoad,
  initialParamSize,
  isOrderReport,
  selectedCatMenuTitle,
  menuNodeList,
  handleChangeCatMenu,
  departmentList,
  isNeedDropdown,
}) => {
  const [filterLocation, setFilterLocation] = useState("");
  const [filterDate, setFilterDate] = useState("");

  /* Change the filter location details and filter date using filter details list */
  useEffect(() => {
    const filterLocation = filterDetails.split("*")[0].split(",");
    if (filterLocation.length === 1) {
      setFilterLocation(filterLocation[0]);
    } else {
      setFilterLocation(filterLocation.length + " locations");
    }
    const startDateAndDate = filterDetails.split("*")[1];

    if (startDateAndDate) {
      if (startDateAndDate.split(" - ")[1]) {
        const startDate = moment(startDateAndDate.split(" - ")[0]).format(
          "Do MMM YYYY",
        );
        const endDate = moment(startDateAndDate.split(" - ")[1]).format(
          "Do MMM YYYY",
        );
        const convertedStartDateAndEndDate = `${startDate} - ${endDate}`;
        setFilterDate(convertedStartDateAndEndDate);
      } else {
        const startDate = moment(startDateAndDate.split(" - ")[0]).format(
          "Do MMM YYYY",
        );
        const convertedStartDateAndEndDate = `${startDate} - ${startDate}`;
        setFilterDate(convertedStartDateAndEndDate);
      }
    }
  }, [filterDetails]);

  const maxWidth = useMediaQuery("(max-width: 960px)");
  const maxWidthCustom = useMediaQuery("(max-width: 2600px)");
  const classes = useStyles();

  return (
    <>
      <Box>
        <Hidden lgUp>
          <PageHeaderMobile />
        </Hidden>
        {isCustomer ? (
          <FilterCustomer
            getFilterData={handleFilterData}
            locationSelectorList={locationSelectorList}
            getLocationSelectorTypingList={handleLocationSelectorTypingList}
            isGetLocationInfo={isGetLocationInfo}
            setFilterDetails={setFilterDetails}
            locationSelectedList={locationSelectedList}
            setLocationSelectedList={setLocationSelectedList}
            openFilterCard={openFilterCard}
            setOpenFilterCard={setOpenFilterCard}
          />
        ) : (
          <Filter
            getFilterData={handleFilterData}
            locationSelectorList={locationSelectorList}
            getLocationSelectorTypingList={handleLocationSelectorTypingList}
            isGetLocationInfo={isGetLocationInfo}
            getUserInfo={getUserInfo}
            userList={userListFilter}
            setFilterDetails={setFilterDetails}
            availableFilter={availableFilter}
            isChart={isChart}
            payInOut={payInOut}
            isUserGet={isUserGet}
            isSale={isSale}
            isSaleItem={isSaleItem}
            getPreviousFilterInfo={getPreviousFilterInfo}
            isShift={isShift}
            isOrder={isOrder}
            setPreviousStartDate={setPreviousStartDate}
            setPreviousEndDate={setPreviousEndDate}
            setCurrentStartDate={setCurrentStartDate}
            setCurrentEndDate={setCurrentEndDate}
            previousStartDate={previousStartDate}
            previousEndDate={previousEndDate}
            locationSelectedList={locationSelectedList}
            setLocationSelectedList={setLocationSelectedList}
            openFilterCard={openFilterCard}
            setOpenFilterCard={setOpenFilterCard}
            isDailySale={isDailySale}
            isWeekDateRange={isWeekDateRange}
            isInitialLoad={isInitialLoad}
            setIsInitialLoad={setIsInitialLoad}
            initialParamSize={initialParamSize}
            isOrderReport={isOrderReport}
          />
        )}

        {isNeedDropdown && !isOpenSkeletonLoading && !_.isEmpty(nodeList) ? (
          <Grid container spacing={2}>
            {/* {!_.isEmpty(menuNodeList) && ( */}
            <Grid item xs={6}>
              {!isOpenSkeletonLoading && !_.isEmpty(nodeList) && (
                <SalePageTitleAndLocationDetails
                  topic={topic}
                  filterDate={filterDate}
                  handleOnClickText={handleOnClickText}
                />
              )}
            </Grid>
            <Grid item xs={6} style={{ marginTop: "40px" }}>
              <div
                style={
                  maxWidthCustom
                    ? { display: "flex", justifyContent: "end", width: "100%" }
                    : { display: "flex" }
                }
              >
                {selectedCatMenuTitle && (
                  <CategoryMenuDropDown
                    categoryMenuList={menuNodeList}
                    selectedCatMenuTitle={selectedCatMenuTitle}
                    handleChangeCatMenu={handleChangeCatMenu}
                  />
                )}
                {selectedCatMenuTitle && (
                  <CatMenuPopUp departmentList={departmentList} />
                )}
              </div>
            </Grid>
            {/* )} */}
          </Grid>
        ) : (
          !isOpenSkeletonLoading &&
          !_.isEmpty(nodeList) && (
            <SalePageTitleAndLocationDetails
              topic={topic}
              filterDate={filterDate}
              handleOnClickText={handleOnClickText}
            />
          )
        )}
        {!isOpenSkeletonLoading ? (
          <>
            {!_.isEmpty(title) && <SaleTitle title={title} />}

            {!_.isEmpty(nodeList) ? (
              <>
                {isChart && !isDailySale && (
                  <SaleSubTitle
                    filterLocation={filterLocation}
                    filterDate={filterDate}
                  />
                )}
                {children}
              </>
            ) : (
              <NoSalesFound
                title={
                  errorTitle
                    ? errorTitle
                    : isOrder || (isOrderReport && "Orders")
                }
              />
            )}
          </>
        ) : (
          <div style={{ marginTop: "80px" }}>
            <SkeleTonLoading isReport={isReport} />
          </div>
        )}
      </Box>
    </>
  );
};

export default SaleReport;
