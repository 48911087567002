/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { Grid, useMediaQuery } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";

import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import CardCommon from "../../../../components/card/CardCommon";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";

export interface DiscountInfoNodeProps {
  nodeData: any;
  handleOpenEditModal: any;
  handleDeactivateStrategy: any;
  isLoadingButtonDeactivate: any;
  handleOpenConfirmationModal: any;
}

/* Create, delete, and update discount information and users can view all discount information. */
const StrategyInfoNode: React.FunctionComponent<DiscountInfoNodeProps> = ({
  nodeData,
  handleOpenEditModal,
  handleDeactivateStrategy,
  isLoadingButtonDeactivate,
  handleOpenConfirmationModal,
}) => {
  const [strategyName, setStrategyName] = useState("");
  const [success, setSuccess] = useState("");
  const [valid, setValid] = useState(false);
  const [id, setId] = useState("");

  /* Update states after creating delete or update banner information. */
  const setNodeDataToState = useCallback((nodeValues) => {
    const { name, valid, id } = nodeValues;
    setValid(valid);
    setStrategyName(name);
    setId(id);
  }, []);

  useEffect(() => {
    setNodeDataToState(nodeData);
  }, [nodeData, setNodeDataToState]);

  const screenWidth = useMediaQuery("(max-width: 600px)");

  return (
    <Grid style={{ width: "100%" }}>
      {/* <ConfirmDeleteDialogDefault
        open={isOpenDiscountResetModal}
        setOpen={setIsOpenDiscountResetModal}
        confirmAction={handleResetDiscount}
      />
      <ConfirmDeleteDialog
        open={openDeleteConfirm}
        setOpen={setOpenDeleteConfirm}
        confirmAction={handleDeleteDiscountOption}
      /> */}
      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />
      <CardCommon backgroundColor={"entity_background"}>
        <Grid container style={{ height: screenWidth ? "100px":"60px", placeItems: "center" }}>
          <Grid item xs={12} sm={6} style={{ paddingLeft: "12px" }}>
            <Typography variant="h5" align="left">
              {strategyName && strategyName}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              justifyContent: screenWidth?"space-between":"end",
              paddingRight: "12px",
              paddingLeft: screenWidth?"12px":"0px",
              marginBottom: screenWidth?"6px":"0px",
            }}
          >
            <ButtonCommon
              style={{
                fontSize: 11,
                marginRight: 12,
                width: "100px",
              }}
              variant="contained"
              color="yellow"
              onClick={() => handleOpenEditModal(nodeData)}
            >
              Edit
            </ButtonCommon>
            <ButtonCommon
              isLoadingPage={isLoadingButtonDeactivate?.includes(id)}
              variant="contained"
              style={{ fontSize: 11, width: "100px", marginRight: 12 }}
              color={valid ? "red" : "green"}
              onClick={() => handleDeactivateStrategy(nodeData, valid)}
            >
              {valid ? "Deactivate" : "Activate"}
            </ButtonCommon>
            <ButtonCommon
              variant="contained"
              style={{
                fontSize: 11,
                width: "100px",
              }}
              onClick={() => handleOpenConfirmationModal(nodeData)}
              color="blue"
            >
              Duplicate
            </ButtonCommon>
          </Grid>
        </Grid>
      </CardCommon>
    </Grid>
  );
};

export default withAuthority(StrategyInfoNode, Authorities.DISCOUNT_READ);
