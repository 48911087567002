import { createStyles, Grid, makeStyles } from "@material-ui/core";
import _ from "lodash";
import { useRouteMatch } from "react-router-dom";
import React, {  useState } from "react";

import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import WithLoading from "../../../../utils/WithLoading";
import StrategyInfoNode from "./StrategyInfoNode";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import StrategyAddEditModal from "./StrategyAddEditModal";
import {
  createDuplicateStrategyInfo,
  createStrategyInfo,
  fetchSingleStrategyInfo,
  updateStrategyInfo,
} from "../../../../services/menuApp/printerMenuService";
import {
  combineMinuteOfWeekArrays,
  normalizeTimeSelections,
} from "../../../../utils/time-conversions";
import { HttpStatus } from "../../../../utils/enum";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import AlertDialogDefault from "../../../../components/alerts/AlertDialogDefault";
import FilterCustom from "../../../../components/common/FilterCustom";

export interface DiscountTypesProps {
  nodes: Array<any>;
  isLoading: boolean;
  setError: any;
  getStrategyInfo: any;
}

/* This page has 2 tab panels. They are percentage, and amount. 
Information related to those panels is designed to be visible to the user using cards */
const StrategyPageLoading: React.FunctionComponent<DiscountTypesProps> = ({
  nodes,
  isLoading,
  setError,
  getStrategyInfo,
}) => {
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [strategyName, setStrategyName] = useState("");
  const [strategyNameInitial, setStrategyNameInitial] = useState("");
  const [deliveryTypesList, setDeliveryTypesList] = useState<any>([]);
  const [deliveryTypesListInitial, setDeliveryTypesListInitial] = useState<any>(
    [],
  );
  const [availability, setAvailability] = useState<any>({});
  const [availabilityInitial, setAvailabilityInitial] = useState<any>({});
  const [availabilityErrorMessage, setAvailabilityErrorMessage] = useState("");
  const [isDeliveryTypeEmpty, setIsDeliveryTypeEmpty] = useState(false);
  const [isNameEmpty, setIsNameEmpty] = useState(false);
  const [isAvailabilityEmpty, setIsAvailabilityEmpty] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isLoadingButtonDeactivate, setIsLoadingButtonDeactivate] =
    useState<any>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [id, setId] = useState("");
  const [version, setVersion] = useState(0);
  const [nodeData, setNodeData] = useState<any>([]);
  const [success, setSuccess] = useState("");
  const [openFilterCard, setOpenFilterCard] = useState();
  const [activeInactiveSelectedList, setActiveInactiveSelectedList] = useState<any>(
    [],
  );

  const match: any = useRouteMatch();

  const handleReset = () => {
    setIsEdit(false);
    setIsDuplicate(false);
    setIsOpenEditModal(false);
    setStrategyName("");
    setDeliveryTypesList([]);
    setDeliveryTypesListInitial([]);
    setAvailability({});
    setAvailabilityInitial({});
    setIsLoadingButtonDeactivate([])
    setIsLoadingButton(false)
    setIsNameEmpty(false)
    setIsDeliveryTypeEmpty(false)
    setNodeData([])
    setId("");
    setVersion(0);
    setStrategyNameInitial("");
    setIsAvailabilityEmpty(false)
  };

  const handleOpenAddStrategyModal = () => {
    setIsEdit(false);
    setIsDuplicate(false);
    setIsOpenEditModal(true);
    setAvailability({ availability: [] });
    setAvailabilityInitial({ availability: [] });
  };

  const handleOpenEditModal = (nodeData: any) => {
    setIsEdit(true);
    setIsDuplicate(false);
    setAvailability({ availability: nodeData.availability });
    setAvailabilityInitial({ availability: nodeData.availability });
    setStrategyName(nodeData.name);
    setStrategyNameInitial(nodeData.name);
    setDeliveryTypesList(nodeData.deliveryTypes);
    setDeliveryTypesListInitial(nodeData.deliveryTypes);
    setIsOpenEditModal(true);
    setId(nodeData.id);
    setVersion(nodeData.version);
  };

  const handleOpenDuplicateEditModal = (nodeData: any) => {
    setAvailability({ availability: nodeData.availability });
    setAvailabilityInitial({ availability: nodeData.availability });
    setStrategyName(`Copy of ${nodeData.name}`);
    setStrategyNameInitial(nodeData.name);
    setDeliveryTypesList(nodeData.deliveryTypes);
    setDeliveryTypesListInitial(nodeData.deliveryTypes)
    setIsOpenEditModal(true);
    setId(nodeData.id);
    setVersion(nodeData.version);
  };

  const handleOpenConfirmationModal = (nodeData: any) => {
    setIsEdit(false);
    setIsDuplicate(true);
    setNodeData(nodeData);
  };

  const handleCreateNewStrategy = async (strategyInfo: any) => {
    try {
      const res = await createStrategyInfo(
        match.params.locationId,
        strategyInfo,
      );
      getStrategyInfo();
      handleReset()
      setSuccess(SUCCESSFULLY_CREATED);
    } catch (error) {
      setIsLoadingButton(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleDuplicateStrategy = async (strategyInfo: any, id: any) => {
    try {
      const res = await createDuplicateStrategyInfo(
        match.params.locationId,
        strategyInfo,
        id,
      );
      getStrategyInfo();
      handleReset()
      setSuccess(SUCCESSFULLY_CREATED);
    } catch (error) {
      setIsLoadingButton(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingButton(false);
    }
  };

  const getSingleStrategyInfo = async (strategyInfo: any) => {
    try {
      const res = await fetchSingleStrategyInfo(match.params.locationId, id || strategyInfo.id);
      setVersion(res.data.data.version);
      strategyInfo.version = res.data.data.version;
      handleUpdateNewStrategy(strategyInfo);
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleUpdateNewStrategy = async (strategyInfo: any) => {
    try {
      const res = await updateStrategyInfo(
        match.params.locationId,
        strategyInfo,
      );
      if (res.data.status === HttpStatus.CONFLICT_409) {
        getSingleStrategyInfo(strategyInfo);
      } else {
        getStrategyInfo();
        handleReset()
        setSuccess(SUCCESSFULLY_UPDATED);
      }
    } catch (error) {
      setIsLoadingButton(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };
  
  const handleSubmit = () => {
    const createStrategyInfo: any = {
      name: strategyName,
      locationId: match.params.locationId,
      deliveryTypes: deliveryTypesList,
      availability: availability.availability,
    };
    if (_.isEmpty(strategyName) || _.isEmpty(deliveryTypesList)) {
      if (_.isEmpty(strategyName)) {
        setIsNameEmpty(true);
      }
      if (_.isEmpty(deliveryTypesList)) {
        setIsDeliveryTypeEmpty(true);
      }
    } else {
      setIsLoadingButton(true);
      if (isEdit) {
        createStrategyInfo.id = id;
        createStrategyInfo.version = version;
        handleUpdateNewStrategy(createStrategyInfo);
      } else if (isDuplicate) {
        handleDuplicateStrategy(createStrategyInfo, id);
      }  else {
        handleCreateNewStrategy(createStrategyInfo);
      }
    }
  };

  const handleDeactivateStrategy = (nodeData: any, valid: any) => {
    setIsLoadingButtonDeactivate((prevState: any) => [
      ...prevState,
      nodeData.id,
    ]);
    const cloneData = _.cloneDeep(nodeData);
    cloneData.valid = !valid;
    handleUpdateNewStrategy(cloneData);
  };

  /* Send an API call to after click availability apply button. 
  An API call is sent only if the status is not "create". */
  const handleSubmitAvailability = async (
    availabilitySelectionList: Array<any>,
  ) => {
    setIsAvailabilityEmpty(false);
    setError("");
    /* availabilitySelectionList = [{openTime: '11:49', closeTime: '11:49', id: 2, 
                                         nodeList: [{minuteOfWeek: 3589, durationMins: 1440, day: 'tue', openTime: '11:49', closeTime: '11:49'},
                                                    {minuteOfWeek: 6469, durationMins: 1440, day: 'thu', openTime: '11:49', closeTime: '11:49'}]}]
           combinedMowArrays = {tue: [{minuteOfWeek: 3589, durationMins: 1440, day: 'tue', openTime: '11:49', closeTime: '11:49'},
                                thu:  {minuteOfWeek: 6469, durationMins: 1440, day: 'thu', openTime: '11:49', closeTime: '11:49'}]} */
    const combinedMowArrays: any = combineMinuteOfWeekArrays(
      availabilitySelectionList,
    );
    const normalizedArray = _.cloneDeep(combinedMowArrays);

    // Remove select same date range in same same date.
    Object.keys(combinedMowArrays).forEach((day) => {
      normalizedArray[day] = normalizeTimeSelections(normalizedArray[day]);
    });

    // Convert array to minute of week array
    const mowArray: any = Object.values(normalizedArray).flat();
    setAvailability({ availability: mowArray });
  };

  return (
    <>
      <Grid container spacing={2} style={{ placeItems: "center" }}>
        <Grid item xs={4} lg={3}>
          <FilterCustom
            openFilterCard={openFilterCard}
            setOpenFilterCard={setOpenFilterCard}
            activeInactiveSelectedList={activeInactiveSelectedList}
            setActiveInactiveSelectedList={setActiveInactiveSelectedList}
          />
        </Grid>
        <Grid item xs={8} lg={9} style={{ display: "flex", justifyContent: "end" }}>
          <ButtonCommon
            style={{
              fontSize: 11,
              width: "160px",
              height: "40px",
            }}
            variant="contained"
            color="green"
            onClick={handleOpenAddStrategyModal}
          >
            Add New Strategy
          </ButtonCommon>
        </Grid>
        {!_.isEmpty(nodes) &&
          nodes.map((nodeData: any) =>
            _.isEmpty(activeInactiveSelectedList) ||
            activeInactiveSelectedList.length === 2 ? (
              <Grid item xs={12} key={nodeData.id} style={{ display: "flex" }}>
                <StrategyInfoNode
                  nodeData={nodeData}
                  handleOpenEditModal={handleOpenEditModal}
                  handleDeactivateStrategy={handleDeactivateStrategy}
                  isLoadingButtonDeactivate={isLoadingButtonDeactivate}
                  handleOpenConfirmationModal={handleOpenConfirmationModal}
                />
              </Grid>
            ) : activeInactiveSelectedList[0]?.id === "active" ? (
              nodeData.valid && (
                <Grid
                  item
                  xs={12}
                  key={nodeData.id}
                  style={{ display: "flex" }}
                >
                  <StrategyInfoNode
                    nodeData={nodeData}
                    handleOpenEditModal={handleOpenEditModal}
                    handleDeactivateStrategy={handleDeactivateStrategy}
                    isLoadingButtonDeactivate={isLoadingButtonDeactivate}
                    handleOpenConfirmationModal={handleOpenConfirmationModal}
                  />
                </Grid>
              )
            ) : (
              !nodeData.valid && (
                <Grid
                  item
                  xs={12}
                  key={nodeData.id}
                  style={{ display: "flex" }}
                >
                  <StrategyInfoNode
                    nodeData={nodeData}
                    handleOpenEditModal={handleOpenEditModal}
                    handleDeactivateStrategy={handleDeactivateStrategy}
                    isLoadingButtonDeactivate={isLoadingButtonDeactivate}
                    handleOpenConfirmationModal={handleOpenConfirmationModal}
                  />
                </Grid>
              )
            ),
          )}
      </Grid>
      {isOpenEditModal && (
        <StrategyAddEditModal
          isOpen={isOpenEditModal}
          setIsOpen={setIsOpenEditModal}
          setStrategyName={setStrategyName}
          strategyName={strategyName}
          deliveryTypesList={deliveryTypesList}
          setDeliveryTypesList={setDeliveryTypesList}
          handleSubmit={handleSubmit}
          availability={availability}
          handleSubmitAvailability={handleSubmitAvailability}
          setAvailabilityErrorMessage={setAvailabilityErrorMessage}
          isDeliveryTypeEmpty={isDeliveryTypeEmpty}
          setIsNameEmpty={setIsNameEmpty}
          setIsDeliveryTypeEmpty={setIsDeliveryTypeEmpty}
          isNameEmpty={isNameEmpty}
          isAvailabilityEmpty={isAvailabilityEmpty}
          isLoadingButton={isLoadingButton}
          isEdit={isEdit}
          isDuplicate={isDuplicate}
          handleReset={handleReset}
          deliveryTypesListInitial={deliveryTypesListInitial}
          availabilityInitial={availabilityInitial}
          strategyNameInitial={strategyNameInitial}
        />
      )}
      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />
      <AlertDialogDefault
        open={isDuplicate}
        confirmAction={() => handleOpenDuplicateEditModal(nodeData)}
        cancelAction={() => setIsDuplicate(false)}
        title="Confirm Printer Mapping Duplication"
        desc="Are you sure you want to duplicate this printer mapping strategy? All associated category and item printer mappings will also be duplicated."
        severity="warning"
        confirmLabel="Confirm Duplicate"
        cancelLabel="cancel"
        color="secondary"
        disableBackdropClick
        isLoading={isLoadingButton}
      />
    </>
  );
};

export default WithLoading(StrategyPageLoading);
