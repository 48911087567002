import { Box, Hidden, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";

import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import withAuthority from "../../../../components/Auth/withAuthority";
import { fetchAllCategoryMenu } from "../../../../services/menuApp/categoryMenuService";
import { CustomTheme } from "../../../../types/customTheme";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { getCategoryListByCatMenu } from "../../../../utils/DiscountUtils";
import DiscountTypes from "./StrategyPageLoading";
import { fetchAllDiscountInfo } from "../../../../services/eatprestoApp/discountInfoService";
import PageHeaderMobile from "../../../../components/common/PageHeader/PageHeaderMoblie";
import { fetchStrategyInfo } from "../../../../services/menuApp/printerMenuService";
import StrategyPageLoading from "./StrategyPageLoading";

export interface DiscountsProps {}

/* Get all discount information, category menu details and location information using API call at initial load. */
const MappingStrategies: React.FunctionComponent<DiscountsProps> = () => {
  const [strategyNodeList, setStrategyNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const match: any = useRouteMatch();

  /* Get category menu details */
  const getStrategyInfo = async () => {
    try {
      const res = await fetchStrategyInfo(match.params.locationId);
      setStrategyNodeList(res.data.data)
      setIsLoading(false);
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR)
      setIsLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Menu - Mapping Strategies";
    getStrategyInfo();
  }, []);

  const theme: CustomTheme = useTheme();

  return (
    <>
      <Hidden lgUp>
        <PageHeaderMobile />
      </Hidden>
      <Box style={{ marginTop: "18px" }}>
        <StrategyPageLoading
          nodes={strategyNodeList}
          isLoading={isLoading}
          setError={setError}
          getStrategyInfo={getStrategyInfo}
        />
      </Box>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(MappingStrategies, Authorities.DISCOUNT_READ);
