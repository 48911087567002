import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { useRouteMatch } from "react-router-dom";
import _ from "lodash";

import DialogCommonDefault from "../../../../../components/dialogs/DialogCommonDefault";
import {
  createMapProductInfo,
  deleteMapProductInfo,
  fetchHardwareInfo,
  fetchPrinterMappingInfo,
  fetchProductConfigInfo,
  fetchSinglePrinterMappingInfo,
  fetchStrategyInfo,
  updateMapProductInfo,
} from "../../../../../services/menuApp/printerMenuService";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../../utils/consts";
import CardCommon from "../../../../../components/card/CardCommon";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";
import { HttpStatus, buttonColors } from "../../../../../utils/enum";
import PrinterNode from "./PrinterNode";
import { AxiosError } from "axios";
import { CustomTheme } from "../../../../../types/customTheme";

export interface MappingModalProps {
  isOpen: any;
  setIsOpen: any;
  setError: any;
  printerMappingItemName: any;
  selectedPrinterMappingItemOrCategoryId: any;
  type: any;
  selectedCatMenuTitle: any;
  categoryName: any;
}

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    discountTypeSection: {
      borderRadius: 8,
    },
    tabPanel: {
      backgroundColor: theme.palette.background.paper,
    },
    circleStyle: {
      fontSize: "16px",
      marginTop: "4px",
      color: theme.palette.custom.green.main,
    },
  }),
);

interface TabPanelProps {
  children: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      className={classes.tabPanel}
      hidden={value !== index}
      id={`discount-type-tab-${index}`}
      aria-labelledby={`discount-type-tab-${index}`}
      {...other}
    >
      {value === index && <div style={{ padding: "16px 0" }}>{children}</div>}
    </div>
  );
};

const MappingModal: React.FunctionComponent<MappingModalProps> = ({
  isOpen,
  setIsOpen,
  setError,
  printerMappingItemName,
  selectedPrinterMappingItemOrCategoryId,
  type,
  selectedCatMenuTitle,
  categoryName,
}) => {
  const [value, setValue] = useState(0);
  const [printerList, setPrinterList] = useState<any>([]);
  const [strategyList, setStrategyList] = useState([]);
  const [displayNode, setDisplayNode] = useState<any>([]);
  const [availableStrategyIds, setAvailableStrategyIds] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [productConfigVersion, setProductConfigVersion] = useState("");
  const [isLoadingButton, setIsLoadingButton] = useState({});
  const [errorCard, setErrorCard] = useState<any>({});

  const match: any = useRouteMatch();

  const handlePrintersAndHandleDisplaysNode = (hardwareNode: any) => {
    if (!_.isEmpty(hardwareNode)) {
      const filterDisplayList = hardwareNode.filter(
        (data: any) => data.type === "kds",
      );
      setDisplayNode(filterDisplayList);
      const filterPrinterList = hardwareNode.filter(
        (data: any) =>
          data.type === "printer" || data.type === "printer-sticker",
      );

      getPrinterMappingInfo(filterDisplayList, filterPrinterList);
    } else {
      setDisplayNode([]);
      setIsLoading(false);
    }
  };

  const getHardwareInfo = async () => {
    try {
      const res = await fetchHardwareInfo(match.params.locationId);
      //   handleHardwareInfo(res.data.data)
      handlePrintersAndHandleDisplaysNode(res.data.data);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  const getFilteredIds = (data: any) => {
    // Initialize output object
    const output: any = {};

    // Iterate through data array
    data.forEach((item: any) => {
      const filterMapping = item?.filterMapping;

      // Check if filterMapping is not empty
      if (filterMapping && Object.keys(filterMapping).length > 0) {
        // Iterate through keys of filterMapping
        Object.keys(filterMapping).forEach((key) => {
          if (!output[key]) {
            output[key] = []; // Initialize array if not exist
          }

          // Add name to the array
          output[key].push(item?.name);
        });
      }
    });

    return output;
  };

  useEffect(() => {
    if (!_.isEmpty(strategyList)) {
      const mergeList = [...printerList, ...displayNode];
      if (!_.isEmpty(mergeList)) {
        const availableStrategy = getFilteredIds(mergeList);
        setAvailableStrategyIds(availableStrategy);
      } else {
        setAvailableStrategyIds({});
      }
    }
  }, [strategyList, printerList, displayNode]);

  const getStrategyInfo = async () => {
    try {
      const res = await fetchStrategyInfo(match.params.locationId);
      //   handleHardwareInfo(res.data.data)
      setStrategyList(res.data.data);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const getPrinterMappingInfo = async (displayList: any, printerList: any) => {
    try {
      const res = await fetchPrinterMappingInfo(
        match.params.locationId,
        selectedPrinterMappingItemOrCategoryId,
        type,
      );
      handleFilerMapping(res?.data?.data, displayList, printerList);
      //   handleHardwareInfo(res.data.data)
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  const getSinglePrinterMappingInfo = async (
    printerOrDisplay: any,
    hardwareInfo: any,
  ) => {
    try {
      const res = await fetchSinglePrinterMappingInfo(
        match.params.locationId,
        hardwareInfo.id,
      );
      const cloneHardwareInfo = _.cloneDeep(hardwareInfo);
      cloneHardwareInfo.version = res.data.data.version;
      handleUpdateStockMap(printerOrDisplay, cloneHardwareInfo);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  const handleFilerMapping = (
    filter: any,
    displayList: any,
    printerList: any,
  ) => {
    // Create a mapping of printCode to abcd items
    const printCodeMapping = filter?.reduce((acc: any, item: any) => {
      acc[item.printCode] = acc[item.printCode] || {};
      acc[item.printCode][item.strategy] = item;
      return acc;
    }, {});
    // Function to merge abc and filter based on code
    const mergeData = (list: any) => {
      return list.map((item: any) => {
        const filterMapping = printCodeMapping?.[item?.code] ?? {};
        return { ...item, filterMapping };
      });
    };

    const outputDisplayList = mergeData(displayList);
    const outputHardwareList = mergeData(printerList);

    setPrinterList(outputHardwareList);

    setDisplayNode(outputDisplayList);
    setIsLoading(false);
    setIsLoadingButton({});
  };

  const getProductConfig = async () => {
    try {
      const res = await fetchProductConfigInfo(match.params.locationId);
      setProductConfigVersion(res.data.data.printerMappingsVersion);
      //   handleHardwareInfo(res.data.data)
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getHardwareInfo();
    getStrategyInfo();
    getProductConfig();
  }, []);

  const updateInheritance = (list: any, id: any, strategy: any, res: any) => {
    return list.map((item: any) => {
      if (item.code === res.printCode && item.filterMapping[strategy]) {
        return {
          ...item,
          filterMapping: {
            ...item.filterMapping,
            [strategy]: {
              ...item.filterMapping[strategy],
              inheritance: res.inheritance,
            },
          },
        };
      }
      return item;
    });
  };

  const handleChangeCheckBox = (
    event: any,
    hardwareInfo: any,
    printerOrDisplay: any,
    itemOrCategory: any,
    strategyId: any,
  ) => {
    const updatedHardwareInfo = {
      type: itemOrCategory,
      locationId: hardwareInfo.locationId,
      printCode: hardwareInfo.code,
      session: "",
      refId: selectedPrinterMappingItemOrCategoryId,
      strategy: strategyId,
      id: hardwareInfo.filterMapping[strategyId].id,
      inheritance: event.target.checked,
      version: hardwareInfo.filterMapping[strategyId].version,
    };
    handleUpdateStockMap(printerOrDisplay, updatedHardwareInfo);
  };

  /* Switch the tab to be displayed. */
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const updateInheritanceWhenCreate = (list: any, res: any) => {
    return list.map((item: any) => {
      if (item.code === res.printCode) {
        const data = {
          created: res.created,
          id: res.id,
          inheritance: res.inheritance,
          locationId: res.locationId,
          printCode: res.printCode,
          refId: res.refId,
          session: res.session,
          strategy: res.strategy.id,
          type: res.type,
          updated: res.updated,
        };
        return {
          ...item,
          filterMapping: {
            ...item.filterMapping,
            [res.strategy.id]: {
              ...data,
            },
          },
        };
      }
      return item;
    });
  };

  const handleCreateStockMap = async (
    hardwareInfo: any,
    printerOrDisplay: any,
  ) => {
    try {
      const res = await createMapProductInfo(
        match.params.locationId,
        hardwareInfo,
      );
      if (printerOrDisplay === "printer") {
        const output = updateInheritanceWhenCreate(printerList, res.data.data);

        setPrinterList(output);
      } else {
        const output = updateInheritanceWhenCreate(displayNode, res.data.data);
        setDisplayNode(output);
      }
      setIsLoadingButton({});
      setErrorCard({});
      //   handleHardwareInfo(res.data.data)
    } catch (error) {
      // If an error occurs, handle it by setting the error state with the error message.
      const err: any = error as AxiosError;
      setErrorCard((prevState: any) => ({
        ...prevState,
        [hardwareInfo.printCode]: {
          ...prevState[hardwareInfo.printCode],
          message: "Can you try again.", // Assuming you want to update a 'message' property
        },
      }));
      setIsLoadingButton({});
    }
  };

  const handleUpdateStockMap = async (
    printerOrDisplay: any,
    hardwareInfo: any,
  ) => {
    try {
      const res = await updateMapProductInfo(
        match.params.locationId,
        hardwareInfo,
      );
      if (res.data.status === HttpStatus.CONFLICT_409) {
        getSinglePrinterMappingInfo(printerOrDisplay, hardwareInfo);
      } else {
        if (printerOrDisplay === "printer") {
          const output = updateInheritance(
            printerList,
            hardwareInfo.id,
            hardwareInfo.strategy,
            res.data.data,
          );
          setPrinterList(output);
        } else {
          const output = updateInheritance(
            displayNode,
            hardwareInfo.id,
            hardwareInfo.strategy,
            res.data.data,
          );

          setDisplayNode(output);
        }
      }
      setErrorCard({});
      //   handleHardwareInfo(res.data.data)
    } catch (error) {
      // If an error occurs, handle it by setting the error state with the error message.
      const err: any = error as AxiosError;

      if (err?.status === HttpStatus.CONFLICT_409) {
      } else {
        setErrorCard((prevState: any) => ({
          ...prevState,
          [hardwareInfo.printCode]: {
            ...prevState[hardwareInfo.printCode],
            message: "Can you try again.", // Assuming you want to update a 'message' property
          },
        }));
      }
    }
  };

  const handleDeleteMapProduct = async (
    filterMapping: any,
    hardwareInfo: any,
    printerOrDisplay: any,
  ) => {
    setIsLoadingButton({ [hardwareInfo.id]: true });
    try {
      const res = await deleteMapProductInfo(
        match.params.locationId,
        filterMapping.id,
      );
      getPrinterMappingInfo(displayNode, printerList);
      setErrorCard({});
    } catch (error) {
      setIsLoadingButton({});
      setErrorCard((prevState: any) => ({
        ...prevState,
        [hardwareInfo.code]: {
          ...prevState[hardwareInfo.code],
          message: "Can you try again.", // Assuming you want to update a 'message' property
        },
      }));
    }
  };
  const handleSubmit = (
    itemOrCategory: any,
    hardwareInfo: any,
    strategyId: any,
    printerOrDisplay: any,
  ) => {
    setIsLoadingButton({ [hardwareInfo.id]: true });

    const updatedHardwareInfo = {
      type: itemOrCategory,
      locationId: hardwareInfo.locationId,
      printCode: hardwareInfo.code,
      inheritance: true,
      session: "",
      refId: selectedPrinterMappingItemOrCategoryId,
      strategy: strategyId,
    };
    handleCreateStockMap(updatedHardwareInfo, printerOrDisplay);
  };
  const theme: CustomTheme = useTheme();
  const classes: any = useStyles();
  return (
    <>
      {
        <DialogCommonDefault
          open={isOpen}
          setOpen={setIsOpen}
          isNeedFixedHeight={true}
          maxWidth={"xl"}
        >
          <DialogTitle>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <Typography variant="h5">
                  {`Map printers - ${selectedCatMenuTitle} -> ${
                    type === "category" ? "" : categoryName + " ->"
                  }`}
                </Typography>
                <Typography
                  variant="h5"
                  style={{
                    paddingLeft: "4px",
                    color: theme.palette.custom.yellow.main,
                  }}
                >
                  {printerMappingItemName}
                </Typography>
              </div>
              <ButtonCommon
                variant="contained"
                style={{ fontSize: 11, width: "100px" }}
                color={buttonColors.CANCEL_BUTTON_COLOR}
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </ButtonCommon>
            </div>
          </DialogTitle>
          <DialogContent>
            <CardCommon>
              <Tabs
                centered
                value={value}
                variant="scrollable"
                selectionFollowsFocus
                scrollButtons="on"
                onChange={handleChange}
                aria-label="discount-types-tabs"
              >
                {strategyList.map((data: any, index: number) => (
                  <Tab
                    label={
                      availableStrategyIds?.[data.id] ? (
                        <Tooltip
                          title={availableStrategyIds?.[data.id]?.map(
                            (data: any) => (
                              <div>{data}</div>
                            ),
                          )}
                        >
                          <div
                            style={
                              data.valid
                                ? {
                                    display: "flex",
                                    justifyContent: "center",
                                  }
                                : {
                                    display: "flex",
                                    justifyContent: "center",
                                    color: theme.palette.custom.red.main,
                                  }
                            }
                          >
                            {data.name}
                            <div style={{ marginLeft: "4px" }}>
                              <FiberManualRecordIcon
                                className={classes.circleStyle}
                              />
                            </div>
                          </div>
                        </Tooltip>
                      ) : (
                        <div
                          style={
                            data.valid
                              ? {}
                              : {
                                  color: theme.palette.custom.red.main,
                                }
                          }
                        >
                          {data.name}
                        </div>
                      )
                    }
                    id={`strategy-type-tab-${index}`}
                    aria-controls={`strategy-type-tab-${index}`}
                  />
                ))}
              </Tabs>
            </CardCommon>

            {!isLoading ? (
              strategyList.map((data: any, index: number) => (
                <div className={classes.root}>
                  <TabPanel value={value} index={index}>
                    <div className={classes.discountTypeSection}>
                      <Typography
                        variant="h4"
                        component="div"
                        align="left"
                        style={{ margin: 4 }}
                      />
                    </div>
                    <PrinterNode
                      displayNode={displayNode}
                      printerList={printerList}
                      handleChangeCheckBox={handleChangeCheckBox}
                      strategyId={data.id}
                      version={productConfigVersion}
                      handleSubmit={handleSubmit}
                      error={errorCard}
                      isLoadingButton={isLoadingButton}
                      type={type}
                      handleDeleteMapProduct={handleDeleteMapProduct}
                    />
                  </TabPanel>
                </div>
              ))
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: "10%",
                }}
              >
                <CircularProgress size={50} color="secondary" />
              </div>
            )}
          </DialogContent>
        </DialogCommonDefault>
      }
    </>
  );
};

export default MappingModal;
