/* eslint-disable no-use-before-define */
/* eslint-disable import/order */
import { Grid, Typography } from "@material-ui/core";
import React from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import _ from "lodash";

import CardCommon from "../../../card/CardCommon";
import { activeInactiveSelectorList } from "../../../../utils/consts/list";
import MultipleSelector from "../../selector/MultipleSelector";

export interface Props {
  handleAllSelectedList: any;
  activeInactiveSelectedList: any;
}

const FilterCard: React.FunctionComponent<Props> = ({
  handleAllSelectedList,
  activeInactiveSelectedList,
}) => {
  return (
    <div style={{ marginBottom: "32px" }}>
      <CardCommon backgroundColor={"entity_background"}>
        <Grid
          container
          spacing={2}
          style={{
            marginBottom: "10px",
          }}
        >
          <Grid
            item
            xs={12}
            style={{
              marginTop: "12px",
              width: "100%",
            }}
          >
            <Typography style={{ fontWeight: "bold" }} variant="body2">
              Status
            </Typography>
            <MultipleSelector
              selectorTextFieldName={"status"}
              selectorList={activeInactiveSelectorList}
              handleAllSelectedList={handleAllSelectedList}
              selectedList={activeInactiveSelectedList}
            />
          </Grid>
        </Grid>
      </CardCommon>
    </div>
  );
};

export default FilterCard;
