import React from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import _ from "lodash";

import CardCommon from "../../../../../components/card/CardCommon";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";
import { buttonColors } from "../../../../../utils/enum";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    error: {
      backgroundColor: theme.palette.error.dark,
      color: "white",
      borderRadius: "8px 8px 0 0",
      border: `1px solid ${theme.palette.background.entity_border}`,
      padding: 8,
    },
  }),
);

export interface MappingModalProps {
  displayNode: any;
  printerList: any;
  handleChangeCheckBox: any;
  strategyId: any;
  version: any;
  handleSubmit: any;
  error: any;
  isLoadingButton: any;
  type: any;
  handleDeleteMapProduct: any;
}

const PrinterNode: React.FunctionComponent<MappingModalProps> = ({
  displayNode,
  printerList,
  handleChangeCheckBox,
  strategyId,
  version,
  handleSubmit,
  error,
  isLoadingButton,
  type,
  handleDeleteMapProduct,
}) => {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={2}>
        {!_.isEmpty(printerList) && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">Printers</Typography>
            </Grid>
            {printerList.map((hardware: any) => (
              <Grid item xs={6} sm={4} md={3} lg={2}>
                <CardCommon
                  backgroundColor={"entity_background"}
                  style={{ height: error[hardware.code] ? "194px" : "152px" }}
                >
                  {error[hardware.code] && (
                    <Typography
                      component="div"
                      className={classes.error}
                      variant="body1"
                    >
                      {error[hardware.code]?.message}
                    </Typography>
                  )}
                  {hardware?.filterMapping &&
                    Object.values(hardware?.filterMapping).length > 0 &&
                    strategyId ===
                      hardware?.filterMapping[strategyId]?.strategy && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked
                            size="small"
                            checked={
                              hardware?.filterMapping[strategyId].inheritance
                            }
                            onChange={(event) =>
                              handleChangeCheckBox(
                                event,
                                hardware,
                                "printer",
                                type,
                                strategyId,
                              )
                            }
                          />
                        }
                        label={
                          <Typography style={{ fontSize: "14px" }}>
                            Inherit to Slave
                          </Typography>
                        }
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "2px",
                        }}
                      />
                    )}
                  <Typography
                    align="center"
                    variant="h6"
                    style={{
                      fontWeight: "bold",
                      lineHeight: "0px",
                      marginTop:
                        hardware?.filterMapping &&
                        Object.values(hardware?.filterMapping).length > 0 &&
                        strategyId ===
                          hardware?.filterMapping[strategyId]?.strategy
                          ? "8px"
                          : "48px",
                    }}
                  >
                    {hardware.code}
                  </Typography>
                  <Typography align="center" style={{ marginTop: "14px" }}>
                    {hardware.name}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "12px",
                    }}
                  >
                    <ButtonCommon
                      variant="contained"
                      style={{ fontSize: 11, width: "140px" }}
                      isLoadingPage={isLoadingButton[hardware.id]}
                      disabled={version === 1 || isLoadingButton[hardware.id]}
                      color={
                        hardware?.filterMapping &&
                        Object.values(hardware?.filterMapping).length > 0 &&
                        strategyId ===
                          hardware?.filterMapping[strategyId]?.strategy
                          ? buttonColors.DELETE_BUTTON_COLOR
                          : buttonColors.CREATE_BUTTON_COLOR
                      }
                      onClick={() =>
                        strategyId ===
                        hardware?.filterMapping[strategyId]?.strategy
                          ? handleDeleteMapProduct(
                              hardware?.filterMapping[strategyId],
                              hardware,
                              "printer",
                            )
                          : handleSubmit(type, hardware, strategyId, "printer")
                      }
                    >
                      {hardware?.filterMapping &&
                      Object.values(hardware?.filterMapping).length > 0 &&
                      strategyId ===
                        hardware?.filterMapping[strategyId]?.strategy
                        ? "DeActivate"
                        : "Activate"}
                    </ButtonCommon>
                  </div>
                </CardCommon>
              </Grid>
            ))}
          </>
        )}
      </Grid>
      <Grid container spacing={2} style={{ marginTop: "20px" }}>
        {!_.isEmpty(displayNode) && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">Displays</Typography>
            </Grid>
            {displayNode.map((hardware: any) => (
              <Grid item xs={6} sm={4} md={3} lg={2}>
                <CardCommon
                  backgroundColor={"entity_background"}
                  style={{ height: error[hardware.code] ? "194px" : "152px" }}
                >
                  {error[hardware.code] && (
                    <Typography
                      component="div"
                      className={classes.error}
                      variant="body1"
                    >
                      {error[hardware.code]?.message}
                    </Typography>
                  )}
                  {hardware?.filterMapping &&
                    Object.values(hardware?.filterMapping).length > 0 &&
                    strategyId ===
                      hardware?.filterMapping[strategyId]?.strategy && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked
                            size="small"
                            checked={
                              hardware?.filterMapping[strategyId].inheritance
                            }
                            onChange={(event) =>
                              handleChangeCheckBox(
                                event,
                                hardware,
                                "display",
                                type,
                                strategyId,
                              )
                            }
                          />
                        }
                        label={
                          <Typography style={{ fontSize: "14px" }}>
                            Inherit to Slave
                          </Typography>
                        }
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "2px",
                        }}
                      />
                    )}
                  <Typography
                    align="center"
                    variant="h6"
                    style={{
                      fontWeight: "bold",
                      lineHeight: "0px",
                      marginTop:
                        hardware?.filterMapping &&
                        Object.values(hardware?.filterMapping).length > 0 &&
                        strategyId ===
                          hardware?.filterMapping[strategyId]?.strategy
                          ? "8px"
                          : "48px",
                    }}
                  >
                    {hardware.code}
                  </Typography>
                  <Typography align="center" style={{ marginTop: "14px" }}>
                    {hardware.name}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "12px",
                    }}
                  >
                    <ButtonCommon
                      variant="contained"
                      disabled={version === 1 || isLoadingButton[hardware.id]}
                      style={{ fontSize: 11, width: "140px" }}
                      isLoadingPage={isLoadingButton[hardware.id]}
                      color={
                        hardware?.filterMapping &&
                        Object.values(hardware?.filterMapping).length > 0 &&
                        strategyId ===
                          hardware?.filterMapping[strategyId]?.strategy
                          ? buttonColors.DELETE_BUTTON_COLOR
                          : buttonColors.CREATE_BUTTON_COLOR
                      }
                      onClick={() =>
                        strategyId ===
                        hardware?.filterMapping[strategyId]?.strategy
                          ? handleDeleteMapProduct(
                              hardware?.filterMapping[strategyId],
                              hardware,
                              "display",
                            )
                          : handleSubmit(type, hardware, strategyId, "display")
                      }
                    >
                      {hardware?.filterMapping &&
                      Object.values(hardware?.filterMapping).length > 0 &&
                      strategyId ===
                        hardware?.filterMapping[strategyId]?.strategy
                        ? "DeActivate"
                        : "Activate"}
                    </ButtonCommon>
                  </div>
                </CardCommon>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </>
  );
};

export default PrinterNode;
