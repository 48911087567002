import React, { useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import _ from "lodash";

import { CustomTheme } from "../../../../types/customTheme";
import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";
import SwitchCommon from "../../../../components/switches/SwitchCommon";
import { DeliveryTpe } from "../../../../utils/enum";
import { DELIVERY_TYPE_DINEIN } from "../../../../utils/consts";
import TextfieldDefaultNew from "../../../../components/textField/TextfieldDefaultNew";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import AvailabilitySelection from "../../../../components/time/AvailabilitySelection";

export interface MappingModalProps {
  isOpen: any;
  setIsOpen: any;
  setStrategyName: any;
  strategyName: any;
  deliveryTypesList: any;
  setDeliveryTypesList: any;
  handleSubmit: any;
  availability: any;
  handleSubmitAvailability: any;
  setAvailabilityErrorMessage: any;
  isDeliveryTypeEmpty: any;
  setIsNameEmpty: any;
  setIsDeliveryTypeEmpty: any;
  isNameEmpty: any;
  isAvailabilityEmpty: any;
  isLoadingButton: any;
  isEdit: any;
  isDuplicate: any;
  handleReset: any;
  deliveryTypesListInitial: any;
  availabilityInitial: any;
  strategyNameInitial: any;
}

const StrategyAddEditModal: React.FunctionComponent<MappingModalProps> = ({
  isOpen,
  setIsOpen,
  setStrategyName,
  strategyName,
  deliveryTypesList,
  setDeliveryTypesList,
  handleSubmit,
  availability,
  handleSubmitAvailability,
  setAvailabilityErrorMessage,
  isDeliveryTypeEmpty,
  setIsNameEmpty,
  setIsDeliveryTypeEmpty,
  isNameEmpty,
  isAvailabilityEmpty,
  isLoadingButton,
  isEdit,
  isDuplicate,
  handleReset,
  deliveryTypesListInitial,
  availabilityInitial,
  strategyNameInitial,
}) => {
  /* Update the deliveryTypeList after selecting the delivery options using switches (dine-in, takeout, and delivery). */
  const handleSelectDeliveryTypes = (e: any) => {
    const { value, name, checked } = e.target;
    // When a delivery type option is selected, that delivery type option is entered into a list.
    if (checked) {
      const selectedList: any = new Set([...deliveryTypesList, value]);
      setDeliveryTypesList([...selectedList]);
    } else {
      // When a delivery type option is not selected, that delivery type option is removed into a list.
      const selectedList: any = deliveryTypesList.filter(
        (amountType: String) => name !== amountType,
      );
      setDeliveryTypesList(selectedList);
    }
    setIsDeliveryTypeEmpty(false);
  };

  const isEqualArrays = (arr1: any, arr2: any) => {
    // If lengths are not equal, arrays are not equal
    if (arr1.length !== arr2.length) {
      return false;
    }

    // Sort both arrays
    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    // Compare elements of both arrays
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }

    // If all elements match, arrays are equal
    return true;
  };

  const isEqual = (arr1: any, arr2: any) => {
    // Sort arrays based on the string representation of their objects
    const results = arr1.filter((value: any) =>
      arr2.some((value1: any) => {
        return (
          value.closeTime !== value1.closeTime ||
          value.day !== value1.day ||
          value.durationMins !== value1.durationMins ||
          value.minuteOfWeek !== value1.minuteOfWeek ||
          value.openTime !== value1.openTime
        );
      }),
    );
    const lengthSame = arr1.length > arr2.length || arr2.length > arr1.length;
    return results.length > 0 || lengthSame ? false : true;
  };

  const handleDisable = () => {
    if (
      strategyName === strategyNameInitial &&
      isEqualArrays(deliveryTypesList, deliveryTypesListInitial) &&
      isEqual(availability.availability, availabilityInitial.availability)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <DialogCommonDefault
        open={isOpen}
        setOpen={setIsOpen}
        isNeedFixedHeight={false}
      >
        <DialogTitle>{`${
          isEdit ? "Edit" : isDuplicate ? "Duplicate" : "Add New"
        } Strategy`}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography style={{ paddingLeft: "4px" }}>Name</Typography>
              <TextfieldDefaultNew
                id="sign-password-input"
                name="sign-password-input"
                onChange={(e: any) => {
                  setIsNameEmpty("");
                  setStrategyName(e.target.value);
                }}
                value={strategyName}
                type="text"
              />
              {isNameEmpty && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "3px",
                  }}
                >
                  <Typography
                    align="left"
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    Please enter name.
                  </Typography>
                </div>
              )}
            </Grid>
            <Grid item xs={6} style={{ marginTop: "12px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <FormControlLabel
                  style={{ marginLeft: "0px", marginRight: "0px" }}
                  value="dinein"
                  control={<SwitchCommon />}
                  label={
                    <Typography variant="caption" color="textSecondary">
                      Dine In
                    </Typography>
                  }
                  name="dinein"
                  checked={
                    !_.isEmpty(deliveryTypesList)
                      ? deliveryTypesList.includes(DELIVERY_TYPE_DINEIN)
                      : false
                  }
                  labelPlacement="bottom"
                  onChange={handleSelectDeliveryTypes}
                />
                <FormControlLabel
                  style={{ marginLeft: "0px", marginRight: "0px" }}
                  value="delivery"
                  control={<SwitchCommon />}
                  label={
                    <Typography variant="caption" color="textSecondary">
                      Delivery
                    </Typography>
                  }
                  name="delivery"
                  checked={
                    !_.isEmpty(deliveryTypesList)
                      ? deliveryTypesList.includes(DeliveryTpe.DELIVERY)
                      : false
                  }
                  labelPlacement="bottom"
                  onChange={handleSelectDeliveryTypes}
                />
                <FormControlLabel
                  style={{ marginLeft: "0px", marginRight: "0px" }}
                  value="takeout"
                  name="takeout"
                  control={<SwitchCommon />}
                  label={
                    <Typography variant="caption" color="textSecondary">
                      Takeout
                    </Typography>
                  }
                  checked={
                    !_.isEmpty(deliveryTypesList)
                      ? deliveryTypesList.includes(DeliveryTpe.TAKEOUT)
                      : false
                  }
                  labelPlacement="bottom"
                  onChange={handleSelectDeliveryTypes}
                />
              </div>
              {_.isEqual(isDeliveryTypeEmpty, true) && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "3px",
                  }}
                >
                  <Typography
                    align="left"
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    Please select a least one delivery option.
                  </Typography>
                </div>
              )}
            </Grid>

            <Grid item xs={12} style={{ marginTop: "8px" }}>
              <AvailabilitySelection
                nodeData={availability}
                disabled={false}
                handleSubmit={handleSubmitAvailability}
                setAvailabilityErrorMessage={setAvailabilityErrorMessage}
                backgroundColor={"entity_background"}
              />
              {isAvailabilityEmpty && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "3px",
                  }}
                >
                  <Typography
                    align="left"
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    Please select availability.
                  </Typography>
                </div>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginBottom: "8px" }}>
          <ButtonCommon
            variant="contained"
            style={{ fontSize: 11, width: "100px", marginRight: 8 }}
            color="yellow"
            onClick={handleReset}
          >
            Cancel
          </ButtonCommon>
          <ButtonCommon
            variant="contained"
            style={{
              fontSize: 11,
              marginLeft: 4,
              width: "100px",
            }}
            disabled={handleDisable()}
            isLoadingPage={isLoadingButton}
            color={isEdit ? "orange" : isDuplicate ? "blue" : "green"}
            onClick={handleSubmit}
          >
            {isEdit ? "Save" : isDuplicate ? "Duplicate" : "Create"}
          </ButtonCommon>
        </DialogActions>
      </DialogCommonDefault>
    </>
  );
};

export default StrategyAddEditModal;
