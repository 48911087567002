import moment from "moment";
import saveAs from "file-saver";
import _ from "lodash";
import { ExportExcelColorInventory } from "../../../../../root/theme/exportColor";

const excelRawChangeColor = (data: any, index: number) => {
  
    if (index % 2 === 0) {
      return {
        argb: [ExportExcelColorInventory.OddRowColor],
      };
    } else {
      return {
        argb: [ExportExcelColorInventory.EvenRowColor],
      };
    }
};

/* Create a excel file */
export const handleDownloadCsv = (
  excelHeaders: any,
  nodes: any,
  locationData: any,
  selectedStockOrderDetails: any,
) => {
  const ExcelJS = require("exceljs");
  const workbook = new ExcelJS.Workbook();
  let excelHeader: any = {};
  let excelData: any = {};

  const worksheet = workbook.addWorksheet("Payment");

  /* Worksheet add headers */
  worksheet.columns = excelHeaders;

  /* Find the last character in the excel */
  const len = excelHeaders.length + 64;
  let char = String.fromCharCode(len);

  /* Add a title */
  worksheet.getCell("A1").value = `Purchase Order from ${locationData.businessDisplayName}`;

  worksheet.getCell("A3").value = "Name";
  worksheet.getCell("B3").value = locationData.businessDisplayName;

  worksheet.getCell("C3").value = "Name";
  worksheet.getCell("D3").value = selectedStockOrderDetails?.supplierId?.name;


  worksheet.getCell("A4").value = "Address";
  worksheet.getCell("B4").value = locationData.addressFormatted;

  worksheet.getCell("C4").value = "Address";
  worksheet.getCell("D4").value = selectedStockOrderDetails?.supplierId?.address;
  

  worksheet.getCell("A5").value = "Contact No";
  worksheet.getCell("B5").value = locationData.contactNo;

  worksheet.getCell("C5").value = "Contact No";
  worksheet.getCell("D5").value = selectedStockOrderDetails?.supplierId?.contactNo;


  worksheet.getCell("A6").value = "Email";
  worksheet.getCell("B6").value = locationData.contactNo;

  worksheet.getCell("C6").value = "Email";
  worksheet.getCell("D6").value = selectedStockOrderDetails?.supplierId?.contactNo;
  
  worksheet.mergeCells("A1:D1");

  /* Add a empty row. */
  worksheet.addRow({});

  /* Add a headers and change the header and empty row height. */
  excelHeaders.map((data: any) => {
    excelHeader[data.key] = data.header;
  });
  worksheet.addRow(excelHeader);
  worksheet.getRow(1).height = 28;
  worksheet.getRow(2).height = 28;
  worksheet.getRow(3).height = 28;
  worksheet.getRow(4).height = 28;
  worksheet.getRow(5).height = 28;
  worksheet.getRow(6).height = 28;
  worksheet.getRow(7).height = 28;

  worksheet.getCell("A3").font = { bold: true };
  worksheet.getCell("A4").font = { bold: true };
  worksheet.getCell("A5").font = { bold: true };
  worksheet.getCell("A6").font = { bold: true };

  worksheet.getCell("C3").font = { bold: true };
  worksheet.getCell("C4").font = { bold: true };
  worksheet.getCell("C5").font = { bold: true };
  worksheet.getCell("C6").font = { bold: true };

  /* Add a body in the excel. */
  nodes.map((data: any, index: number) => {
    Object.keys(excelHeader).map((header: any) => {
      excelData[header] = data[header];
    });
    worksheet.addRow(excelData);

    // Change the alignment in the cell.
    const row = worksheet.getRow(index + 9);
    row.eachCell({ includeEmpty: true }, function (cell: any, colNumber: any) {
      let char = String.fromCharCode(64 + colNumber);

      worksheet.getCell(`A${index + 8})`).alignment = {
        vertical: "bottom",
        horizontal: "left",
      };

      worksheet.getCell(`B${index + 8})`).alignment = {
        vertical: "bottom",
        horizontal: "center",
      };
      worksheet.getCell(`C${index + 8})`).alignment = {
        vertical: "bottom",
        horizontal: "center",
      };
      worksheet.getCell(`D${index + 8})`).alignment = {
        vertical: "bottom",
        horizontal: "right",
      };

      if(nodes.length === index+1) {
        worksheet.getCell(`A${index + 9})`).alignment = {
          vertical: "bottom",
          horizontal: "left",
        };
  
        worksheet.getCell(`B${index + 9})`).alignment = {
          vertical: "bottom",
          horizontal: "center",
        };
        worksheet.getCell(`C${index + 9})`).alignment = {
          vertical: "bottom",
          horizontal: "center",
        };
        worksheet.getCell(`D${index + 9})`).alignment = {
          vertical: "bottom",
          horizontal: "right",
        };
      }
      // Change the font style in the specific cell.
      if (colNumber === 1) {
        worksheet.getCell(`${char}${index + 8})`).font = { bold: true };
        if(index+1===nodes.length){
          worksheet.getCell(`${char}${index + 9})`).font = { bold: true };

        }
      }
    });

    worksheet.getRow(index + 8).height = 28;
  });

  /* Change the footer height */
  worksheet.getRow(nodes.length + 8).height = 28;

  /* Change the font color in the all cells */
  worksheet.addConditionalFormatting({
    ref: `A9:${char}${nodes.length + 8}`,
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
          },
          font: { color: { argb: "000000" } },
        },
      },
    ],
  });

  /* Change the title style */
  worksheet.addConditionalFormatting({
    ref: "A1",
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
          },
          font: { bold: true, size: 10 },
        },
      },
    ],
  });
  
  /* Change the sub title stile */
  worksheet.addConditionalFormatting({
    ref: `B1:${char}1`,
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
          },
          font: {
            bold: true,
            size: 10,
          },
        },
      },
    ],
  });

  /* Change all row colors. */
  nodes.map((data: any, index: any) => {
    if (index + 1 !== nodes.length +6) {
      worksheet.addConditionalFormatting({
        ref: `A${index + 9}:${char}${index + 9}`,
        rules: [
          {
            type: "expression",
            formulae: ["MOD(2,2)=0"],
            style: {
              fill: {
                type: "pattern",
                pattern: "solid",
                bgColor: excelRawChangeColor(data, index),
              },
              font: { color: { argb: "000000" } },
            },
          },
        ],
      });
    }
  });

  /* Bold the letters in the first column. */
  const column = worksheet.getColumn(1);
  column.style = { font: { bold: true } };

  /* Change the header row color */
  worksheet.addConditionalFormatting({
    ref: `A8:${char}8`,
    rules: [
      {
        type: "expression",
        formulae: ["MOD(2,2)=0"],
        style: {
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: { argb: ExportExcelColorInventory.HeaderRowColor },
          },
          font: {
            bold: true,
            color: { argb: "000000" },
          },
        },
      },
    ],
  });

  const today = moment().format("MMM_Do_YY").toString();
  workbook.xlsx.writeBuffer().then(function (buffer: any) {
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      `delivery_Report${today}_${Math.floor(
        100000 + Math.random() * 900000,
      )}.xlsx`,
    );
  });
};
