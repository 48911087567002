import { jsPDF } from "jspdf";
import "jspdf-autotable";
import moment from "moment";

import { ExportPdfColorInventory } from "../../../../../root/theme/exportColor";
import eatprestoLogo from "../../../../../assets/images/logos/logo.png";
import { convertDateFormat } from "../../../../../utils/ConvertDateTimeFormat";

/* Create a pdf file */
export const handleDownloadPdf = (
  scapeChange: string,
  columns: any,
  nodes: any,
  locationData: any,
  selectedStockOrderDetails: any,
) => {
  // Create a new jsPDF instance, specifying landscape or portrait mode based on scapeChange variable
  const doc: any = new jsPDF(scapeChange === "p" ? "p" : "l");

  // Placeholder for total pages
  const totalPagesExp = "{total_pages_count_string}";

  // Get page width
  const pageWidth = doc.internal.pageSize.getWidth();

  // Format current date
  const today = moment().format("MMM_Do_YY").toString();

  // Create an Image element for the logo
  const img = new Image();
  img.src = eatprestoLogo;

  // Calculate equal column width for 4 columns (in millimeters)
  const totalWidth = pageWidth - 14.11 - 14.11;
  const equalColumnWidth = totalWidth / 4;

  // Generate the table using the autotable plugin
  doc.autoTable({
    columns: columns.map((col: any) => ({ ...col, dataKey: col.field })),
    body: nodes, // Table data rows
    startY: 52, // Y position to start the table
    styles: {
      align: "center",
      fontSize: 8,
      valign: "middle",
      halign: "center",
      borderRadius: "10px",
    },
    headStyles: {
      fillColor: [ExportPdfColorInventory.HeaderRowColor], // Header row background color
      textColor: ["#000000"], // Header row text color
      fontSize: 10,
      valign: "middle",
      halign: "center",
    },
    columnStyles: {
      0: { fontStyle: "bold", halign: "left", cellWidth: equalColumnWidth },
      1: { cellWidth: equalColumnWidth },
      2: { cellWidth: equalColumnWidth },
      3: { halign: "right", cellWidth: equalColumnWidth },
    },
    alternateRowStyles: {
      fillColor: [ExportPdfColorInventory.OddRowColor], // Odd row background color
      textColor: ["#000000"],
    },
    tableLineWidth: 0.1, // Table border line width

    // Callback function when drawing a page
    didDrawPage: function (data: any) {
      let pageNumber = "Page " + doc.internal.getNumberOfPages();
      // Change topic font size
      doc.setFontSize(12);

      const addressLines = locationData.addressFormatted.split(",");

      doc.setTextColor(0, 0, 0); // Set text color to black

      // Check if this is the first page of the document
      if (doc.internal.getNumberOfPages() === 1) {
        // Set font size to 14 and style to bold
        doc.setFontSize(14).setFont(undefined, "bold");

        // Add "Deliver : " text at the specified position
        doc.text(
          `Purchase Order from ${locationData.businessDisplayName}`,
          data.settings.margin.left,
          12,
        );

        // Set font size back to 12 and style to normal
        doc.setFontSize(12).setFont(undefined, "normal");

        // Loop through address lines and add them to the document
        // for (let i = 0; i < addressLines.length; i++) {
        //   doc.text(
        //     addressLines[i].trim() + ",",
        //     data.settings.margin.left,
        //     18 + i * 6,
        //   );
        // }

        // Set font size to 14 and style to bold
        doc.setFontSize(12).setFont(undefined, "bold");

        doc.text("Name", data.settings.margin.left, 22);

        doc.setFontSize(12).setFont(undefined, "normal");

        doc.text(
          locationData.businessDisplayName,
          data.settings.margin.left + 25,
          22,
        );

        doc.setFontSize(12).setFont(undefined, "bold");

        doc.text("Address", data.settings.margin.left, 29);

        doc.setFontSize(12).setFont(undefined, "normal");

        doc.text(
          locationData.addressFormatted,
          data.settings.margin.left + 25,
          29,
        );

        doc.setFontSize(12).setFont(undefined, "bold");

        doc.text("Contact No", data.settings.margin.left, 36);

        doc.setFontSize(12).setFont(undefined, "normal");

        doc.text(locationData.contactNo, data.settings.margin.left + 25, 36);

        doc.setFontSize(12).setFont(undefined, "bold");

        doc.text("Email", data.settings.margin.left, 43);

        doc.setFontSize(12).setFont(undefined, "normal");

        doc.text(locationData.email, data.settings.margin.left + 25, 43);

        //   // Calculate the text width of "Supplier :"
        // const textWidth = doc.getTextWidth("Supplier :");

        //   // Add "Supplier : " text at the specified position
        // doc.text(
        //   "Supplier : ",
        //   pageWidth - data.settings.margin.right - textWidth,
        //   12,
        // );

        // Initialize count for vertical positioning
        let count = 22;

        // Set font size back to 12 and style to normal
        doc.setFontSize(12).setFont(undefined, "normal");

        // Check if the supplier's name is available
        if (selectedStockOrderDetails?.supplierId?.name) {
          // Calculate the text width of the supplier's name
          doc.setFontSize(12).setFont(undefined, "normal");

          const textWidth = doc.getTextWidth(
            selectedStockOrderDetails?.supplierId?.name,
          );

          doc.setFontSize(12).setFont(undefined, "bold");

          const textWidths = doc.getTextWidth("Name");

          doc.text("Name", pageWidth - data.settings.margin.right - 75, count);

          doc.setFontSize(12).setFont(undefined, "normal");

          // Add the supplier's name at the specified position
          doc.text(
            selectedStockOrderDetails?.supplierId?.name,
            pageWidth - data.settings.margin.right - textWidth,
            count,
          );

          // Increase the vertical position
          count = count + 7;
        }

        // Add supplier address if available
        if (selectedStockOrderDetails?.supplierId?.address) {
          // Calculate the text width of the supplier's name
          doc.setFontSize(12).setFont(undefined, "normal");

          const textWidth = doc.getTextWidth(
            selectedStockOrderDetails?.supplierId?.address,
          );

          doc.setFontSize(12).setFont(undefined, "bold");

          doc.text(
            "Address",
            pageWidth - data.settings.margin.right - 75,
            count,
          );

          doc.setFontSize(12).setFont(undefined, "normal");

          // Add the supplier's address at the specified position
          doc.text(
            selectedStockOrderDetails?.supplierId?.address,
            pageWidth - data.settings.margin.right - textWidth,
            count,
          );

          // Increase the vertical position
          count = count + 7;
        }

        // Add supplier contact no if available
        if (selectedStockOrderDetails?.supplierId?.contactNo) {
          // Calculate the text width of the supplier's name
          doc.setFontSize(12).setFont(undefined, "normal");

          const textWidth = doc.getTextWidth(
            selectedStockOrderDetails?.supplierId?.contactNo,
          );

          doc.setFontSize(12).setFont(undefined, "bold");

          doc.text(
            "Contact No",
            pageWidth - data.settings.margin.right - 75,
            count,
          );

          doc.setFontSize(12).setFont(undefined, "normal");

          // Add the supplier's contactNo at the specified position
          doc.text(
            selectedStockOrderDetails?.supplierId?.contactNo,
            pageWidth - data.settings.margin.right - textWidth,
            count,
          );

          // Increase the vertical position
          count = count + 7;
        }

        // Add supplier email if available
        if (selectedStockOrderDetails?.supplierId?.email) {
          // Calculate the text width of the supplier's name
          doc.setFontSize(12).setFont(undefined, "normal");

          const textWidth = doc.getTextWidth(
            selectedStockOrderDetails?.supplierId?.email,
          );

          doc.setFontSize(12).setFont(undefined, "bold");

          doc.text(
            "Email",
            pageWidth - data.settings.margin.right - 75,
            count,
          );

          doc.setFontSize(12).setFont(undefined, "normal");

          // Add the supplier's email at the specified position
          doc.text(
            selectedStockOrderDetails?.supplierId?.email,
            pageWidth - data.settings.margin.right - textWidth,
            count,
          );

          // Increase the vertical position
          count = count + 7;
        }

        doc.setFontSize(10);
      }

      // Check if the putTotalPages function is available (jspdf v1.0+)
      if (typeof doc.putTotalPages === "function") {
        pageNumber = pageNumber + " of " + totalPagesExp;
      }
      doc.setFontSize(10);
      // Get the current page size from the doc object
      let pageSize = doc.internal.pageSize;

      // Calculate the page height; use the dynamic getter if available, or fall back to the getHeight method
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();

      // Add page number to the bottom-left corner of the page
      doc.text(pageNumber, data.settings.margin.left, pageHeight - 10);

      // Calculate the text width of the formatted date string
      const textWidth = doc.getTextWidth(
        convertDateFormat(new Date().toString()),
      );

      // Add the formatted date to the bottom-right corner of the page, considering the text width
      doc.text(
        // The formatted date string
        convertDateFormat(new Date().toString()),
        // Calculate the X position
        pageWidth - data.settings.margin.right - textWidth,
        // Y position, same as above for consistency
        pageHeight - 10,
      );
    },
    // Callback function for cell customization
    didParseCell: function (data: any) {
      // Check if we are in the "head" section of the table
      if (data.section === "head") {
        // Check the column's dataKey to customize cell styles
        if (data.column.dataKey === "sku") {
          // Align text to the left for the "sku" column in the header
          data.cell.styles.halign = "left";
        } else if (data.column.dataKey === "barcode") {
          // Align text to the right for the "barcode" column in the header
          data.cell.styles.halign = "right";
        }
      }
      // Check if we are in the body section and specifically the 4th column (0-based index)
      if (data.column.index === 3) {
        // Align text to the right for the 4th column in the body
        data.cell.styles.halign = "right";
      }
      // Check if it's not the first row (header row)
      if (data.row.index !== 0) {
        // Apply alternating row background colors and text colors
        if (data.row.index % 2 === 0) {
          // Even rows
          // Background color for even rows
          data.cell.styles.fillColor = [ExportPdfColorInventory.OddRowColor];
          // Text color for even rows
          data.cell.styles.textColor = ["#000000"];
        } else {
          // Odd rows
          // Background color for odd rows
          data.cell.styles.fillColor = [ExportPdfColorInventory.EvenRowColor];
          // Text color for odd rows
          data.cell.styles.textColor = ["#000000"];
        }
      }
    },
    margin: {
      top: 30,
      bottom: 30,
    },
  });

  // Check if the putTotalPages function is available (supported in jsPDF v1.0+)
  if (typeof doc.putTotalPages === "function") {
    // If available, use it to set the total page count in the format specified by totalPagesExp
    doc.putTotalPages(totalPagesExp);
  }

  // Generate a random unique filename for the PDF report
  const randomFileName = `delivery_Report_${today}_${Math.floor(
    100000 + Math.random() * 900000,
  )}.pdf`;

  // Save the PDF with the generated filename
  doc.save(randomFileName);
};
