import React from "react";
import { Typography } from "@material-ui/core";

export interface WizardModalProps {}

const StepZero: React.FunctionComponent<WizardModalProps> = ({}) => {
  return (
    <Typography variant="h6" style={{ marginBottom: "20px" }}>
      Use this wizard to efficiently add multiple stock items with similar
      parameters
    </Typography>
  );
};

export default StepZero;
