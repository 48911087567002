import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import PrestoExpressAccountsApp from "../@apps/PrestoExpressAccountsApp";
import PrestoExpressEatprestoApp from "../@apps/PrestoExpressEatprestoApp";
import PrestoExpressLocationApp from "../@apps/PrestoExpressLocationApp";
import PrestoExpressSalesApp from "../@apps/PrestoExpressSalesApp";
import ProtectedRoute from "../components/routes/ProtectedRoute";
import { checkQueryParams } from "../utils/locationQueryParams";
import "./App.css";
import PrestoExpressMenuApp from "../@apps/PrestoExpressMenusApp";
import PrestoExpressTaskApp from "../@apps/PrestoExpressTaskApp";
import PrestoExpressInventoryApp from "../@apps/PrestoExpressInventoryApp";
import PrestoExpressCommonApp from "../@apps/PrestoExpressCommonApp";
import PrestoExpressCustomerApp from "../@apps/PrestoExpressCustomerApp";

/* Route manage using query params. */
export const GlobalContext = React.createContext<any>(null);

/* To show the UI in dark mode on initial load. */
function App() {
  const [globalState, setGlobalState] = useState<any>({
    isDarkMode: false,
  });
  return (
    <div className="App">
      <Router>
        <GlobalContext.Provider value={{ globalState, setGlobalState }}>
          <Switch>
            {window.location.origin.includes(
              process.env.REACT_APP_PRESTO_COMMON_DOMAIN || "",
            ) && (
              <Route path="/:locationId" component={PrestoExpressCommonApp} />
            )}

            {!window.location.origin.includes(
              process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN || "",
            ) &&
              !window.location.origin.includes(
                process.env.REACT_APP_PRESTO_COMMON_DOMAIN || "",
              ) &&
              !checkQueryParams() && (
                <ProtectedRoute
                  exact
                  path="/"
                  component={PrestoExpressLocationApp}
                />
              )}

            {!window.location.origin.includes(
              process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN || "",
            ) &&
              !window.location.origin.includes(
                process.env.REACT_APP_PRESTO_COMMON_DOMAIN || "",
              ) &&
              !checkQueryParams() && (
                <ProtectedRoute
                  exact
                  path="/"
                  component={PrestoExpressEatprestoApp}
                />
              )}

            {!window.location.origin.includes(
              process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN || "",
            ) &&
              !window.location.origin.includes(
                process.env.REACT_APP_PRESTO_COMMON_DOMAIN || "",
              ) &&
              !checkQueryParams() && (
                <ProtectedRoute
                  exact
                  path="/"
                  component={PrestoExpressMenuApp}
                />
              )}

            {!window.location.origin.includes(
              process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN || "",
            ) &&
              !window.location.origin.includes(
                process.env.REACT_APP_PRESTO_COMMON_DOMAIN || "",
              ) &&
              !checkQueryParams() && (
                <ProtectedRoute
                  exact
                  path="/"
                  component={PrestoExpressTaskApp}
                />
              )}

            {!window.location.origin.includes(
              process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN || "",
            ) &&
              !window.location.origin.includes(
                process.env.REACT_APP_PRESTO_COMMON_DOMAIN || "",
              ) &&
              !checkQueryParams() && (
                <ProtectedRoute
                  exact
                  path="/"
                  component={PrestoExpressSalesApp}
                />
              )}

            {!window.location.origin.includes(
              process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN || "",
            ) &&
              !window.location.origin.includes(
                process.env.REACT_APP_PRESTO_COMMON_DOMAIN || "",
              ) &&
              !checkQueryParams() && (
                <ProtectedRoute
                  exact
                  path="/"
                  component={PrestoExpressInventoryApp}
                />
              )}

            {!window.location.origin.includes(
              process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN || "",
            ) &&
              !window.location.origin.includes(
                process.env.REACT_APP_PRESTO_COMMON_DOMAIN || "",
              ) &&
              !checkQueryParams() && (
                <ProtectedRoute
                  exact
                  path="/"
                  component={PrestoExpressCustomerApp}
                />
              )}

            <Route
              path="/redirect-authentication"
              component={() => {
                window.location.href = `${process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN}/accounts/sign-in`;
                return null;
              }}
            />
            {/* Redirect a login page. */}
            {window.location.origin.includes(
              process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN || "",
            ) && <Redirect from="/" to="/redirect-authentication" exact />}

            {/* Route a app selection page. */}
            {window.location.origin.includes(
              process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN || "",
            ) && (
              <Route path="/accounts" component={PrestoExpressAccountsApp} />
            )}

            {/* Redirect to the location selection page if a query parameter is available in the eatpresto URL. */}
            {checkQueryParams()
              ? window.location.origin.includes(
                  process.env.REACT_APP_PRESTO_EATPRESTO_DOMAIN || "",
                ) && <Route path="/" component={PrestoExpressEatprestoApp} />
              : //  Redirect to the eatpresto dashboard page if a query parameter is not available in the eatpresto URL.
                window.location.origin.includes(
                  process.env.REACT_APP_PRESTO_EATPRESTO_DOMAIN || "",
                ) && (
                  <Route
                    path="/:locationId/eatpresto"
                    component={PrestoExpressEatprestoApp}
                  />
                )}

            {/* Redirect to the location selection page if a query parameter is available in the location URL. */}
            {checkQueryParams()
              ? window.location.origin.includes(
                  process.env.REACT_APP_PRESTO_LOCATION_DOMAIN || "",
                ) && <Route path="/" component={PrestoExpressLocationApp} />
              : //  Redirect to the location dashboard page if a query parameter is not available in the location URL.
                window.location.origin.includes(
                  process.env.REACT_APP_PRESTO_LOCATION_DOMAIN || "",
                ) && (
                  <Route
                    path="/:locationId/presto-locations"
                    component={PrestoExpressLocationApp}
                  />
                )}

            {/* Redirect to the location selection page if a query parameter is available in the menu URL. */}
            {checkQueryParams()
              ? window.location.origin.includes(
                  process.env.REACT_APP_PRESTO_MENU_DOMAIN || "",
                ) && <Route path="/" component={PrestoExpressMenuApp} />
              : //  Redirect to the menu dashboard page if a query parameter is not available in the menu URL.
                window.location.origin.includes(
                  process.env.REACT_APP_PRESTO_MENU_DOMAIN || "",
                ) && (
                  <Route
                    path="/:locationId/presto-menus"
                    component={PrestoExpressMenuApp}
                  />
                )}

            {/* Redirect to the location selection page if a query parameter is available in the task URL. */}
            {checkQueryParams()
              ? window.location.origin.includes(
                  process.env.REACT_APP_PRESTO_TASK_DOMAIN || "",
                ) && <Route path="/" component={PrestoExpressTaskApp} />
              : //  Redirect to the task dashboard page if a query parameter is not available in the task URL.
                window.location.origin.includes(
                  process.env.REACT_APP_PRESTO_TASK_DOMAIN || "",
                ) && (
                  <Route
                    path="/:locationId/presto-task"
                    component={PrestoExpressTaskApp}
                  />
                )}

            {checkQueryParams()
              ? window.location.origin.includes(
                  process.env.REACT_APP_PRESTO_CUSTOMER_DOMAIN || "",
                ) && <Route path="/" component={PrestoExpressCustomerApp} />
              : //  Redirect to the sale dashboard page if a query parameter is not available in the sale URL.
                window.location.origin.includes(
                  process.env.REACT_APP_PRESTO_CUSTOMER_DOMAIN || "",
                ) && (
                  <Route
                    path="/:locationId/presto-customer"
                    component={PrestoExpressCustomerApp}
                  />
                )}

            {/* Redirect to the location selection page if a query parameter is available in the sale URL. */}
            {checkQueryParams()
              ? window.location.origin.includes(
                  process.env.REACT_APP_PRESTO_INVENTORY_DOMAIN || "",
                ) && <Route path="/" component={PrestoExpressInventoryApp} />
              : //  Redirect to the sale dashboard page if a query parameter is not available in the sale URL.
                window.location.origin.includes(
                  process.env.REACT_APP_PRESTO_INVENTORY_DOMAIN || "",
                ) && (
                  <Route
                    path="/:locationId"
                    component={PrestoExpressInventoryApp}
                  />
                )}

            {/* Redirect to the location selection page if a query parameter is available in the sale URL. */}
            {checkQueryParams()
              ? window.location.origin.includes(
                  process.env.REACT_APP_PRESTO_SALES_DOMAIN || "",
                ) && <Route path="/" component={PrestoExpressSalesApp} />
              : //  Redirect to the sale dashboard page if a query parameter is not available in the sale URL.
                window.location.origin.includes(
                  process.env.REACT_APP_PRESTO_SALES_DOMAIN || "",
                ) && (
                  <Route
                    path="/:locationId/"
                    component={PrestoExpressSalesApp}
                  />
                )}
          </Switch>
        </GlobalContext.Provider>
      </Router>
    </div>
  );
}

export default App;
