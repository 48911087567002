import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";

import { CustomTheme } from "../../../../types/customTheme";
import { getLocalStore } from "../../../../utils/store/localStore";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    titleStyle: {
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginTop: "7px",
      [theme.breakpoints.down("sm")]: {
        display: "-webkit-box",
        "-webkit-line-clamp": 1,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    imgBoxNotAvailableStyle: {
      marginRight: "7px",
      marginLeft: "10px",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
      width: "114px",
      [theme.breakpoints.down("sm")]: {
        height: "80px",
        width: "80px",
      },
      backgroundColor: theme.palette.background.imgBackground,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    img: {
      objectFit: "cover",
    },
    boxStyle: {
      display: "-webkit-box",
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textTransform: "capitalize",
    },
    priceStyle: {
      marginBottom: "4px",
    },
  }),
);

export interface BannersProps {
  item: any;
  handleClickLinkedId: any;
  isColorChange: any;
}

/* Get selected menu information, category menu details and location information using API call at initial load. */
const CategoryMenuListStrip: React.FunctionComponent<BannersProps> = ({
  item,
  handleClickLinkedId,
  isColorChange,
}) => {
  /* Change the pixel size of image */
  const handleImageUrl = (url: any) => {
    // If 'imgUrl' has 'cloudinary', that 'imgUrl' edit.
    if (url.includes("cloudinary")) {
      const urlSplit = url.split("upload");
      const inputString = urlSplit[0] + "upload/c_fill,g_center,h_240,w_320";
      const mergeUrl = inputString + urlSplit[1];
      return mergeUrl;
    } else {
      // If 'imgUrl' has not 'cloudinary', that 'imgUrl' does not edit.
      return url;
    }
  };
  const theme: CustomTheme = useTheme();
  const classes = useStyles();
  const [data, setData] = useState<any>([]);
  const [opacity, setOpacity] = useState(0);
  const [rotation, setRotation] = useState(0);
  const matchesLargeScreen = useMediaQuery("(max-width: 960px)");
  

  useEffect(() => {
    setData(data);
  }, []);

  const hexToRgbOpacity = (hex: any) => {
    // Remove the hash sign if it exists
    hex = hex.replace("#", "");

    // Parse the hex value into individual RGB components
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Return the RGB value in the format suitable for rgba() function
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };



  useEffect(() => {
    const startTime = performance.now();

    function rotateElement(timestamp: any) {
      const progress = timestamp - startTime;
      const duration = item.length * 100; // 3 seconds
      const targetRotation = matchesLargeScreen?2:3; // in degrees
      const rotationStep = (targetRotation / duration) * progress;
      setRotation(rotationStep);

      if (progress < duration) {
        requestAnimationFrame(rotateElement);
      }
    }

    requestAnimationFrame(rotateElement);

    return () => {
      // Cleanup if component unmounts before animation completes
      setRotation(0);
    };
  }, [isColorChange]); // Run effect only once on component mount

  useEffect(() => {
    const startTime = performance.now();

    function fadeInColor(timestamp: any) {
      const progress = timestamp - startTime;
      const duration = item.length * 400; // 3 seconds
      const opacityValue = Math.min(progress / duration, 1);
      setOpacity(opacityValue);

      if (progress < duration) {
        requestAnimationFrame(fadeInColor);
      }
    }

    requestAnimationFrame(fadeInColor);

    return () => {
      // Cleanup if component unmounts before animation completes
      setOpacity(0);
    };
  }, [isColorChange]);

  const isDarkMode = getLocalStore("isDarkMode");
  return (
    <>
      {matchesLargeScreen ? (
        <div
          style={{
            position: "relative",
            height: "136px",
            width: "100%",
            zIndex: 0,
            cursor: "pointer",
          }}
          onClick={() => handleClickLinkedId(item)}
        >
          {item.map((card: any, index: any) => (
            <Slide
              in={true}
              direction="left"
              mountOnEnter
              unmountOnExit
              timeout={300 * index}
            >
              <Box
                boxShadow={isDarkMode === "true" ? 4 : 0}
                style={{
                  position: "absolute",
                  left: 0,
                  marginTop: "2px",
                  width: "100%",
                  borderRadius: "10px",
                  border: `1px solid ${theme.palette.background.entity_border}`,
                  backgroundColor: !card.valid
                    ? theme.palette.custom.red.main
                    : theme.palette.background.entity_background,
                }}
              >
                {index + 1 === item.length ? (
                  <div
                    style={{
                      backgroundColor: !card.valid
                        ? theme.palette.custom.red.main
                        : theme.palette.background.entity_background,
                      height: "100px",
                      borderRadius: "10px",
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={9}
                        sm={10}
                        md={10}
                        lg={10}
                        xl={10}
                        style={{ display: "flex" }}
                      >
                        {card?.img ? (
                          <Box
                            style={{
                              marginLeft: "7px",
                              borderRadius: "10px",
                              height: `${80}px`,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                              marginTop: "7px",
                            }}
                          >
                            <img
                              className={classes.img}
                              src={handleImageUrl(card.img)}
                              alt="not available images"
                              style={{
                                borderRadius: "9px",
                                border: `1px solid ${theme.palette.background.entity_border}`,
                                height: `${80}px`,
                                width: "80px",
                              }}
                            />
                          </Box>
                        ) : (
                          <Box
                            className={classes.imgBoxNotAvailableStyle}
                            style={{ height: `${80}px`, marginTop: "7px" }}
                          >
                            <ImageOutlinedIcon style={{ fontSize: `52px` }} />
                          </Box>
                        )}
                        <Grid
                          item
                          xs
                          container
                          direction="column"
                          style={{ paddingLeft: "8px" }}
                        >
                          <Grid item xs>
                            <Typography
                              style={
                                card.valid === false
                                  ? { fontWeight: "bold", color: "white" }
                                  : { fontWeight: "bold" }
                              }
                              align="left"
                              className={classes.titleStyle}
                            >
                              {card?.name}
                            </Typography>
                            <Typography
                              className={classes.boxStyle}
                              style={
                                card.valid === false
                                  ? {
                                      color: "white",
                                      textTransform: "lowercase",
                                    }
                                  : {
                                      textTransform: "lowercase",
                                    }
                              }
                              variant="body2"
                              align="left"
                            >
                              {card?.desc}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "end",
                          paddingRight: "12px",
                        }}
                      >
                        <Typography
                          variant="caption"
                          align="right"
                          className={classes.priceStyle}
                        >
                          {" "}
                          <span
                            className="material-symbols-outlined"
                            style={
                              card.valid === false
                                ? { color: "white" }
                                : { textTransform: "lowercase" }
                            }
                          >
                            note_stack
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                ) : item.length === 2 ? (
                  <Box
                    style={{
                      height: "100px",
                      width: "100%",
                      borderRadius: "10px",
                      border: `1px solid ${theme.palette.background.entity_border_menu_entity_background}`,
                      backgroundColor: !card.valid
                        ? theme.palette.custom.red.main
                        : isColorChange ===
                          `${card.departmentId}*${card.linkedId}`
                        ? hexToRgbOpacity(
                            theme.palette.background.menu_entity_background,
                          )
                        : theme.palette.background.menu_entity_background,
                        transform:
                        isColorChange ===
                        `${card.departmentId}*${card.linkedId}`
                          ? `rotate(${rotation>2?2:rotation}deg)`
                          : "rotate(2deg)",
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={9}
                        sm={10}
                        md={10}
                        lg={10}
                        xl={10}
                        style={{ display: "flex" }}
                      >
                        {card?.img ? (
                          <Box
                            style={{
                              marginLeft: "7px",
                              borderRadius: "10px",
                              height: `${80}px`,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                              marginTop: "10px",
                            }}
                          >
                            <img
                              className={classes.img}
                              src={handleImageUrl(card.img)}
                              alt="not available images"
                              style={{
                                borderRadius: "9px",
                                border: `1px solid ${theme.palette.background.entity_border}`,
                                height: `${80}px`,
                                width: "114px",
                              }}
                            />
                          </Box>
                        ) : (
                          <Box
                            className={classes.imgBoxNotAvailableStyle}
                            style={{ height: `${80}px`, marginTop: "10px" }}
                          >
                            <ImageOutlinedIcon
                              style={{ fontSize: `${52 - index * 5}px` }}
                            />
                          </Box>
                        )}
                        <Grid
                          item
                          xs
                          container
                          direction="column"
                          style={{ paddingLeft: "8px" }}
                        >
                          <Grid item xs>
                            <Typography
                              style={
                                card.valid === false
                                  ? { fontWeight: "bold", color: "white" }
                                  : { fontWeight: "bold" }
                              }
                              align="left"
                              className={classes.titleStyle}
                            >
                              {card?.name}
                            </Typography>
                            <Typography
                              className={classes.boxStyle}
                              style={
                                card.valid === false
                                  ? {
                                      color: "white",
                                      textTransform: "lowercase",
                                    }
                                  : {
                                      textTransform: "lowercase",
                                    }
                              }
                              variant="body2"
                              align="left"
                            >
                              {card?.desc}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "end",
                          paddingRight: "12px",
                        }}
                      ></Grid>
                    </Grid>
                  </Box>
                ) : (
                  item.length > 2 && (
                    <Box
                      style={{
                        height: "100px",
                        width: "100%",
                        borderRadius: "10px",
                        border: `1px solid ${theme.palette.background.entity_border_menu_entity_background}`,

                        backgroundColor: !card.valid
                          ? theme.palette.custom.red.main
                          : isColorChange ===
                            `${card.departmentId}*${card.linkedId}`
                          ? hexToRgbOpacity(
                              theme.palette.background.menu_entity_background,
                            )
                          : theme.palette.background.menu_entity_background,
                          transform:
                          index === 2
                            ? isColorChange ===
                              `${card.departmentId}*${card.linkedId}`
                              ? `rotate(${rotation>2?178:180 - rotation }deg)`
                              : "rotate(178deg)"
                            : isColorChange ===
                              `${card.departmentId}*${card.linkedId}`
                            ? `rotate(${rotation>2?2:rotation}deg)`
                            : "rotate(1.5deg)",
                      }}
                    >
                      <Grid container>
                        <Grid
                          item
                          xs={9}
                          sm={10}
                          md={10}
                          lg={10}
                          xl={10}
                          style={{ display: "flex" }}
                        >
                          {card?.img ? (
                            <Box
                              style={{
                                marginLeft: "7px",
                                borderRadius: "10px",
                                height: `${80}px`,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                                marginTop: "7px",
                              }}
                            >
                            </Box>
                          ) : (
                            <Box
                              className={classes.imgBoxNotAvailableStyle}
                              style={{ height: `${80}px`, marginTop: "7px" }}
                            >
                            </Box>
                          )}
                          <Grid
                            item
                            xs
                            container
                            direction="column"
                            style={{ paddingLeft: "8px" }}
                          >
                            <Grid item xs>
                              <Typography
                                style={
                                  card.valid === false
                                    ? { fontWeight: "bold", color: "white" }
                                    : { fontWeight: "bold" }
                                }
                                align="left"
                                className={classes.titleStyle}
                              >
                                {card?.name}
                              </Typography>
                              <Typography
                                className={classes.boxStyle}
                                style={
                                  card.valid === false
                                    ? {
                                        color: "white",
                                        textTransform: "lowercase",
                                      }
                                    : {
                                        textTransform: "lowercase",
                                      }
                                }
                                variant="body2"
                                align="left"
                              >
                                {card?.desc}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "end",
                            paddingRight: "12px",
                          }}
                        ></Grid>
                      </Grid>
                    </Box>
                  )
                )}
              </Box>
            </Slide>
          ))}
        </div>
      ) : (
        <div
          style={{
            position: "relative",
            height: "140px",
            width: "100%",
            zIndex: 0,
            cursor: "pointer",
          }}
          onClick={() => handleClickLinkedId(item)}
        >
          {item.map((card: any, index: any) => (
            <Slide
              in={true}
              direction="left"
              mountOnEnter
              unmountOnExit
              timeout={300 * index}
            >
              <Box
                boxShadow={isDarkMode === "true" ? 4 : 0}
                style={{
                  position: "absolute",
                  left: 0,
                  width: "100%",
                  borderRadius: "10px",
                  border: `1px solid ${theme.palette.background.entity_border}`,
                  backgroundColor: !card.valid
                    ? theme.palette.custom.red.main
                    :  theme.palette.background.entity_background,
                }}
              >
                {index + 1 === item.length ? (
                  <div
                    style={{
                      backgroundColor: !card.valid
                        ? theme.palette.custom.red.main
                        : theme.palette.background.entity_background,
                      height: "134px",
                      borderRadius: "10px",
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={9}
                        sm={10}
                        md={10}
                        lg={10}
                        xl={10}
                        style={{ display: "flex" }}
                      >
                        {card?.img ? (
                          <Box
                            style={{
                              marginLeft: "10px",
                              borderRadius: "10px",
                              height: `${114}px`,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                              marginTop: "10px",
                            }}
                          >
                            <img
                              className={classes.img}
                              src={handleImageUrl(card.img)}
                              alt="not available images"
                              style={{
                                borderRadius: "9px",
                                border: `1px solid ${theme.palette.background.entity_border}`,
                                height: `${114}px`,
                                width: "114px",
                              }}
                            />
                          </Box>
                        ) : (
                          <Box
                            className={classes.imgBoxNotAvailableStyle}
                            style={{ height: `${114}px`, marginTop: "10px" }}
                          >
                            <ImageOutlinedIcon
                              style={{ fontSize: `${52 - index * 5}px` }}
                            />
                          </Box>
                        )}
                        <Grid
                          item
                          xs
                          container
                          direction="column"
                          style={{ paddingLeft: "8px" }}
                        >
                          <Grid item xs>
                            <Typography
                              style={
                                card.valid === false
                                  ? { fontWeight: "bold", color: "white" }
                                  : { fontWeight: "bold" }
                              }
                              align="left"
                              className={classes.titleStyle}
                            >
                              {card?.name}
                            </Typography>
                            <Typography
                              className={classes.boxStyle}
                              style={
                                card.valid === false
                                  ? {
                                      color: "white",
                                      textTransform: "lowercase",
                                    }
                                  : {
                                      textTransform: "lowercase",
                                    }
                              }
                              variant="body2"
                              align="left"
                            >
                              {card?.desc}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "end",
                          paddingRight: "12px",
                        }}
                      >
                        <Typography
                          variant="caption"
                          align="right"
                          className={classes.priceStyle}
                        >
                          {" "}
                          <span
                            className="material-symbols-outlined"
                            style={
                              card.valid === false
                                ? { color: "white" }
                                : { textTransform: "lowercase" }
                            }
                          >
                            note_stack
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                ) : item.length === 2 ? (
                  <Box
                    style={{
                      height: "134px",
                      width: "100%",
                      borderRadius: "10px",
                      border: `1px solid ${theme.palette.background.entity_border_menu_entity_background}`,
                      backgroundColor: !card.valid
                        ? theme.palette.custom.red.main
                        : isColorChange ===
                          `${card.departmentId}*${card.linkedId}`
                        ? hexToRgbOpacity(
                            theme.palette.background.menu_entity_background,
                          )
                        : theme.palette.background.menu_entity_background,
                      transform:
                        isColorChange ===
                        `${card.departmentId}*${card.linkedId}`
                          ? `rotate(${rotation>3?3:rotation}deg)`
                          : "rotate(3deg)",
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={9}
                        sm={10}
                        md={10}
                        lg={10}
                        xl={10}
                        style={{ display: "flex" }}
                      >
                        {card?.img ? (
                          <Box
                            style={{
                              marginLeft: "10px",
                              borderRadius: "10px",
                              height: `${114}px`,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                              marginTop: "10px",
                            }}
                          >
                            <img
                              className={classes.img}
                              src={handleImageUrl(card.img)}
                              alt="not available images"
                              style={{
                                borderRadius: "9px",
                                border: `1px solid ${theme.palette.background.entity_border}`,
                                height: `${114}px`,
                                width: "114px",
                              }}
                            />
                          </Box>
                        ) : (
                          <Box
                            className={classes.imgBoxNotAvailableStyle}
                            style={{ height: `${114}px`, marginTop: "10px" }}
                          >
                            <ImageOutlinedIcon
                              style={{ fontSize: `${52 - index * 5}px` }}
                            />
                          </Box>
                        )}
                        <Grid
                          item
                          xs
                          container
                          direction="column"
                          style={{ paddingLeft: "8px" }}
                        >
                          <Grid item xs>
                            <Typography
                              style={
                                card.valid === false
                                  ? { fontWeight: "bold", color: "white" }
                                  : { fontWeight: "bold" }
                              }
                              align="left"
                              className={classes.titleStyle}
                            >
                              {card?.name}
                            </Typography>
                            <Typography
                              className={classes.boxStyle}
                              style={
                                card.valid === false
                                  ? {
                                      color: "white",
                                      textTransform: "lowercase",
                                    }
                                  : {
                                      textTransform: "lowercase",
                                    }
                              }
                              variant="body2"
                              align="left"
                            >
                              {card?.desc}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "end",
                          paddingRight: "12px",
                        }}
                      >
                        <Typography
                          variant="caption"
                          align="right"
                          className={classes.priceStyle}
                        >
                          {" "}
                          <span
                            className="material-symbols-outlined"
                            style={
                              card.valid === false
                                ? { color: "white" }
                                : { textTransform: "lowercase" }
                            }
                          >
                            note_stack
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                ) : (
                  item.length > 2 && (
                    <Box
                      style={{
                        height: "134px",
                        width: "100%",
                        borderRadius: "10px",
                        border: `1px solid ${theme.palette.background.entity_border_menu_entity_background}`,

                        backgroundColor: !card.valid
                          ? theme.palette.custom.red.main
                          : isColorChange ===
                            `${card.departmentId}*${card.linkedId}`
                          ? hexToRgbOpacity(
                              theme.palette.background.menu_entity_background,
                            )
                          : theme.palette.background.menu_entity_background,
                        transform:
                          index === 2
                            ? isColorChange ===
                              `${card.departmentId}*${card.linkedId}`
                              ? `rotate(${rotation>3?177:180 - rotation}deg)`
                              : "rotate(177deg)"
                            : isColorChange ===
                              `${card.departmentId}*${card.linkedId}`
                            ? `rotate(${rotation>3?3:rotation}deg)`
                            : "rotate(3deg)",
                      }}
                    >
                      <Grid container>
                        <Grid
                          item
                          xs={9}
                          sm={10}
                          md={10}
                          lg={10}
                          xl={10}
                          style={{ display: "flex" }}
                        >
                          {card?.img ? (
                            <Box
                              style={{
                                marginLeft: "10px",
                                borderRadius: "10px",
                                height: `${114}px`,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                                marginTop: "10px",
                              }}
                            >
                              <img
                                className={classes.img}
                                src={handleImageUrl(card.img)}
                                alt="not available images"
                                style={{
                                  borderRadius: "9px",
                                  border: `1px solid ${theme.palette.background.entity_border}`,
                                  height: `${114}px`,
                                  width: "100px",
                                }}
                              />
                            </Box>
                          ) : (
                            <Box
                              className={classes.imgBoxNotAvailableStyle}
                              style={{ height: `${114}px`, marginTop: "7px" }}
                            >
                              <ImageOutlinedIcon
                                style={{ fontSize: `${52 - index * 5}px` }}
                              />
                            </Box>
                          )}
                          <Grid
                            item
                            xs
                            container
                            direction="column"
                            style={{ paddingLeft: "8px" }}
                          >
                            <Grid item xs>
                              <Typography
                                style={
                                  card.valid === false
                                    ? { fontWeight: "bold", color: "white" }
                                    : { fontWeight: "bold" }
                                }
                                align="left"
                                className={classes.titleStyle}
                              >
                                {card?.name}
                              </Typography>
                              <Typography
                                className={classes.boxStyle}
                                style={
                                  card.valid === false
                                    ? {
                                        color: "white",
                                        textTransform: "lowercase",
                                      }
                                    : {
                                        textTransform: "lowercase",
                                      }
                                }
                                variant="body2"
                                align="left"
                              >
                                {card?.desc}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "end",
                            paddingRight: "12px",
                          }}
                        ></Grid>
                      </Grid>
                    </Box>
                  )
                )}
              </Box>
            </Slide>
          ))}
        </div>
      )}
    </>
  );
};

export default CategoryMenuListStrip;
