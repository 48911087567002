import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  Cell,
  HeaderCell,
} from "@table-library/react-table-library/table";
import { useLocation } from "react-router";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  createStyles,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useSort } from "@table-library/react-table-library/sort";
import GetAppIcon from "@material-ui/icons/GetApp";
import "jspdf-autotable";
import { Icon } from "@iconify/react";

import { CustomTheme } from "../../../../types/customTheme";
import CardCommon from "../../../../components/card/CardCommon";
import { sortNumbers, sortStrings } from "../../../../utils/commonArrayMap";
import WithLoading from "../../../../utils/WithLoading";
import SortingMethod from "../../../../components/common/SortingMethod/SortingMethod";
import { convertDateFormatYearValidation } from "../../../../utils/ConvertDateTimeFormat";
import LocationDetailsCard from "../../../../components/common/LocationDetailsCard";
import MenuButtonCommon from "../../../../components/buttons/MenuButtonCommon";
import { handleDownloadCsv } from "./convertPdfExcel/convertDataToExcel";
import { handleDownloadPdf } from "./convertPdfExcel/convertDataToPdf";

export interface CustomerNodeProps {
  customerNode: any;
  isLoading: any;
  isSort: any;
  sortingMethod: any;
  handleChangeOrderListSorting: any;
  handleRemoveOrderListSorting: any;
  disableButton: any;
  headerName: any;
  locationSelectedList: any;
  handleOnClickText: any;
  filterDetails: any;
}

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    startTextStyleHeader: {
      fontWeight: "bold",
      display: "flex",
      justifyContent: "start",
      fontSize: "12px",
    },
    startTextStyle: {
      fontWeight: "bold",
      display: "flex",
      justifyContent: "start",
      paddingLeft: "16px",
      fontSize: "12px",
    },
    textStyleHeader: {
      display: "flex",
      justifyContent: "center",
      fontWeight: "bold",
      fontSize: "12px",
    },
    endTextStyle: {
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      fontSize: "12px",
      paddingRight: "12px",
    },
    headerEndTextStyle: {
      display: "flex",
      justifyContent: "end",
      fontSize: "12px",
      fontWeight: "bold",
    },
    textStyle: {
      display: "flex",
      justifyContent: "center",
      fontSize: "12px",
    },
    headerStyle: {
      "&:last-of-type": {
        backgroundColor: theme.palette.background.table_header_background,
        color: theme.palette.custom.orange.contrastText,
      },
    },
    headerRow: {
      fontSize: "13px",
      "&.css-16gtl2w": {
        display: "flex",
        justifyContent: "center",
      },
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.background.paper,
      },
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.background.entity_background,
      },
    },
    baseCellRow: {
      placeItems: "center",
      "& .css-16gtl2w": {
        fontWeight: "bold",
      },
      height: "44px",
      [theme.breakpoints.down("xl")]: {
        "&:nth-of-type(1)": {
          left: "0px",
          minWidth: "20%",
          width: "20%",
        },
        [`&:nth-of-type(n+2):nth-of-type(-n+${14})`]: {
          minWidth: `${80 / 14}%`,
          width: `${80 / 14}%`,
          "& .css-16gtl2w": {
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          },
        },
        [`&:nth-of-type(${15})`]: {
          minWidth: `${80 / 14}%`,
          width: `${80 / 14}%`,
          "& .css-16gtl2w": {
            display: "flex",
            justifyContent: "end",
            fontWeight: "bold",
          },
        },
      },
      [theme.breakpoints.down("lg")]: {
        "&:nth-of-type(1)": {
          left: "0px",
          minWidth: "20%",
          width: "20%",
        },
        [`&:nth-of-type(n+2):nth-of-type(-n+${14})`]: {
          minWidth: "14%",
          width: "14%",
          "& .css-16gtl2w": {
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          },
        },
        [`&:nth-of-type(${15})`]: {
          minWidth: "14%",
          width: "14%",
          "& .css-16gtl2w": {
            display: "flex",
            justifyContent: "end",
            fontWeight: "bold",
            paddingRight: "12px",
          },
        },
      },
      [theme.breakpoints.down("md")]: {
        "&:nth-of-type(1)": {
          left: "0px",
          minWidth: "25%",
          width: "25%",
        },
        "&:nth-of-type(2)": {
          minWidth: "20%",
          width: "20%",
          "& .css-16gtl2w": {
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          },
        },
        [`&:nth-of-type(n+3):nth-of-type(-n+${14})`]: {
          minWidth: "13%",
          width: "13%",
          "& .css-16gtl2w": {
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          },
        },
        [`&:nth-of-type(${15})`]: {
          minWidth: "13%",
          width: "13%",
          "& .css-16gtl2w": {
            display: "flex",
            justifyContent: "end",
            fontWeight: "bold",
            paddingRight: "12px",
          },
        },
      },
      [theme.breakpoints.down("sm")]: {
        "&:nth-of-type(1)": {
          left: "0px",
          minWidth: "30%",
          width: "30%",
        },
        "&:nth-of-type(2)": {
          minWidth: "28%",
          width: "28%",
          "& .css-16gtl2w": {
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          },
        },
        [`&:nth-of-type(n+3):nth-of-type(-n+${14})`]: {
          minWidth: "12%",
          width: "12%",
          "& .css-16gtl2w": {
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          },
        },
        [`&:nth-of-type(${15})`]: {
          minWidth: "12%",
          width: "12%",
          "& .css-16gtl2w": {
            display: "flex",
            justifyContent: "end",
            fontWeight: "bold",
            paddingRight: "12px",
          },
        },
      },
      [theme.breakpoints.down("xs")]: {
        "&:nth-of-type(1)": {
          left: "0px",
          minWidth: "26%",
          width: "26%",
        },
        "&:nth-of-type(2)": {
          minWidth: "30%",
          width: "30%",
          "& .css-16gtl2w": {
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          },
        },
        [`&:nth-of-type(n+3):nth-of-type(-n+${14})`]: {
          minWidth: "14%",
          width: "14%",
          "& .css-16gtl2w": {
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          },
        },
        [`&:nth-of-type(${15})`]: {
          minWidth: "14%",
          width: "14%",
          "& .css-16gtl2w": {
            display: "flex",
            justifyContent: "end",
            fontWeight: "bold",
            paddingRight: "12px",
          },
        },
      },
    },
  }),
);

const sortObj: any = {
  Orders: "orderCount",
  Collections: "takeoutCount",
  Deliveries: "deliveryCount",
  Marketing: "marketing",
  "Lifetime Value": "totalValue",
  Cash: "cashValue",
  Card: "cardValue",
  "Other Payment": "otherValue",
  "First Order": "created",
  "Last Order": "lastOrder",
};

/**
 * CustomerNode Component
 *
 * This component is responsible for rendering the customer node table.
 * It displays customer information in a table format and provides options for sorting and exporting data.
 */
const CustomerNode: React.FunctionComponent<CustomerNodeProps> = ({
  customerNode,
  isLoading,
  isSort,
  sortingMethod,
  handleChangeOrderListSorting,
  handleRemoveOrderListSorting,
  disableButton,
  headerName,
  locationSelectedList,
  handleOnClickText,
  filterDetails,
}) => {
  const [headersList, setHeadersList] = useState<any>([]);
  const [nodes, setNodes] = useState<any>({ nodes: [] });
  const [pdfExcelNodes, setPdfExcelNodes] = useState<any>({ nodes: [] });
  const [excelNodes, setExcelNodes] = useState<any>({ nodes: [] });
  const [excelHeaders, setExcelHeaders] = useState<any>([]);
  const [pdfHeaders, setPdfHeaders] = useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const maxWidth = useMediaQuery("(max-width: 2000px)");
  const maxWidthCustom = useMediaQuery("(max-width: 2000px)");
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  /**
   * Reset the nodes state when the start date changes
   */
  useEffect(() => {
    setNodes({ nodes: [] });
  }, [params.get("startDate")]);

  const getLatestOrderAddressDetails = (addresses: any) => {
    if (addresses.length > 0) {
      if (addresses.length === 1) {
        return addresses[0];
      } else {
        return addresses.reduce((prev: any, current: any) => {
          // Convert the date strings to Date objects for comparison
          const prevDate = new Date(prev.lastOrder);
          const currentDate = new Date(current.lastOrder);

          // Return the object with the later date
          return prevDate > currentDate ? prev : current;
        });
      }
    } else {
      return {};
    }
  };

  const getLatestContactNoDetails = (contacts: any) => {
    if (contacts.length > 0) {
      if (contacts.length === 1) {
        return contacts[0];
      } else {
        return contacts.reduce((prev: any, current: any) => {
          // Convert the date strings to Date objects for comparison
          const prevDate = new Date(prev.created);
          const currentDate = new Date(current.created);

          // Return the object with the later date
          return prevDate > currentDate ? prev : current;
        });
      }
    } else {
      return {};
    }
  };

  /**
   * This useEffect hook is responsible for processing the customerNode data and updating the state variables accordingly.
   * It is triggered whenever the customerNode prop changes.
   */
  useEffect(() => {
    // Clone the customerNode array to avoid mutating the original data
    let cloneCustomerNode: any = _.cloneDeep(customerNode);
    let customerNodeList: any = [];
    let customerNodeListExcel: any = [];

    // Iterate through each customer object in the cloneCustomerNode array
    if (!_.isEmpty(cloneCustomerNode)) {
      cloneCustomerNode.map((customer: any) => {
        const latestOrderAddressDetails = getLatestOrderAddressDetails(
          customer.addresses,
        );
        let latestContactNoDetails: any = {};
        if (
          _.isEmpty(latestOrderAddressDetails) ||
          latestOrderAddressDetails.contactNo === ""
        ) {
          latestContactNoDetails = getLatestContactNoDetails(customer.contacts);
        }

        // Extract relevant data fields and format them as needed
        customerNodeList.push({
          Name: customer.customer.firstName + " " + customer.customer.lastName,
          "Last Order": convertDateFormatYearValidation(customer.lastOrder),
          "First Order": convertDateFormatYearValidation(customer.created),
          "Lifetime Value": parseFloat(customer.totalValue).toFixed(2),
          Orders: customer.orderCount,
          Collections: customer.takeoutCount,
          Deliveries: customer.deliveryCount,
          Marketing: handleMarketingExcel(customer.customer.marketing),
          Email: customer.customer.primaryEmail,
          Phone: latestOrderAddressDetails.contactNo
            ? latestOrderAddressDetails.contactNo
            : latestContactNoDetails.contactNo,
          "Door No": latestOrderAddressDetails.doorNo,
          "Address Line 1": latestOrderAddressDetails.addressLine1,
          "Address Line 2": latestOrderAddressDetails.addressLine2,
          City: latestOrderAddressDetails.city,
          Postcode: latestOrderAddressDetails.postcode,
        });
      });
    }

    // Process the customerNodeList data and update state variables
    if (!_.isEmpty(customerNodeList)) {
      // Set table headers
      setHeadersList([
        "Name",
        "Last Order",
        "First Order",
        "Lifetime Value",
        "Orders",
        "Collections",
        "Deliveries",
        "Marketing",
        "Email",
        "Phone",
        "Door No",
        "Address Line 1",
        "Address Line 2",
        "City",
        "Postcode",
      ]);

      // Set pdf headers
      setPdfHeaders([
        { title: "Name", field: "Name" },
        { title: "Last Order", field: "Last Order" },
        { title: "First Order", field: "First Order" },
        { title: "Lifetime Value", field: "Lifetime Value" },
        { title: "Orders", field: "Orders" },
        { title: "Collections", field: "Collections" },
        { title: "Deliveries", field: "Deliveries" },
        { title: "Marketing", field: "Marketing" },
        { title: "Email", field: "Email" },
        { title: "Phone", field: "Phone" },
        { title: "Door No", field: "Door No" },
        { title: "Address Line 1", field: "Address Line 1" },
        { title: "Address Line 2", field: "Address Line 2" },
        { title: "City", field: "City" },
        { title: "Postcode", field: "Postcode" },
      ]);

      // Set excel headers
      setExcelHeaders([
        { header: "Name", key: "Name", width: 40, height: 68 },
        { header: "Last Order", key: "Last Order", width: 20, height: 68 },
        { header: "First Order", key: "First Order", width: 20, height: 68 },
        {
          header: "Lifetime Value",
          key: "Lifetime Value",
          width: 20,
          height: 68,
        },
        { header: "Orders", key: "Orders", width: 20, height: 68 },
        { header: "Collections", key: "Collections", width: 20, height: 68 },
        { header: "Deliveries", key: "Deliveries", width: 20, height: 68 },
        { header: "Marketing", key: "Marketing", width: 20, height: 68 },
        { header: "Email", key: "Email", width: 20, height: 68 },
        { header: "Phone", key: "Phone", width: 20, height: 68 },
        { header: "Door No", key: "Door No", width: 20, height: 68 },
        {
          header: "Address Line 1",
          key: "Address Line 1",
          width: 20,
          height: 68,
        },
        {
          header: "Address Line 2",
          key: "Address Line 2",
          width: 20,
          height: 68,
        },
        {
          header: "City",
          key: "City",
          width: 20,
          height: 68,
        },
        {
          header: "Postcode",
          key: "Postcode",
          width: 20,
          height: 68,
        },
      ]);

      // set the table data in the state
      setNodes({ nodes: customerNodeList });
      setPdfExcelNodes(customerNodeList);
    }
  }, [customerNode]);

  /* Open PDF / Excel selection menu */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /* Close PDF / Excel selection menu */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMarketingExcel = (marketing: any) => {
    if (marketing === true) {
      return "Opted-in";
    } else if (marketing === false) {
      return "Opted-out";
    }
    return "-";
  };

  const open = Boolean(anchorEl);
  const theme: CustomTheme = useTheme();
  const classes: any = useStyles();
  const maxWidthLocationChips = useMediaQuery("(max-width: 600px)");

  return (
    <div style={{ marginTop: "24px" }}>
      <Grid
        container
        style={{
          marginBottom: "20px",
          marginTop: "8px",
        }}
      >
        <Grid item xs={12}>
          <div
            style={
              maxWidthLocationChips
                ? { display: "block" }
                : {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }
            }
          >
            <LocationDetailsCard
              locationSelectedList={locationSelectedList}
              handleOnClickText={handleOnClickText}
            />
            {!_.isEmpty(nodes.nodes) && (
              <div
                style={
                  maxWidthLocationChips
                    ? {
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "8px",
                      }
                    : { marginLeft: "16px" }
                }
              >
                <MenuButtonCommon
                  id="basic-button"
                  variant="contained"
                  style={{
                    fontSize: 11,
                    height: "38px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                  }}
                  color="blue"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <GetAppIcon />
                  <Typography
                    variant="body1"
                    style={{ paddingLeft: "16px", color: "white" }}
                  >
                    Export
                  </Typography>
                </MenuButtonCommon>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    elevation: 0,
                    style: {
                      borderRadius: "10px",
                      border: `1px solid ${theme.palette.background.entity_border}`,
                    },
                  }}
                  style={{ top: "56px" }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleDownloadPdf(
                        "l",
                        pdfHeaders,
                        pdfExcelNodes,
                        filterDetails,
                      );
                      handleClose();
                    }}
                  >
                    Export as PDF
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleDownloadCsv(
                        excelHeaders,
                        pdfExcelNodes,
                        filterDetails,
                        customerNode.length,
                      );
                      handleClose();
                    }}
                  >
                    Export as CSV
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      {!_.isEmpty(customerNode) ? (
        <CardCommon>
          <Table
            data={nodes}
            layout={{ custom: maxWidthCustom, horizontalScroll: maxWidth }}
          >
            {(tableList) => (
              <>
                <Header style={{ zIndex: 0 }}>
                  <HeaderRow className={classes.headerStyle}>
                    {headersList.map((data: any, index: any) => (
                      <HeaderCell
                        sortKey={data}
                        pinLeft={index === 0 ? true : false}
                        className={classes.baseCellRow}
                        style={{ paddingRight: "4px" }}
                      >
                        <div
                          className={
                            index === 0
                              ? classes.startTextStyleHeader
                              : index === 14
                              ? classes.headerEndTextStyle
                              : classes.textStyleHeader
                          }
                        >
                          <SortingMethod
                            handleChangeOrderListSorting={
                              handleChangeOrderListSorting
                            }
                            handleRemoveOrderListSorting={
                              handleRemoveOrderListSorting
                            }
                            headerName={data}
                            headerId={sortObj[data]}
                            disableButton={
                              disableButton ||
                              data === "Name" ||
                              data === "Phone" ||
                              data === "Door No" ||
                              data === "Address Line 1" ||
                              data === "Address Line 2" ||
                              data === "City" ||
                              data === "Postcode" ||
                              data === "Email" ||
                              data === "Marketing"
                            }
                            color={"orange"}
                            isSort={
                              headerName === sortObj[data] ? isSort : false
                            }
                            sortingMethod={
                              headerName === sortObj[data] ? sortingMethod : ""
                            }
                          />
                        </div>
                      </HeaderCell>
                    ))}
                  </HeaderRow>
                </Header>

                <Body className={classes.bodyStyle}>
                  {tableList.map((item) => (
                    <>
                      <Row
                        key={item.gross20}
                        item={item}
                        className={
                          item.Method === "Lifetime Value Payments"
                            ? classes.totalRow
                            : classes.headerRow
                        }
                      >
                        {Object.keys(item).map((data: any, index: any) => (
                          <>
                            <Cell
                              pinLeft={index === 0 ? true : false}
                              className={classes.baseCellRow}
                            >
                              {data === "Marketing" && item[data] != "-" ? (
                                <Typography
                                  variant="caption"
                                  align={
                                    index === 0
                                      ? "left"
                                      : index === 14
                                      ? "right"
                                      : "center"
                                  }
                                  style={{
                                    fontWeight: "bold",
                                    color:
                                      item[data] === "Opted-in"
                                        ? theme.palette.custom.green.main
                                        : theme.palette.custom.red.main,
                                  }}
                                  className={
                                    index === 0
                                      ? classes.startTextStyle
                                      : index === 14
                                      ? classes.endTextStyle
                                      : classes.textStyle
                                  }
                                >
                                  {item[data]}
                                </Typography>
                              ) : (
                                <Typography
                                  variant="caption"
                                  align={
                                    index === 0
                                      ? "left"
                                      : index === 14
                                      ? "right"
                                      : "center"
                                  }
                                  className={
                                    index === 0
                                      ? classes.startTextStyle
                                      : index === 14
                                      ? classes.endTextStyle
                                      : classes.textStyle
                                  }
                                >
                                  {item[data]}
                                </Typography>
                              )}
                            </Cell>
                          </>
                        ))}
                      </Row>
                    </>
                  ))}
                </Body>
              </>
            )}
          </Table>
        </CardCommon>
      ) : (
        <Typography variant="h4" style={{ marginTop: "80px" }}>
          Customer Not Available.
        </Typography>
      )}
    </div>
  );
};

export default WithLoading(CustomerNode);
