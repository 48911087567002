import * as React from "react";
import { Grid, Typography, createStyles, makeStyles } from "@material-ui/core";
import _ from "lodash";

import { CustomTheme } from "../../../../../types/customTheme";
import TextfieldCommon from "../../../../../components/textField/TextfieldCommon";
import TextFieldCustom from "../../../../../components/textField/TextdieldCustom";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    labelStyle: {
      fontSize: "17px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "15px",
      },
    },
  }),
);

export interface WizardModalProps {
  warningThreshold: any;
  setWarningThreshold: any;
  baseUnit: any;
}

const StepFive: React.FunctionComponent<WizardModalProps> = ({
  warningThreshold,
  setWarningThreshold,
  baseUnit,
}) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h6">
        Please choose your preferred warning threshold level
      </Typography>
      <Typography variant="body1">
        Set a warning threshold to highlight low stock levels during purchase
        orders.
      </Typography>
      <Grid container style={{ marginTop: "20px" }}>
        <Grid item xs={12}>
          <TextFieldCustom
            id="warningThreshold"
            name="warningThreshold"
            type="number"
            label={
              <Typography className={classes.labelStyle}>
                {`Warning Threshold in ${baseUnit}`}
              </Typography>
            }
            value={warningThreshold}
            disabled={false}
            onChange={(e: any) => setWarningThreshold(e.target.value)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default StepFive;
