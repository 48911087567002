import React from "react";
import _ from "lodash";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import IndeterminateCheckBoxOutlinedIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { CustomTheme } from "../../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    textField: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      borderRadius: "10px",
      border: "none",
      backgroundColor: theme.palette.background.entity_highlight_background,
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },

      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiOutlinedInput-inputMarginDense": {
        height: "16px",
      },
      '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
      {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
    buttonStyle: {
      backgroundColor: theme.palette.custom.blue.main,
      "&:hover": {
        backgroundColor: theme.palette.custom.blue.light,
      },
      "& .MuiOutlinedInput-root .MuiButton-root": {
        minWidth: "400px !important",
      },
      "& .MuiButton-root": {
        minWidth: "400px !important",
      },
      color: "white",
      height: "28px",
      padding: "0px",
    },
  }),
);

export interface IncrementDecrementTextboxProps {
  setInitialOrderQty: any;
  initialOrderQty: any;
  handleIncrement: any;
  handleDecrement: any;
  isDisable?: any;
  displaySuffix: any;
  isWaste: any
}

/**
 * This component provides an input field with increment and decrement buttons for adjusting quantity values.
 * It is commonly used in various parts of the application to allow users to modify quantities easily.
 */
const IncrementDecrementTextboxNewsDefault: React.FunctionComponent<
  IncrementDecrementTextboxProps
> = ({
  setInitialOrderQty,
  initialOrderQty,
  handleIncrement,
  handleDecrement,
  isDisable,
  displaySuffix,
  isWaste
}) => {
    const classes = useStyles();
    return (
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            className={classes.buttonStyle}
            disabled={
              isDisable ||
              (parseFloat(initialOrderQty) > 0 &&
                parseFloat(initialOrderQty) < 2) ||
              parseFloat(initialOrderQty) <= 0
            }
            style={{ maxWidth: "44px", minWidth: "44px" }}
            onClick={handleDecrement}
          >
            <RemoveIcon
              style={{
                fontSize: "16px",
              }}
            />
          </Button>
          <TextField
            id="outputMultiplier"
            name="outputMultiplier"
            label=""
            type="number"
            style={{
              width: "148px",
              marginLeft: "12px",
              marginRight: "12px",
            }}
            variant="outlined"
            className={classes.textField}
            margin="dense"
            disabled={isDisable}
            value={initialOrderQty}
            onChange={(event: any) => {
              if (event.target.value > -1) {
                setInitialOrderQty(event.target.value);
              }
            }}
            InputProps={{
              endAdornment: (
                <>
                  <InputAdornment position="end"><Typography style={{ fontSize: "12px" }}>{displaySuffix}</Typography></InputAdornment>
                </>
              ),
              startAdornment: (
                <>
                  <InputAdornment position="start">-</InputAdornment>
                </>
              ),
              style: { fontSize: "20px" },
            }}
          />
          <Button
            className={classes.buttonStyle}
            disabled={isDisable}
            onClick={handleIncrement}
            size="small"
            style={{ maxWidth: "44px", minWidth: "44px" }}
          >
            <AddIcon
              style={{
                fontSize: "16px",
              }}
            />
          </Button>
        </div>
        <div
          style={{
            marginBottom: "4px",
            display: "flex",
            justifyContent: "Center",
          }}
        >
          <Typography variant="caption">{isWaste ? "Waste Qty" : "Default Deduction"}</Typography>
        </div>
      </div>
    );
  };

export default IncrementDecrementTextboxNewsDefault;
