import {
  Box,
  Button,
  Card,
  createStyles,
  Grid,
  makeStyles,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import _ from "lodash";

import { CustomTheme } from "../../../../types/customTheme";
import { getLocalStore } from "../../../../utils/store/localStore";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    imgBoxStyle: {
      margin: "7px",
      marginLeft: "10px",
      borderRadius: "10px",
      height: "120px",
      [theme.breakpoints.down("sm")]: {
        height: "92px",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
    },
    titleStyle: {
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",

      [theme.breakpoints.down("sm")]: {
        display: "-webkit-box",
        "-webkit-line-clamp": 1,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    imgBoxNotAvailableStyle: {
      marginRight: "7px",
      marginTop: "10px",
      marginLeft: "10px",
      borderRadius: "10px",
      marginBottom: "10px",
      height: "114px",
      width: "114px",
      [theme.breakpoints.down("sm")]: {
        height: "80px",
        width: "80px",
      },
      backgroundColor: theme.palette.background.imgBackground,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    img: {
      objectFit: "cover",
      width: "114px",
      height: "114px",
      [theme.breakpoints.down("sm")]: {
        width: "88px",
        height: "84px",
      },
    },
    boxStyle: {
      display: "-webkit-box",
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textTransform: "capitalize",
    },
    priceStyle: {
      marginBottom: "4px",
    },
  }),
);

export interface BannersProps {
  item: any;
  removedLinkedIdList: any;
  handleUpdateIdList: any;
  index: any;
  isColorChange: any;
  data: any;
  revertExpand: any;
  handleOpenMappingModal: any;
  category: any;
}

/* Get selected menu information, category menu details and location information using API call at initial load. */
const CategoryMenuList: React.FunctionComponent<BannersProps> = ({
  item,
  removedLinkedIdList,
  handleUpdateIdList,
  index,
  isColorChange,
  data,
  revertExpand,
  handleOpenMappingModal,
  category,
}) => {
  const [color, setColor] = useState("");
  const matchesLargeScreen = useMediaQuery("(max-width: 960px)");
  const [rotation, setRotation] = useState(matchesLargeScreen ? 2 : 3);

  /* Change the pixel size of image */
  const handleImageUrl = (url: any) => {
    // If 'imgUrl' has 'cloudinary', that 'imgUrl' edit.
    if (url.includes("cloudinary")) {
      const urlSplit = url.split("upload");
      const inputString = urlSplit[0] + "upload/c_fill,g_center,h_240,w_320";
      const mergeUrl = inputString + urlSplit[1];
      return mergeUrl;
    } else {
      // If 'imgUrl' has not 'cloudinary', that 'imgUrl' does not edit.
      return url;
    }
  };

  const hexToRgb = (hex: any) => {
    // Remove the hash sign if it exists
    hex = hex.replace("#", "");

    // Parse the hex value into individual RGB components
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Return the RGB value in the format suitable for rgba() function
    return [r, g, b];
  };

  useEffect(() => {
    const startTime = performance.now();
    const initialRotation = matchesLargeScreen ? 2 : 3; // Initial rotation value

    function rotateElement(timestamp: any) {
      const progress = timestamp - startTime;
      const duration = data.length * 100; // 3 seconds
      const rotationStep =
        initialRotation - (initialRotation / duration) * progress;
      setRotation(rotationStep);

      if (progress < duration) {
        requestAnimationFrame(rotateElement);
      }
    }

    requestAnimationFrame(rotateElement);

    return () => {
      // Cleanup if component unmounts before animation completes
      setRotation(matchesLargeScreen ? 2 : 3);
    };
  }, [isColorChange]);

  useEffect(() => {
    let startTime: any;
    const duration = data.length * 400; // 3 seconds
    const startColor = hexToRgb(
      theme.palette.background.menu_entity_background,
    ); // RGB values for #282C32
    const endColor = hexToRgb(theme.palette.background.entity_background); // RGB values for #32363E

    function changeColor(timestamp: any) {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      const newColor = startColor.map((startValue, index) => {
        const endValue = endColor[index];
        const difference = endValue - startValue;
        return startValue + (progress / duration) * difference;
      });
      setColor(`rgb(${newColor[0]}, ${newColor[1]}, ${newColor[2]})`);

      if (progress < duration) {
        requestAnimationFrame(changeColor);
      }
    }
    requestAnimationFrame(changeColor);

    const timeoutId = setTimeout(() => {
      setColor(""); // Set empty color after 3 seconds
    }, 2000);

    return () => {
      // Cleanup if component unmounts before animation completes
      clearTimeout(timeoutId);
      if (!_.isEmpty(color)) {
        setColor("");
      }
    };
  }, [isColorChange]);
  const isDarkMode = getLocalStore("isDarkMode");

  useEffect(() => {
    if (!_.isEmpty(color)) {
      setColor("");
    }
  }, [isDarkMode]);
  const theme: CustomTheme = useTheme();
  const classes = useStyles();
  return (
    <Slide
      in={true}
      direction="right"
      mountOnEnter
      unmountOnExit
      timeout={300 * index}
    >
      <div
        style={{ overflow: "hidden", zIndex: 0 }}
        onClick={(event) =>
          handleOpenMappingModal(event, category, item, "item")
        }
      >
        <Card
          style={{
            overflowY: "hidden",
            cursor: "pointer",
            borderRadius: "10px",
            border: `1px solid ${theme.palette.background.entity_border}`,
            boxShadow: "none",
            backgroundColor:
              item.valid === false
                ? theme.palette.custom.red.main
                : revertExpand === `${item.departmentId}*${item.linkedId}` &&
                  index === 0 &&
                  color
                ? color
                : theme.palette.background.entity_background,
            transform:
              revertExpand === `${item.departmentId}*${item.linkedId}` &&
              index === 0
                ? `rotate(${rotation < 0 ? 0 : rotation}deg)`
                : "rotate(0deg)",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={9}
              sm={10}
              md={10}
              lg={10}
              xl={10}
              style={{ display: "flex" }}
            >
              {item?.img ? (
                <Box className={classes.imgBoxStyle}>
                  <img
                    className={classes.img}
                    src={handleImageUrl(item.img)}
                    alt="not available images"
                    style={{
                      borderRadius: "10px",
                      border: `1px solid ${theme.palette.background.entity_border}`,
                    }}
                  />
                </Box>
              ) : (
                <Box className={classes.imgBoxNotAvailableStyle}>
                  <ImageOutlinedIcon style={{ fontSize: "52px" }} />
                </Box>
              )}
              <Grid
                item
                xs
                container
                direction="column"
                style={{ paddingLeft: "8px", paddingTop: "12px" }}
              >
                <Grid item xs>
                  <Typography
                    style={
                      item.valid === false
                        ? { fontWeight: "bold", color: "white" }
                        : { fontWeight: "bold" }
                    }
                    align="left"
                    className={classes.titleStyle}
                  >
                    {item?.name}
                  </Typography>
                  <Typography
                    className={classes.boxStyle}
                    style={
                      item.valid === false
                        ? { textTransform: "lowercase", color: "white" }
                        : { textTransform: "lowercase" }
                    }
                    variant="body2"
                    align="left"
                  >
                    {item?.desc}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {removedLinkedIdList.includes(
              `${item.departmentId}*${item.linkedId}`,
            ) && (
              <Grid
                item
                xs={3}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                }}
              >
                <Typography
                  variant="caption"
                  align="right"
                  className={classes.priceStyle}
                >
                  <Button
                    onClick={(event) => handleUpdateIdList(event, item)}
                    style={{ zIndex: 20 }}
                  >
                    <span
                      className="material-symbols-outlined"
                      style={
                        item.valid === false
                          ? { color: "white" }
                          : { textTransform: "lowercase" }
                      }
                    >
                      left_panel_close
                    </span>
                  </Button>
                </Typography>
              </Grid>
            )}
          </Grid>
        </Card>
      </div>
    </Slide>
  );
};

export default CategoryMenuList;
