import React from "react";
import { Route, Switch } from "react-router-dom";
import CorePage from "../pages/CorePage";

const TasksAppRoutes: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route path="/:locationId/inventory/order/:orderId" component={CorePage} exact />
    </Switch>
  );
};

export default TasksAppRoutes;
