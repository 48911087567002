import React, { useEffect, useState } from "react";
import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { useRouteMatch } from "react-router";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { Box, useTheme } from "@material-ui/core";
import _ from "lodash";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import { getCookie } from "../../../../utils/cookies";
import { paymentFilterObject } from "../../../../utils/consts/list";
import { CustomTheme } from "../../../../types/customTheme";
import SaleReport from "../../../../components/common/SaleReport";
import moment from "moment";
import DailySaleReportInfoNode from "./DailySaleReportInfoNode";
import {
  fetchAllHardwareReportInfo,
  fetchAllRevenueCenterInfo,
} from "../../../../services/salesApp/revenueCenterReport";

export interface paymentReportProps {}

type RevenueCenterReport = {
  orders: number;
  subTotal: number;
  discount: number;
  total: number;
  cash: number;
  card: number;
  otherPayment: number;
  average: number;
  min: number;
  max: number;
};

/* Get the daily sale details using API call and get the location information */
const RevenueCenterReport: React.FunctionComponent<paymentReportProps> = () => {
  const [hardwareInformationNodeList, setHardwareInformationNodeList] =
    useState<any>([]);
  const [error, setError] = useState("");
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [filterDetails, setFilterDetails] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterData] = useState("");
  const [basedOnShift, setBasedOnShift] = useState(false);
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [locationSelectedList, setLocationSelectedList] = useState([]);
  const [revenueDetailsObj, setRevenueDetailsObj] = useState<any>({});

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");

  const handleGetHardwareInformation = async () => {
    try {
      // This function calls the fetchAllDailySaleReportInfo API to retrieve the data
      const res = await fetchAllHardwareReportInfo(match.params.locationId);

      setHardwareInformationNodeList(res.data.data);
      // The setIsLoading state is set to false to stop the loading spinner
    } catch (err) {
      setIsLoading(false);
      // If an error occurs, the setIsLoading state is set to false and the setError state is set to an error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleGetRevenueInformation = async (
    filter: any,
    basedOnShift: boolean,
  ) => {
    try {
      // This function calls the fetchAllDailySaleReportInfo API to retrieve the data
      const res = await fetchAllRevenueCenterInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );
      setRevenueDetailsObj(res.data);
      setIsLoading(false);
    } catch (err) {
      // If an error occurs, the setIsLoading state is set to false and the setError state is set to an error message
      setIsLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /** A function that fetches all filter locations based on searchName and updates the state
   * of locationList, locationData, locationSelectorList and isGetLocationInfo based on the response
   * */
  const getAllFilterLocation = async (searchName: any) => {
    fetchAllFilterLocations(searchName)
      .then((res) => {
        let locationList: any = [];
        /* Setting up the list of locations as needed to select locations. */
        if (!_.isEmpty(res.data.data)) {
          res.data.data.map((location: any) => {
            locationList.push({
              id: location.id,
              label: location.businessDisplayName,
            });
          });
        }

        setLocationSelectorList(locationList);
        setIsGetLocationInfo(true);
      })
      .catch(() => {
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };

  useEffect(() => {
    document.title = "Sale - Revenue Center Report";
    getAllFilterLocation("");
    handleGetHardwareInformation();
  }, []);

  // Get filter data
  const handleFilterData = (filterData: any, basedOnShift: any) => {
    setIsLoading(true);
    handleGetRevenueInformation(filterData, basedOnShift);

    setFilterData(filterData);
    setBasedOnShift(basedOnShift);
  };

  const handleOnClickText = () => {
    setOpenFilterCard(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /*Get location list API call after typing. */
  const handleLocationSelectorTypingList = (searchName: any) => {};

  const theme: CustomTheme = useTheme();

  return (
    <>
      <Box>
        <SaleReport
          handleFilterData={handleFilterData}
          locationSelectorList={locationSelectorList}
          handleLocationSelectorTypingList={handleLocationSelectorTypingList}
          isGetLocationInfo={isGetLocationInfo}
          setFilterDetails={setFilterDetails}
          availableFilter={paymentFilterObject}
          isOpenSkeletonLoading={isLoading}
          nodeList={hardwareInformationNodeList}
          filterDetails={filterDetails}
          title={""}
          isChart={false}
          filterTitle="Revenue Center summary report for"
          topic="Revenue Center Report"
          isNeedFilterDetails={true}
          isDailySale={true}
          locationSelectedList={locationSelectedList}
          setLocationSelectedList={setLocationSelectedList}
          openFilterCard={openFilterCard}
          setOpenFilterCard={setOpenFilterCard}
          handleOnClickText={handleOnClickText}
          isReport={true}
        >
          <DailySaleReportInfoNode
            filterDetails={filterDetails}
            hardwareInformationNodeList={hardwareInformationNodeList}
            locationSelectedList={locationSelectedList}
            handleOnClickText={handleOnClickText}
            revenueDetailsObj={revenueDetailsObj}
          />
        </SaleReport>

        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>
    </>
  );
};

export default withAuthority(RevenueCenterReport, Authorities.SALE_READ);
