import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Table,
  Body,
  Row,
  Cell,
} from "@table-library/react-table-library/table";
import { useLocation } from "react-router";
import GetAppIcon from "@material-ui/icons/GetApp";
import SearchIcon from "@material-ui/icons/Search";
import {
  createStyles,
  Grid,
  InputAdornment,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { CustomTheme } from "../../../../types/customTheme";
import "jspdf-autotable";
import CardCommon from "../../../../components/card/CardCommon";
import MenuButtonCommon from "../../../../components/buttons/MenuButtonCommon";
import { handleDownloadPdf } from "./convertPdfExcel/convertDataToPdf";
import moment from "moment";
import LocationDetailsCard from "../../../../components/common/LocationDetailsCard";
import TextfieldCommon from "../../../../components/textField/TextfieldCommon";
import NoSalesFound from "../../../../components/common/NoSalesFound";
import { handleExcel } from "./convertPdfExcel/handleExcel";
import {
  handleStockLevel,
  handleStockLevelPdfAndExcel,
} from "../../../../utils/calculateStockLevel";

export interface VoidSummeryInfoNodeProps {
  dailySaleReportNodeList: any;
  filterDetails: any;
  locationSelectedList: any;
  handleOnClickText: any;
  stockItemGroupDepartmentNodeInSelector: any;
  searchName: any;
  handleChangeSearch: any;
}

const useStyles = (bodyLength: number) =>
  makeStyles((theme: CustomTheme) =>
    createStyles({
      startTextStyle: {
        fontWeight: "bold",
        display: "flex",
        justifyContent: "start",
        paddingLeft: "12px",
        fontSize: "12px",
      },
      textStyle: {
        display: "flex",
        justifyContent: "start",
        fontSize: "12px",
      },
      textStyleEnd: {
        display: "flex",
        justifyContent: "end",
        fontSize: "12px",
        paddingRight: "12px",
      },
      headerRow: {
        fontSize: "13px",
        cursor: "pointer",
        "&.css-16gtl2w": {
          display: "flex",
          justifyContent: "start",
        },
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.background.paper,
        },
        "&:nth-of-type(even)": {
          backgroundColor: theme.palette.background.entity_background,
        },
      },
      baseCellRow: {
        placeItems: "start",
        "& .css-16gtl2w": {
          fontWeight: "bold",
        },
        height: "40px",
        [theme.breakpoints.down("xl")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+10)`]: {
            minWidth: "10%",
            width: "10%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "start",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(n+10)`]: {
            minWidth: "10%",
            width: "10%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "10%",
            width: "10%",
          },
        },
        [theme.breakpoints.down("lg")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+10)`]: {
            minWidth: `${85 / 9}%`,
            width: `${85 / 9}%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "start",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(n+10)`]: {
            minWidth: `${85 / 9}%`,
            width: `${85 / 9}%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "8px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "15%",
            width: "15%",
          },
        },

        [theme.breakpoints.down("md")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+10)`]: {
            minWidth: "13%",
            width: "13%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "start",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(n+10)`]: {
            minWidth: "13%",
            width: "13%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "8px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "16%",
            width: "16%",
          },
        },
        [theme.breakpoints.down("sm")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+10)`]: {
            minWidth: "16%",
            width: "16%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "start",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(n+10)`]: {
            minWidth: "16%",
            width: "16%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "8px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "20%",
            width: "20%",
          },
        },
        [theme.breakpoints.down("xs")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+10)`]: {
            minWidth: "24vw",
            width: "24vw",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "start",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(n+10)`]: {
            minWidth: "24vw",
            width: "24vw",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "8px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "26vw",
            width: "26vw",
          },
        },
      },
      baseCellRowGroupColor: {
        placeItems: "start",
        "& .css-16gtl2w": {
          display: "flex",
          justifyContent: "start",
          fontWeight: "bold",
        },

        height: "40px",
        "&.td:nth-of-type(n)": {
          color: theme.palette.custom["green"].contrastText,
          backgroundColor: theme.palette.custom.green.main,
        },
        "&:nth-of-type(1)": {
          left: "0px",
          minWidth: "100%",
          width: "100%",
        },
      },
      baseCellRowDepartmentColor: {
        placeItems: "start",
        "& .css-16gtl2w": {
          fontWeight: "bold",
        },
        height: "40px",
        "&.td:nth-of-type(n)": {
          backgroundColor: theme.palette.custom.yellow.main,
        },
        [theme.breakpoints.down("xl")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+10)`]: {
            minWidth: "10%",
            width: "10%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "start",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(n+10)`]: {
            minWidth: "10%",
            width: "10%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "10%",
            width: "10%",
          },
        },
        [theme.breakpoints.down("lg")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+10)`]: {
            minWidth: `${85 / 9}%`,
            width: `${85 / 9}%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "start",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(n+10)`]: {
            minWidth: `${85 / 9}%`,
            width: `${85 / 9}%`,
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "8px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "15%",
            width: "15%",
          },
        },

        [theme.breakpoints.down("md")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+10)`]: {
            minWidth: "13%",
            width: "13%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "start",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(n+10)`]: {
            minWidth: "13%",
            width: "13%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "8px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "16%",
            width: "16%",
          },
        },
        [theme.breakpoints.down("sm")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+10)`]: {
            minWidth: "16%",
            width: "16%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "start",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(n+10)`]: {
            minWidth: "16%",
            width: "16%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "8px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "20%",
            width: "20%",
          },
        },
        [theme.breakpoints.down("xs")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+10)`]: {
            minWidth: "24vw",
            width: "24vw",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "start",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(n+10)`]: {
            minWidth: "24vw",
            width: "24vw",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "8px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "26vw",
            width: "26vw",
          },
        },
      },
    }),
  );

/* Creation of 'daily sale' table, and conversion of 'daily sale' table to PDF and Excel. */
const DailySaleReportInfoNode: React.FunctionComponent<
  VoidSummeryInfoNodeProps
  // eslint-disable-next-line react/function-component-definition
> = ({
  dailySaleReportNodeList,
  filterDetails,
  locationSelectedList,
  handleOnClickText,
  stockItemGroupDepartmentNodeInSelector,
  searchName,
  handleChangeSearch,
}) => {
  const [pdfHeaders, setPdfHeaders] = useState<any>([]);
  const [headersList, setHeadersList] = useState<any>([]);
  const [excelHeaders, setExcelHeaders] = useState<any>([]);
  const [nodesNew, setNodesNew] = useState<any>();
  const [nodesPdfExcel, setNodesPdfExcel] = useState<any>([]);
  const [bodyLength, setBodyLength] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const maxWidth = useMediaQuery("(max-width: 1700px)");
  const maxWidthCustom = useMediaQuery("(max-width: 2000px)");
  const open = Boolean(anchorEl);
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const theme: CustomTheme = useTheme();

  useEffect(() => {
    setNodesNew([]);
  }, [params.get("startDate")]);

  const convertStringNumber = (input: any) => {
    const number = parseFloat(input);
    if (!isNaN(number)) {
      if (Number.isInteger(number)) {
        return number.toString();
      } else {
        return number.toFixed(2);
      }
    }
    return input; // Return the input as is if it's not a valid number.
  };

  /* Creating the data for the Payment report table and creating the data for pdf and excel files.
       Using a API request */
  useEffect(() => {
    setNodesNew([]);
    if (
      !_.isEmpty(stockItemGroupDepartmentNodeInSelector) &&
      !_.isEmpty(dailySaleReportNodeList)
    ) {
      const convertedData: any = [];
      const convertedPdfExcelData: any = [];
      stockItemGroupDepartmentNodeInSelector.forEach((data: any) => {
        if (!_.isEmpty(data) && !_.isEmpty(data.stockDepartmentId)) {
          convertedData.push({
            isGroup: true,
            isDepartment: false,
            department: data.name,
          });
          convertedPdfExcelData.push({
            isGroup: true,
            isDepartment: false,
            department: data.name,
          });
          data.stockDepartmentId.forEach((data1: any) => {
            const datas: any = [];
            const pdfData: any = [];
            datas.push({
              isGroup: false,
              isDepartment: true,
              department: data1.name,
              item: "Item",
              unit: "Unit",
              startStock: "Start Stock",
              sold: "Sold",
              waste: "Waste",
              returned: "Returned",
              deliveredStock: "Delivered Stock",
              adjustment: "Adjustment",
              endStock: "End Stock",
            });
            pdfData.push({
              isGroup: false,
              isDepartment: true,
              department: data1.name,
              item: "Item",
              unit: "Unit",
              startStock: "Start Stock",
              sold: "Sold",
              waste: "Waste",
              returned: "Returned",
              deliveredStock: "Delivered Stock",
              adjustment: "Adjustment",
              endStock: "End Stock",
            });
            data1.item.forEach((data2: any) => {
              const { displaySuffix } = data2;
              const {
                start = null,
                sale = null,
                waste = null,
                cancel = null,
                order = null,
                end = null,
                adjustment = null,
                delivery = null,
              } = dailySaleReportNodeList[data2.id]?.data || {};
              datas.push({
                isGroup: false,
                isDepartment: false,
                department: data1.name,
                item: data2.name,
                unit: data2.displaySuffix,
                startStock:
                  dailySaleReportNodeList[data2.id] && start !== "-"
                    ? handleStockLevel(
                        data2.autoUnitConvert,
                        data2.showRemainderSep,
                        data2.inputMultiplier,
                        start,
                        displaySuffix,
                        data2.inputSuffix,
                      )
                    : "0",
                sold:
                  dailySaleReportNodeList[data2?.id] &&
                  sale &&
                  sale !== "0" &&
                  sale !== "-"
                    ? handleStockLevel(
                        data2.autoUnitConvert,
                        data2.showRemainderSep,
                        data2.inputMultiplier,
                        sale,
                        displaySuffix,
                        data2.inputSuffix,
                      )
                    : "",
                waste:
                  dailySaleReportNodeList[data2?.id] &&
                  waste &&
                  waste !== "0" &&
                  waste !== "-"
                    ? handleStockLevel(
                        data2.autoUnitConvert,
                        data2.showRemainderSep,
                        data2.inputMultiplier,
                        waste,
                        data2.displaySuffix,
                        data2.inputSuffix,
                      )
                    : "",
                returned:
                  dailySaleReportNodeList[data2?.id] &&
                  cancel &&
                  cancel !== "0" &&
                  cancel !== "-"
                    ? handleStockLevel(
                        data2.autoUnitConvert,
                        data2.showRemainderSep,
                        data2.inputMultiplier,
                        cancel,
                        data2.displaySuffix,
                        data2.inputSuffix,
                      )
                    : "",
                deliveredStock:
                  dailySaleReportNodeList[data2?.id] &&
                  delivery &&
                  delivery !== "0" &&
                  delivery !== "-"
                    ? handleStockLevel(
                        data2.autoUnitConvert,
                        data2.showRemainderSep,
                        data2.inputMultiplier,
                        delivery,
                        data2.displaySuffix,
                        data2.inputSuffix,
                      )
                    : "",
                adjustment:
                  dailySaleReportNodeList[data2?.id] &&
                  adjustment &&
                  adjustment !== "0" &&
                  adjustment !== "-"
                    ? handleStockLevel(
                        data2.autoUnitConvert,
                        data2.showRemainderSep,
                        data2.inputMultiplier,
                        adjustment,
                        data2.displaySuffix,
                        data2.inputSuffix,
                      )
                    : "",
                endStock:
                  dailySaleReportNodeList[data2.id] && end !== "-"
                    ? handleStockLevel(
                        data2.autoUnitConvert,
                        data2.showRemainderSep,
                        data2.inputMultiplier,
                        end,
                        data2.displaySuffix,
                        data2.inputSuffix,
                      )
                    : "0",
              });
              pdfData.push({
                isGroup: false,
                isDepartment: false,
                department: data1.name,
                item: data2.name,
                unit: data2.displaySuffix,
                startStock:
                  dailySaleReportNodeList[data2.id] && start !== "-"
                    ? handleStockLevelPdfAndExcel(
                        data2.autoUnitConvert,
                        data2.showRemainderSep,
                        data2.inputMultiplier,
                        start,
                        displaySuffix,
                        data2.inputSuffix,
                      )
                    : "0",
                sold:
                  dailySaleReportNodeList[data2?.id] &&
                  sale &&
                  sale !== "0" &&
                  sale !== "-"
                    ? handleStockLevelPdfAndExcel(
                        data2.autoUnitConvert,
                        data2.showRemainderSep,
                        data2.inputMultiplier,
                        sale,
                        displaySuffix,
                        data2.inputSuffix,
                      )
                    : "",
                waste:
                  dailySaleReportNodeList[data2?.id] &&
                  waste &&
                  waste !== "0" &&
                  waste !== "-"
                    ? handleStockLevelPdfAndExcel(
                        data2.autoUnitConvert,
                        data2.showRemainderSep,
                        data2.inputMultiplier,
                        waste,
                        data2.displaySuffix,
                        data2.inputSuffix,
                      )
                    : "",
                returned:
                  dailySaleReportNodeList[data2?.id] &&
                  cancel &&
                  cancel !== "0" &&
                  cancel !== "-"
                    ? handleStockLevelPdfAndExcel(
                        data2.autoUnitConvert,
                        data2.showRemainderSep,
                        data2.inputMultiplier,
                        cancel,
                        data2.displaySuffix,
                        data2.inputSuffix,
                      )
                    : "",
                deliveredStock:
                  dailySaleReportNodeList[data2?.id] &&
                  delivery &&
                  delivery !== "0" &&
                  delivery !== "-"
                    ? handleStockLevelPdfAndExcel(
                        data2.autoUnitConvert,
                        data2.showRemainderSep,
                        data2.inputMultiplier,
                        delivery,
                        data2.displaySuffix,
                        data2.inputSuffix,
                      )
                    : "",
                adjustment:
                  dailySaleReportNodeList[data2?.id] &&
                  adjustment &&
                  adjustment !== "0" &&
                  adjustment !== "-"
                    ? handleStockLevelPdfAndExcel(
                        data2.autoUnitConvert,
                        data2.showRemainderSep,
                        data2.inputMultiplier,
                        adjustment,
                        data2.displaySuffix,
                        data2.inputSuffix,
                      )
                    : "",
                endStock:
                  dailySaleReportNodeList[data2.id] && end !== "-"
                    ? handleStockLevelPdfAndExcel(
                        data2.autoUnitConvert,
                        data2.showRemainderSep,
                        data2.inputMultiplier,
                        end,
                        data2.displaySuffix,
                        data2.inputSuffix,
                      )
                    : "0",
              });
            });

            convertedData.push({ datas });
            convertedPdfExcelData.push({ pdfData });
          });
        }
      });

      // Set pdf headers
      setPdfHeaders([
        { title: "Department", field: "department" },
        { title: "Item", field: "item" },
        { title: "Unit", field: "unit" },
        { title: "Start Stock", field: "startStock" },
        { title: "Sold", field: "sold" },
        { title: "Waste", field: "waste" },
        { title: "Returned", field: "returned" },
        { title: "Delivered Stock", field: "deliveredStock" },
        { title: "Adjustment", field: "adjustment" },
        { title: "End Stock", field: "endStock" },
      ]);

      // Set excel headers
      setExcelHeaders([
        { header: "Department", key: "department", width: 32, height: 68 },
        { header: "Item", key: "item", width: 32, height: 68 },
        { header: "Unit", key: "unit", width: 32, height: 68 },
        { header: "Start Stock", key: "startStock", width: 32, height: 68 },
        { header: "Sold", key: "sold", width: 32, height: 68 },
        { header: "Waste", key: "waste", width: 32, height: 68 },
        {
          header: "Returned",
          key: "returned",
          width: 32,
          height: 68,
        },
        {
          header: "Delivered Stock",
          key: "deliveredStock",
          width: 32,
          height: 68,
        },
        { header: "Adjustment", key: "adjustment", width: 32, height: 68 },
        { header: "End Stock", key: "endStock", width: 32, height: 68 },
      ]);

      // Set table headers
      setHeadersList([
        "IsGroup",
        "IsDepartment",
        "Department",
        "Item",
        "Unit",
        "Start Stock",
        "Sold",
        "Waste",
        "Returned",
        "Delivered Stock",
        "Adjustment",
        "End Stock",
      ]);

      // set the pdf excel data in the state
      setNodesPdfExcel(convertedPdfExcelData);

      setTimeout(() => {
        setNodesNew(convertedData);
      }, 0);
    } else {
      setNodesNew([]);
    }
  }, [stockItemGroupDepartmentNodeInSelector, dailySaleReportNodeList]);

  /* Open PDF / Excel selection menu */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /* Close PDF / Excel selection menu */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes: any = useStyles(bodyLength)();

  const handleCellClass = (item: any) => {
    if (item.isGroup) {
      return classes.baseCellRowGroupColor;
    } else if (item.isDepartment) {
      return classes.baseCellRowDepartmentColor;
    } else {
      return classes.baseCellRow;
    }
  };

  const handleTableItem = (data: any) => {
    return (
      <CardCommon>
        <Table
          data={{ nodes: data }}
          layout={{ custom: maxWidthCustom, horizontalScroll: maxWidth }}
          style={{ zIndex: 0 }}
        >
          {(tableList) => (
            <>
              <Body>
                {tableList.map((item, bodyIndex) =>
                  item.isDepartment ? (
                    <Row
                      key={item.group}
                      item={item}
                      className={classes.headerRow}
                    >
                      <Cell pinLeft className={handleCellClass(item)}>
                        <Typography
                          variant="caption"
                          align="left"
                          className={classes.startTextStyle}
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                        >
                          {item.department}
                        </Typography>
                      </Cell>
                      <Cell className={handleCellClass(item)}>
                        <Typography
                          variant="caption"
                          align="left"
                          className={classes.textStyle}
                          style={{ color: "black", fontWeight: "bold" }}
                        >
                          {item.item}
                        </Typography>
                      </Cell>
                      <Cell className={handleCellClass(item)}>
                        <Typography
                          variant="caption"
                          align="left"
                          className={classes.textStyle}
                          style={{ color: "black", fontWeight: "bold" }}
                        >
                          {item.unit}
                        </Typography>
                      </Cell>
                      <Cell className={handleCellClass(item)}>
                        <Typography
                          variant="caption"
                          align="left"
                          className={classes.textStyle}
                          style={{ color: "black", fontWeight: "bold" }}
                        >
                          {item.startStock}
                        </Typography>
                      </Cell>
                      <Cell className={handleCellClass(item)}>
                        <Typography
                          variant="caption"
                          align="left"
                          className={classes.textStyle}
                          style={{ color: "black", fontWeight: "bold" }}
                        >
                          {item.sold}
                        </Typography>
                      </Cell>

                      <Cell className={handleCellClass(item)}>
                        <Typography
                          variant="caption"
                          align="left"
                          className={classes.textStyle}
                          style={{ color: "black", fontWeight: "bold" }}
                        >
                          {item.waste}
                        </Typography>
                      </Cell>
                      <Cell className={handleCellClass(item)}>
                        <Typography
                          variant="caption"
                          align="left"
                          className={classes.textStyle}
                          style={{ color: "black", fontWeight: "bold" }}
                        >
                          {item.returned}
                        </Typography>
                      </Cell>
                      <Cell className={handleCellClass(item)}>
                        <Typography
                          variant="caption"
                          align="left"
                          className={classes.textStyle}
                          style={{ color: "black", fontWeight: "bold" }}
                        >
                          {item.deliveredStock}
                        </Typography>
                      </Cell>

                      <Cell className={handleCellClass(item)}>
                        <Typography
                          variant="caption"
                          className={classes.textStyle}
                          style={{ color: "black", fontWeight: "bold" }}
                          align="left"
                        >
                          {item.adjustment}
                        </Typography>
                      </Cell>
                      <Cell className={handleCellClass(item)}>
                        <Typography
                          variant="caption"
                          className={classes.textStyleEnd}
                          style={{ color: "black", fontWeight: "bold" }}
                          align="left"
                        >
                          {item.endStock}
                        </Typography>
                      </Cell>
                    </Row>
                  ) : (
                    <Row
                      key={item.group}
                      item={item}
                      className={classes.headerRow}
                    >
                      <Cell pinLeft className={handleCellClass(item)}>
                        <Typography
                          variant="caption"
                          align="left"
                          className={classes.startTextStyle}
                        >
                          {item.department}
                        </Typography>
                      </Cell>
                      <Cell className={handleCellClass(item)}>
                        <Typography
                          variant="caption"
                          align="left"
                          className={classes.textStyle}
                        >
                          {item.item}
                        </Typography>
                      </Cell>
                      <Cell className={handleCellClass(item)}>
                        <Typography
                          variant="caption"
                          align="left"
                          className={classes.textStyle}
                        >
                          {item.unit}
                        </Typography>
                      </Cell>
                      <Cell className={handleCellClass(item)}>
                        <Typography
                          variant="caption"
                          align="left"
                          className={classes.textStyle}
                        >
                          {handleData(item.startStock)}
                        </Typography>
                      </Cell>
                      {item.isGroup || item.isDepartment ? (
                        <Cell className={handleCellClass(item)}>
                          <Typography
                            variant="caption"
                            align="left"
                            className={classes.textStyle}
                          >
                            {handleData(item.sold)}
                          </Typography>
                        </Cell>
                      ) : (
                        <Cell className={handleCellClass(item)}>
                          <Typography
                            variant="caption"
                            align="left"
                            className={classes.textStyle}
                            // style={{
                            //   color: theme.palette.custom.red.main,
                            //   fontWeight: "bold",
                            // }}
                          >
                            {handleData(item.sold)}
                          </Typography>
                        </Cell>
                      )}
                      {item.isGroup || item.isDepartment ? (
                        <Cell className={handleCellClass(item)}>
                          <Typography
                            variant="caption"
                            align="left"
                            className={classes.textStyle}
                          >
                            {handleData(item.waste)}
                          </Typography>
                        </Cell>
                      ) : (
                        <Cell className={handleCellClass(item)}>
                          <Typography
                            variant="caption"
                            align="left"
                            className={classes.textStyle}
                            // style={{
                            //   color: theme.palette.custom.red.main,
                            //   fontWeight: "bold",
                            // }}
                          >
                            {handleData(item.waste)}
                          </Typography>
                        </Cell>
                      )}
                      {item.isGroup ||
                      item.isDepartment ||
                      item.returned.toString() === "0" ||
                      item.returned.toString() === "0 " ? (
                        <Cell className={handleCellClass(item)}>
                          <Typography
                            variant="caption"
                            align="left"
                            className={classes.textStyle}
                          >
                            {handleData(item.returned)}
                          </Typography>
                        </Cell>
                      ) : (
                        <Cell className={handleCellClass(item)}>
                          <Typography
                            variant="caption"
                            align="left"
                            className={classes.textStyle}
                            // style={{
                            //   color: theme.palette.custom.green.main,
                            //   fontWeight: "bold",
                            // }}
                          >
                            {handleData(item.returned)}
                          </Typography>
                        </Cell>
                      )}
                      {item.isGroup || item.isDepartment ? (
                        <Cell className={handleCellClass(item)}>
                          <Typography
                            variant="caption"
                            align="left"
                            className={classes.textStyle}
                          >
                            {handleData(item.deliveredStock)}
                          </Typography>
                        </Cell>
                      ) : (
                        <Cell className={handleCellClass(item)}>
                          <Typography
                            variant="caption"
                            align="left"
                            className={classes.textStyle}
                            // style={{
                            //   color: theme.palette.custom.green.main,
                            //   fontWeight: "bold",
                            // }}
                          >
                            {handleData(item.deliveredStock)}
                          </Typography>
                        </Cell>
                      )}
                      <Cell className={handleCellClass(item)}>
                        <Typography
                          variant="caption"
                          className={classes.textStyle}
                          align="left"
                        >
                          {handleData(item.adjustment)}
                        </Typography>
                      </Cell>
                      <Cell className={handleCellClass(item)}>
                        <Typography
                          variant="caption"
                          className={classes.textStyleEnd}
                          align="left"
                        >
                          {handleData(item.endStock)}
                        </Typography>
                      </Cell>
                    </Row>
                  ),
                )}
              </Body>
            </>
          )}
        </Table>
      </CardCommon>
    );
  };

  const handleData = (data: any) => {
    if (data) {
      if (Object.keys(data).length === 1) {
        return data[0];
      } else if (Object.keys(data).length === 2) {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                marginRight: "8px",
                fontWeight: "bold",
                fontSize: "16px",
                marginBottom: "1px",
              }}
            >
              <div style={{ color: theme.palette.secondary.main }}>
                {data[0]}
              </div>
            </div>
            {data[1]}
          </div>
        );
      } else if (Object.keys(data).length === 4) {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                marginRight: "8px",
                fontWeight: "bold",
                fontSize: "16px",
                marginBottom: "1px",
              }}
            >
              <div style={{ color: theme.palette.secondary.main }}>
                {data[0]}
              </div>
            </div>
            {data[1]}
            <div
              style={{
                marginRight: "8px",
                marginLeft: "8px",
                fontWeight: "bold",
                fontSize: "16px",
                marginBottom: "1px",
              }}
            >
              <div style={{ color: theme.palette.secondary.main }}>
                {data[2]}
              </div>
            </div>
            {data[3]}
          </div>
        );
      }
    } else {
      return data;
    }
  };
  const handleTable = (data: any) => {
    return (
      <CardCommon>
        <Table
          data={{ nodes: [data] }}
          layout={{ custom: maxWidthCustom, horizontalScroll: maxWidth }}
          style={{ zIndex: 0 }}
        >
          {(tableList) => (
            <>
              <Body>
                {tableList.map((item, bodyIndex) => (
                  <Row
                    key={item.group}
                    item={item}
                    className={classes.headerRow}
                  >
                    <Cell pinLeft className={handleCellClass(item)}>
                      <Typography
                        variant="caption"
                        align="left"
                        className={classes.startTextStyle}
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        {item.department}
                      </Typography>
                    </Cell>
                  </Row>
                ))}
              </Body>
            </>
          )}
        </Table>
      </CardCommon>
    );
  };

  const maxWidthLocationChips = useMediaQuery("(max-width: 600px)");

  return (
    <>
      <Grid container spacing={2} style={{ marginTop: "8px" }}>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
          }}
        >
          <div>
            <LocationDetailsCard
              locationSelectedList={locationSelectedList}
              handleOnClickText={handleOnClickText}
            />
          </div>
        </Grid>
      </Grid>
      <div
        style={
          maxWidthLocationChips
            ? { display: "block", marginTop: "8px", marginBottom: "20px" }
            : {
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }
        }
      >
        <TextfieldCommon
          id="search"
          name="search"
          label="Search"
          type="text"
          style={maxWidthLocationChips ? { width: "100%" } : { width: "240px" }}
          value={searchName}
          onChange={(e: any) => handleChangeSearch(e.target.value)}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <div
          style={
            maxWidthLocationChips
              ? {
                  marginBottom: "12px",
                  marginTop: "8px",
                }
              : {
                  marginTop: "8px",
                }
          }
        >
          <MenuButtonCommon
            id="basic-button"
            variant="contained"
            style={{
              fontSize: 11,
              height: "38px",
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            disabled={_.isEmpty(stockItemGroupDepartmentNodeInSelector)}
          >
            <GetAppIcon />
            <Typography
              variant="body1"
              style={{ paddingLeft: "16px", color: "white" }}
            >
              Export
            </Typography>
          </MenuButtonCommon>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            PaperProps={{
              elevation: 0,
              style: {
                borderRadius: "10px",
                border: `1px solid ${theme.palette.background.entity_border}`,
              },
            }}
            style={{ top: "56px" }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              onClick={() => {
                handleDownloadPdf(filterDetails, pdfHeaders, nodesPdfExcel);
                handleClose();
              }}
            >
              Export as PDF
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleExcel(excelHeaders, nodesPdfExcel, filterDetails);
                handleClose();
              }}
            >
              Export as CSV
            </MenuItem>
          </Menu>
        </div>
      </div>
      {!_.isEmpty(stockItemGroupDepartmentNodeInSelector)
        ? !_.isEmpty(nodesNew) &&
          nodesNew.map((data: any) => (
            <div style={{ marginBottom: "8px" }}>
              {data.isGroup && handleTable(data)}
              {data.datas && handleTableItem(data.datas)}
            </div>
          ))
        : searchName && (
            <NoSalesFound
              title={"Stock Moment"}
              subTitle={"Try changing the search, to view your report"}
            />
          )}

      {/* {!_.isEmpty(stockItemGroupDepartmentNodeInSelector)? (
        <CardCommon>
          <Table
            data={nodes}
            layout={{ custom: maxWidthCustom, horizontalScroll: maxWidth }}
            style={{ zIndex: 0 }}
          >
            {(tableList) => (
              <>
                <Body>
                  {tableList.map((item, bodyIndex) => (
                    <Row
                      key={item.group}
                      item={item}
                      className={classes.headerRow}
                    >
                      <Cell pinLeft className={handleCellClass(item)}>
                        <Typography
                          variant="caption"
                          align="left"
                          className={classes.startTextStyle}
                        >
                          {item.group}
                        </Typography>
                      </Cell>
                      <Cell className={handleCellClass(item)}>
                        <Typography
                          variant="caption"
                          align="left"
                          className={classes.textStyle}
                        >
                          {item.department}
                        </Typography>
                      </Cell>
                      <Cell className={handleCellClass(item)}>
                        <Typography
                          variant="caption"
                          align="left"
                          className={classes.textStyle}
                        >
                          {item.item}
                        </Typography>
                      </Cell>
                      <Cell className={handleCellClass(item)}>
                        <Typography
                          variant="caption"
                          align="left"
                          className={classes.textStyle}
                        >
                          {item.unit}
                        </Typography>
                      </Cell>
                      <Cell className={handleCellClass(item)}>
                        <Typography
                          variant="caption"
                          align="left"
                          className={classes.textStyle}
                        >
                          {item.startStock}
                        </Typography>
                      </Cell>
                      {item.isGroup || item.isDepartment ? (
                        <Cell className={handleCellClass(item)}>
                          <Typography
                            variant="caption"
                            align="left"
                            className={classes.textStyle}
                          >
                            {item.sold}
                          </Typography>
                        </Cell>
                      ) : (
                        <Cell className={handleCellClass(item)}>
                          <Typography
                            variant="caption"
                            align="left"
                            className={classes.textStyle}
                            style={{
                              color: theme.palette.custom.red.main,
                              fontWeight: "bold",
                            }}
                          >
                            {item.sold}
                          </Typography>
                        </Cell>
                      )}
                      {item.isGroup || item.isDepartment ? (
                        <Cell className={handleCellClass(item)}>
                          <Typography
                            variant="caption"
                            align="left"
                            className={classes.textStyle}
                          >
                            {item.waste}
                          </Typography>
                        </Cell>
                      ) : (
                        <Cell className={handleCellClass(item)}>
                          <Typography
                            variant="caption"
                            align="left"
                            className={classes.textStyle}
                            style={{
                              color: theme.palette.custom.red.main,
                              fontWeight: "bold",
                            }}
                          >
                            {item.waste}
                          </Typography>
                        </Cell>
                      )}
                      {item.isGroup || item.isDepartment ? (
                        <Cell className={handleCellClass(item)}>
                          <Typography
                            variant="caption"
                            align="left"
                            className={classes.textStyle}
                          >
                            {item.returned}
                          </Typography>
                        </Cell>
                      ) : (
                        <Cell className={handleCellClass(item)}>
                          <Typography
                            variant="caption"
                            align="left"
                            className={classes.textStyle}
                            style={{
                              color: theme.palette.custom.green.main,
                              fontWeight: "bold",
                            }}
                          >
                            {item.returned}
                          </Typography>
                        </Cell>
                      )}
                      {item.isGroup || item.isDepartment ? (
                        <Cell className={handleCellClass(item)}>
                          <Typography
                            variant="caption"
                            align="left"
                            className={classes.textStyle}
                          >
                            {item.deliveredStock}
                          </Typography>
                        </Cell>
                      ) : (
                        <Cell className={handleCellClass(item)}>
                          <Typography
                            variant="caption"
                            align="left"
                            className={classes.textStyle}
                            style={{
                              color: theme.palette.custom.green.main,
                              fontWeight: "bold",
                            }}
                          >
                            {item.deliveredStock}
                          </Typography>
                        </Cell>
                      )}
                      <Cell className={handleCellClass(item)}>
                        <Typography
                          variant="caption"
                          className={classes.textStyle}
                          align="left"
                        > 
                          {item.adjustment}
                        </Typography>
                      </Cell>
                      <Cell className={handleCellClass(item)}>
                        <Typography
                          variant="caption"
                          className={classes.textStyleEnd}
                          align="left"
                        >
                          {item.endStock}
                        </Typography>
                      </Cell>
                    </Row>
                  ))}
                </Body>
              </>
            )}
          </Table>
        </CardCommon>
      ) : (
       searchName &&  <NoSalesFound title={"Stock Moment"} subTitle={"Try changing the search, to view your report"} />
      )} */}
    </>
  );
};

export default DailySaleReportInfoNode;
