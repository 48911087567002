import React, { useEffect, useState } from "react";
import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { useLocation, useRouteMatch } from "react-router";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import _ from "lodash";
import {
  fetchAllCancelSalesInfo,
  fetchAllUserInfo,
} from "../../../../services/salesApp/salesService";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import { getCookie } from "../../../../utils/cookies";
import { saleListFilterObject } from "../../../../utils/consts/list";
import SaleReport from "../../../../components/common/SaleReport";
import SaleList from "../../../../components/common/SaleList";
import { CustomTheme } from "../../../../types/customTheme";
import { useTheme } from "@material-ui/core";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";
import Pagination from "../../../../components/common/Pagination";

export interface SalesProps {}

/* Get the sales details using API call and get the location information and get the user information using locationId.
   Canceled sales details are filtered from the sales details retrieved from the API call. */
const Sales: React.FunctionComponent<SalesProps> = () => {
  const [salesNodeList, setSalesNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [headerName, setHeaderName] = useState("date");
  const [sortingMethod, setSortingMethod] = useState("DESC");
  const [disableButton, setDisableButton] = useState(false);
  const [pageSize, setPageSize] = useState("20");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterData, setFilterData] = useState("");
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [userList, setUserList] = useState<any>([]);
  const [userListFilter, setUserListFilter] = useState<any>([]);
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(true);
  const [filterDetails, setFilterDetails] = useState("");
  const [numberOfLocations, setNumberOfLocations] = useState(0);
  const [isPaymentTypeLoading, setIsPaymentTypeLoading] = useState(false);
  const [isDiscountLoading, setIsDiscountLoading] = useState(false);
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [locationSelectedList, setLocationSelectedList] = useState([]);
  const [totalResult, setTotalResult] = useState(0);

  const [isSort, setIsSort] = useState(false);

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  useEffect(() => {
    const locationId: any = params.get("locationId");
    if (!_.isEmpty(locationId)) {
      const numUniqueLocations = locationId.split(",");
      setNumberOfLocations(numUniqueLocations.length);
    }
  }, [params.get("locationId")]);

  /* Get sale list using query params */
  const getSaleFilterInfo = async (
    pageSize: any,
    currentPage: any,
    sortingMethod: any,
    headerName: any,
    filter: any,
  ) => {
    setPageSize(pageSize);
    setCurrentPage(currentPage);
    try {
      const res = await fetchAllCancelSalesInfo(
        idToken,
        match.params.locationId,
        10000,
        1,
        sortingMethod,
        headerName,
        filter,
      );
      // Filter the cancel sale list
      const filterCancelSaleList = res.data.data.filter(
        (saleDetails: any) => saleDetails.cancelled === true,
      );
      // Retrieving the canceled sales list to fit the page size.
      const saleList = filterCancelSaleList.filter(
        (data: any, index: number) =>
          index < currentPage * pageSize &&
          index >= (currentPage - 1) * pageSize,
      );
      setSalesNodeList(saleList);
      setTotalPages(Math.floor(filterCancelSaleList.length / pageSize) + 1);
      setTotalResult(filterCancelSaleList.length)
      setIsLoadingPage(false);
      setIsPaymentTypeLoading(false);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingPage(false);
    }
    setIsOpenSkeletonLoading(false);
    setIsPaymentTypeLoading(false);
  };

  /* The user details required for the filter and the user details required to be 
    displayed in the table are entered into the state. */
  const handleUserList = (userDetails: any) => {
    let userArray: any = [];
    let userObject: any = {};

    // Used the filter
    Object.values(userDetails).map((data: any) => {
      userArray.push({ id: data.id, label: data.name });
      if (!_.isEmpty(userList)) {
        if (_.isEmpty(userList[data.id])) {
          userObject[data.id] = { id: data.id, label: data.name };
        }
      } else {
        userObject[data.id] = { id: data.id, label: data.name };
      }
    });
    setUserListFilter(userArray);
    // Used the table
    setUserList((prevState: any) => ({ ...prevState, ...userObject }));
  };

  /* Get user details using API call */
  const getUserInfo = async (locationId: any) => {
    try {
      const res = await fetchAllUserInfo(idToken, locationId);
      if (!_.isEmpty(res.data.data)) {
        handleUserList(res.data.data);
      } else {
        setUserListFilter([]);
      }
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Get the all location details */
  const getAllFilterLocation = async (searchName: any) => {
    fetchAllFilterLocations()
      .then((res) => {
        let locationList: any = [];
        /* Setting up the list of locations as needed to select locations. */
        if (!_.isEmpty(res.data.data)) {
          res.data.data.map((location: any) =>
            locationList.push({
              id: location.id,
              label: location.businessDisplayName,
            }),
          );
          const locationName = getFilterListFromArrayObject(
            locationList,
            match.params.locationId,
          );
        }
        setLocationSelectorList(locationList);
        setIsGetLocationInfo(true);
      })
      .catch(() => {
        setIsLoadingPage(false);
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };

  useEffect(() => {
    document.title = "Sale - Sale Cancel";
    setIsLoadingPage(true);
    getAllFilterLocation("");
  }, []);

  //Handle cancel sale information using selector
  const handleChangePaginationUsingSelector = (pageSize: any) => {
    setIsOpenSkeletonLoading(true);
    getSaleFilterInfo(pageSize, 1, sortingMethod, headerName, filterData);
  };

  //Handle cancel sale information using page number
  const handleChangePaginationUsingPageNumber = (currentPage: any) => {
    setIsOpenSkeletonLoading(true);
    getSaleFilterInfo(
      pageSize,
      currentPage,
      sortingMethod,
      headerName,
      filterData,
    );
  };

  //sorting the cancel sale information using a header name
  const handleChangeOrderListSorting = (
    headerName: any,
    sortingMethod: any,
  ) => {
    setIsSort(true);
    setDisableButton(true);
    setHeaderName(headerName);
    setSortingMethod(sortingMethod);
    getSaleFilterInfo(
      pageSize,
      currentPage,
      sortingMethod,
      headerName,
      filterData,
    );
  };

  //Remove sorting method
  const handleRemoveOrderListSorting = () => {
    setIsSort(false);
    setDisableButton(false);
    setHeaderName("");
    setSortingMethod("");
    getSaleFilterInfo(pageSize, currentPage, "", "", filterData);
  };

  // Get filter data
  const handleFilterData = (filterData: any) => {
    setFilterData(filterData);
    setIsOpenSkeletonLoading(true);
    getSaleFilterInfo(20, 1, sortingMethod, headerName, filterData);
  };

  // Get filter data
  const handleGetFilterData = () => {
    getSaleFilterInfo(
      pageSize,
      currentPage,
      sortingMethod,
      headerName,
      filterData,
    );
  };

  const handleOnClickText = () => {
    setOpenFilterCard(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /*Get location list API call after typing. */
  const handleLocationSelectorTypingList = (searchName: any) => {};

  const theme: CustomTheme = useTheme();

  return (
    <>
      <SaleReport
        handleFilterData={handleFilterData}
        locationSelectorList={locationSelectorList}
        handleLocationSelectorTypingList={handleLocationSelectorTypingList}
        isGetLocationInfo={isGetLocationInfo}
        getUserInfo={getUserInfo}
        userListFilter={userListFilter}
        setFilterDetails={setFilterDetails}
        availableFilter={saleListFilterObject}
        isOpenSkeletonLoading={isOpenSkeletonLoading}
        nodeList={salesNodeList}
        filterDetails={filterDetails}
        title={""}
        isChart={false}
        isUserGet={true}
        isSale={true}
        locationSelectedList={locationSelectedList}
        setLocationSelectedList={setLocationSelectedList}
        openFilterCard={openFilterCard}
        setOpenFilterCard={setOpenFilterCard}
        handleOnClickText={handleOnClickText}
        topic="Cancel Sales Transactions"
        isReport={false}
      >
        <SaleList
          handleChangeOrderListSorting={handleChangeOrderListSorting}
          handleRemoveOrderListSorting={handleRemoveOrderListSorting}
          nodeData={salesNodeList}
          disableButton={disableButton}
          isLoading={isLoadingPage}
          locationSelectorList={locationSelectorList}
          userList={userList}
          numberOfLocations={numberOfLocations}
          getUserInfo={getUserInfo}
          getSaleFilterInfo={handleGetFilterData}
          isPaymentTypeLoading={isPaymentTypeLoading}
          setIsPaymentTypeLoading={setIsPaymentTypeLoading}
          setIsDiscountLoading={setIsDiscountLoading}
          isDiscountLoading={isDiscountLoading}
          locationSelectedList={locationSelectedList}
          handleOnClickText={handleOnClickText}
          isSort={isSort}
          headerName={headerName}
          sortingMethod={sortingMethod}
        />
        {!_.isEmpty(salesNodeList) && (
          <Pagination
            handleChangePaginationUsingSelector={
              handleChangePaginationUsingSelector
            }
            handleChangePaginationUsingPageNumber={
              handleChangePaginationUsingPageNumber
            }
            totalPages={totalPages}
            currentPage={currentPage}
            pageSize={pageSize}
            totalResult={totalResult}
          />
        )}
      </SaleReport>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(Sales, Authorities.SALE_READ);
