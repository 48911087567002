import { Box, Hidden } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import _ from "lodash";

import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import withAuthority from "../../../../components/Auth/withAuthority";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import Loading from "./Loading";
import {
  fetchGlobalDepartmentInfo,
  fetchGlobalGroupInfo,
  fetchGlobalProductsInfo,
} from "../../../../services/menuApp/printerMenuService";
import PageHeaderMobile from "../../../../components/common/PageHeader/PageHeaderMoblie";

export interface BannersProps {}

/* Get selected menu information, category menu details and location information using API call at initial load. */
const PrinterMapping: React.FunctionComponent<BannersProps> = () => {
  const [menuNodeList, setMenuNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [linkedIdList, setLinkedIdList] = useState<any>([]);

  const match: any = useRouteMatch();

  /* Get all menu option information using API call and response data set to menuOptionNodeList state. */
  const getGroupInfo = async () => {
    try {
      const res = await fetchGlobalGroupInfo(match.params.locationId);
      getDepartmentInfo(res.data.data);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Get all category menu information using API call and response data set to menuNodeList state. */
  const getDepartmentInfo = async (groupNode: any) => {
    try {
      const res = await fetchGlobalDepartmentInfo(match.params.locationId);
      getProductInfo(groupNode, res.data.data);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  const getProductInfo = async (groupNode: any, departmentNode: any) => {
    try {
      const res = await fetchGlobalProductsInfo(match.params.locationId);
      const menu = await handleMenuRestructure(
        groupNode,
        departmentNode,
        res.data.data,
      );
      setMenuNodeList(menu);
      setIsLoading(false);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  const handleMenuRestructure = (
    groupNode: any,
    departmentNode: any,
    productNode: any,
  ) => {
    if (
      !_.isEmpty(groupNode) &&
      !_.isEmpty(departmentNode) &&
      !_.isEmpty(productNode)
    ) {
      const filterDepartment = departmentNode.sort(
        (a: any, b: any) => a.viewOrder - b.viewOrder,
      );
      const filterProduct = productNode.sort(
        (a: any, b: any) => a.viewOrder - b.viewOrder,
      );
      const filterGroup = groupNode.sort(
        (a: any, b: any) => a.viewOrder - b.viewOrder,
      );
      const linkedId: any = [];
      const output: any = [];
      filterGroup.forEach((groupItem: any) => {
        const groupObject: any = {
          ...groupItem,
          department: [],
        };
        const matchingDepartments = filterDepartment.filter(
          (departmentItem: any) =>
            departmentItem.groupId === groupItem.id &&
            departmentItem.name !== "Online Hidden" &&
            departmentItem.name !== "Templates" &&
            departmentItem.name !== "Hidden Food" &&
            departmentItem.name !== "Hidden Drinks",
        );
        matchingDepartments.forEach((departmentItem: any) => {
          const departmentObject: any = {
            ...departmentItem,
            product: {},
          };
          const matchingProducts = filterProduct.filter(
            (productItem: any) =>
              productItem.departmentId === departmentItem.id,
          );
          matchingProducts.forEach((productItem: any) => {
            if (!departmentObject.product[productItem.linkedId]) {
              departmentObject.product[productItem.linkedId] = [];
            } else {
              linkedId.push(
                `${productItem.departmentId}*${productItem.linkedId}`,
              );
            }
            departmentObject.product[productItem.linkedId].push(productItem);
          });
          // Convert object to array of key-value pairs
          // Extract keys and values into separate arrays
          const keys = Object.keys(departmentObject.product);
          const values = Object.values(departmentObject.product);

          // Sort the values array based on the viewOrder property of the first object in each array
          values.sort((a: any, b: any) => a[0].viewOrder - b[0].viewOrder);

          // Create a new object using the sorted keys and values
          const outputs: any = {};
          keys.forEach((key, index) => {
            outputs[key] = values[index];
          });
          departmentObject.product = outputs;
          groupObject.department.push(departmentObject);
        });
        output.push(groupObject);
      });
      setLinkedIdList(linkedId);
      return output;
    }
  };

  useEffect(() => {
    document.title = "Menu - Menu Printer Information";
    getGroupInfo();
    setIsLoading(true);
  }, []);

  return (
    <>
      <Hidden lgUp>
        <PageHeaderMobile isNeedFixed={true} />
      </Hidden>
      <Box>
        <Loading
          isLoading={isLoading}
          menuNodeList={menuNodeList}
          linkedIdList={linkedIdList}
        />
      </Box>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(PrinterMapping, Authorities.MENU_READ);
