import React from "react";
import {
  Grid,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import _ from "lodash";

import { CustomTheme } from "../../../../../types/customTheme";
import TextfieldCommon from "../../../../../components/textField/TextfieldCommon";
import TextFieldCustom from "../../../../../components/textField/TextdieldCustom";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    labelStyle: {
      fontSize: "17px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "15px",
      },
    },
  }),
);

export interface WizardModalProps {
  name: any;
  setName: any;
  barcode: any;
  setBarcode: any;
  sku: any;
  setSku: any;
}

const StepSeven: React.FunctionComponent<WizardModalProps> = ({
  name,
  setName,
  barcode,
  setBarcode,
  sku,
  setSku,
}) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h6">
        Please add the following for the item name, barcode and SKU textbox
        screen
      </Typography>
      <Grid container style={{ marginTop: "20px" }}>
        <Grid item xs={12}>
          <TextFieldCustom
            id="name"
            name="name"
            type="text"
            label={<Typography className={classes.labelStyle}>Name</Typography>}
            value={name}
            disabled={false}
            onChange={(e: any) => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} style={{marginTop: "8px"}}>
          <TextFieldCustom
            id="barcode"
            name="barcode"
            type="text"
            label={
              <Typography className={classes.labelStyle}>Barcode</Typography>
            }
            value={barcode}
            disabled={false}
            onChange={(e: any) => setBarcode(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} style={{marginTop: "8px"}}>
          <TextFieldCustom
            id="sku"
            name="sku"
            type="text"
            label={<Typography className={classes.labelStyle}>Sku</Typography>}
            value={sku}
            disabled={false}
            onChange={(e: any) => setSku(e.target.value)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default StepSeven;
