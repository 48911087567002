import React from "react";
import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import _ from "lodash";

import SortingMethod from "../../../../components/common/SortingMethod/SortingMethod";
import WithLoading from "../../../../utils/WithLoading";
import CardCommon from "../../../../components/card/CardCommon";
import StockItemInfoNode from "./StockItemInfoNode";

export interface OrdersHeaderProps {
  nodeData: any;
  handleChangeOrderListSorting: any;
  handleRemoveOrderListSorting: any;
  disableButton: any;
  isLoading: any;
  handleOpenAddStockOrderItemModal: any;
  orderId: any;
  setOrderId: any;
  isSort: any;
  headerName: any;
  sortingMethod: any;
  supplierNode: any;
  openFilterCard: any;
  handleOpenStockDeliveryItemModal: any;
  setSelectedSupplierName: any
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    headerContainerStyle: {
      marginTop: "16px",
      [theme.breakpoints.down("sm")]: {
        visibility: "hidden",
      },
    },
    headerContainerStyleOpen: {
      marginTop: "0px",
      [theme.breakpoints.down("sm")]: {
        visibility: "hidden",
      },
    },
    headerItemStyle: {
      display: "flex",
      justifyContent: "center",
      placeItems: "center",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "20px",
      },
      [theme.breakpoints.down("xs")]: {
        paddingRight: "5px",
        paddingLeft: "0px",
      },
    },
    headerFirstItemStyle: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      paddingLeft: "9px",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "5px",
      },
    },
    headerItemStyleLastColumn: {
      display: "flex",
      justifyContent: "flex-end",
      placeItems: "center",
      paddingRight: "52px",
      [theme.breakpoints.down("xs")]: {
        paddingRight: "5px",
      },
    },
  }),
);

/* This is stock order Table header page. In addition, Until the required data for the stock order 
   table is received from the API call, this will show the loading of the skeleton. */
const StockOrdersHeader: React.FunctionComponent<OrdersHeaderProps> = ({
  nodeData,
  handleChangeOrderListSorting,
  handleRemoveOrderListSorting,
  disableButton,
  isLoading,
  handleOpenAddStockOrderItemModal,
  isSort,
  headerName,
  sortingMethod,
  supplierNode,
  openFilterCard,
  handleOpenStockDeliveryItemModal,
  setSelectedSupplierName
}) => {
  const classes = useStyles();
  return (
    <>
      <div
        className={
          openFilterCard
            ? classes.headerContainerStyleOpen
            : classes.headerContainerStyle
        }
      >
        <CardCommon backgroundColor={"table_header_background"}>
          <Grid container style={{ minHeight: "36px" }}>
            <Grid item xs={3} className={classes.headerFirstItemStyle}>
              <SortingMethod
                handleChangeOrderListSorting={handleChangeOrderListSorting}
                handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                headerName={"Supplier"}
                headerId={"supplierId"}
                disableButton={disableButton}
                color={"orange"}
                isSort={headerName === "supplierId" ? isSort : false}
                sortingMethod={headerName === "supplierId" ? sortingMethod : ""}
              />
            </Grid>
            <Grid item xs={2} className={classes.headerItemStyle}>
              <SortingMethod
                handleChangeOrderListSorting={handleChangeOrderListSorting}
                handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                headerName={"Created On"}
                headerId={"created"}
                disableButton={disableButton}
                color={"orange"}
                isSort={headerName === "created" ? isSort : false}
                sortingMethod={headerName === "created" ? sortingMethod : ""}
              />
            </Grid>
            <Grid item xs={2} className={classes.headerItemStyle}>
              <SortingMethod
                handleChangeOrderListSorting={handleChangeOrderListSorting}
                handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                headerName={"Ordered On"}
                headerId={"orderedOn"}
                disableButton={disableButton}
                color={"orange"}
                isSort={headerName === "orderedOn" ? isSort : false}
                sortingMethod={headerName === "orderedOn" ? sortingMethod : ""}
              />
            </Grid>
            <Grid item xs={2} className={classes.headerItemStyle}>
              <SortingMethod
                handleChangeOrderListSorting={handleChangeOrderListSorting}
                handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                headerName={"Delivered On"}
                headerId={"deliveredOn"}
                disableButton={disableButton}
                color={"orange"}
                isSort={headerName === "deliveredOn" ? isSort : false}
                sortingMethod={headerName === "deliveredOn" ? sortingMethod : ""}
              />
            </Grid>
            <Grid item xs={3} className={classes.headerItemStyleLastColumn}>
              <SortingMethod
                handleChangeOrderListSorting={handleChangeOrderListSorting}
                handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                headerName={"Status"}
                headerId={"status"}
                disableButton={disableButton}
                color={"orange"}
                isSort={headerName === "status" ? isSort : false}
                sortingMethod={headerName === "status" ? sortingMethod : ""}
              />
            </Grid>
          </Grid>
        </CardCommon>
      </div>
      <Grid container style={{ marginTop: "1px" }}>
        {nodeData.map((nodeData: any) => (
          <Grid
            item
            xs={12}
            key={nodeData.id}
            style={{ placeItems: "center", marginTop: "4px" }}
          >
            <StockItemInfoNode
              nodeData={nodeData}
              handleOpenAddStockOrderItemModal={
                handleOpenAddStockOrderItemModal
              }
              supplierNode={supplierNode}
              handleOpenStockDeliveryItemModal={
                handleOpenStockDeliveryItemModal
              }
              setSelectedSupplierName={setSelectedSupplierName}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default WithLoading(StockOrdersHeader);
