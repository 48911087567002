import React from "react";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import _ from "lodash";

import { CustomTheme } from "../../../../../types/customTheme";
import CardCommon from "../../../../../components/card/CardCommon";
import { CapitalizeFirstLetter } from "../../../../../utils/ReplaceIcon";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    textField: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      borderRadius: "10px",
      border: "none",
      marginRight: "8px",
      maxWidth: "200px",
      backgroundColor: theme.palette.background.entity_highlight_background,
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    supplierTitleStyle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "20px 0px",
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
    },
  }),
);

export interface WizardModalProps {
  supplierDetailsNode: any;
  selectedSupplierList: any;
  handleSelectedSupplier: any;
  handleChangeSearchSupplier: any;
  searchName: any;
  isSearch: any;
  handleClearSearchSupplier: any;
  handleOpenSupplierAddModal: any;
}

const StepTwo: React.FunctionComponent<WizardModalProps> = ({
  supplierDetailsNode,
  selectedSupplierList,
  handleSelectedSupplier,
  handleChangeSearchSupplier,
  searchName,
  isSearch,
  handleClearSearchSupplier,
  handleOpenSupplierAddModal,
}) => {
  /**
   * Determines the color for a supplier based on its ID.
   * If the supplier is selected (in the selectedSupplierList), it returns "green".
   * @param {string} id - The ID of the supplier.
   * @returns {string} - The color string, "green" if selected, undefined otherwise.
   */
  const determineSupplierColor = (id: string) => {
    if (selectedSupplierList.includes(id)) {
      return "green"; // Selected supplier is shown in green.
    }
    // Return undefined for non-selected suppliers.
  };

  const classes = useStyles();
  const theme: CustomTheme = useTheme();
  return (
    <>
      <Typography variant="h6">
        Please select one or more suppliers for these stock items
      </Typography>
      <Typography variant="body1">
        Assigning a supplier will simplify future stock ordering processes from
        them
      </Typography>
      <Grid container>
        <Grid item xs={12} className={classes.supplierTitleStyle}>
          <TextField
            classes={{ root: classes.textField }}
            id="outlined-inline-static"
            placeholder="Search Supplier"
            variant="outlined"
            onChange={(e: any) => handleChangeSearchSupplier(e.target.value)}
            value={searchName}
            style={{
              height: "40px",
              marginTop: "4px",
              marginBottom: "4px",
              width: "!00%",
            }}
            fullWidth
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={handleClearSearchSupplier}
                  >
                    {isSearch ? <ClearIcon /> : <SearchIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            style={{ textTransform: "none" }}
            color="secondary"
            autoFocus
            // disabled={checkedProductIdList.length === 0 || result}
            onClick={handleOpenSupplierAddModal}
          >
            {"Add new supplier"}
          </Button>
        </Grid>
        {!_.isEmpty(supplierDetailsNode) ? (
          supplierDetailsNode.map((supplier: any) => (
            <Grid item xs={6} sm={4} style={{ padding: "4px" }}>
              <CardCommon
                color={determineSupplierColor(supplier.id)}
                backgroundColor={"entity_highlight_background"}
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  fullWidth
                  onClick={() => handleSelectedSupplier(supplier.id)}
                  style={{
                    textTransform: "none",
                    display: "flex",
                    alignItems: "center",
                    padding: "4px",
                    minHeight: "80px",
                    backgroundColor: determineSupplierColor(supplier.id)
                      ? theme.palette.custom.green.main
                      : theme.palette.background.entity_highlight_background,
                  }}
                >
                  <Typography
                    style={
                      determineSupplierColor(supplier.id)
                        ? {
                            margin: "4px",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                          }
                        : {
                            margin: "4px",
                            display: "flex",
                            alignItems: "center",
                          }
                    }
                  >
                    {CapitalizeFirstLetter(supplier.name)}
                  </Typography>
                </Button>
              </CardCommon>{" "}
            </Grid>
          ))
        ) : (
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                marginTop: "40px",
                marginBottom: "40px",
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Typography variant="h5">No supplier available.</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default StepTwo;
