import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import PrintIcon from "@material-ui/icons/Print";

import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import CategoryMenuList from "./CategoryMenuList";
import CategoryMenuDropDown from "./CategoryMenuDropDown";
import CatMenuPopUp from "./CatMenuPopUp";
import CardCommon from "../../../../components/card/CardCommon";
import CategoryMenuListStrip from "./CategoryMenuListStrip";
import MappingModal from "./Modal/MappingModal";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      zIndex: 5,
      marginTop: "54px",
      [theme.breakpoints.up("sm")]: {
        marginTop: "62px",
      },
      [theme.breakpoints.up("1280")]: {
        marginTop: "8px",
      },
    },
    categoryTitle: {
      backgroundColor: theme.palette.background.default,
      position: "sticky",
      top: 194,
      zIndex: 1,
      fontSize: "20px",
      [theme.breakpoints.up("sm")]: {
        top: 200,
        paddingTop: "20px",
        fontSize: "32px",
      },
      [theme.breakpoints.up("1280")]: {
        fontSize: "32px",
        top: 62,
        paddingTop: "52px",
      },
    },
    boxStyleOffline: {
      position: "fixed",
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: theme.palette.background.default,
      right: 10,
      left: 10,
      zIndex: 50,
    },
    boxStyleOfflineLargeScreen: {
      position: "fixed",
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: theme.palette.background.default,
      right: 10,
      zIndex: 50,
    },
  }),
);

export interface MenuInfoPageProps {
  menuNodeList: any;
  linkedIdList: any;
}

const MenuInfoPage: React.SFC<MenuInfoPageProps> = ({
  menuNodeList,
  linkedIdList,
}) => {
  const [departmentList, setDepartmentList] = useState<any>([]);
  const [selectedCatMenuTitle, setSelectedCatMenuTitle] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [removedLinkedIdList, setRemovedLinkedIdList] = useState<any>([]);
  const [isColorChange, setIsColorChange] = useState("");
  const [isOpenMappingModal, setIsOpenMappingModal] = useState(false);
  const [error, setError] = useState("");
  const [printerMappingItemName, setPrinterMappingItemName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [
    selectedPrinterMappingItemOrCategoryId,
    setSelectedPrinterMappingItemOrCategoryId,
  ] = useState("");
  const [type, setType] = useState("");
  const [revertExpand, setRevertExpand] = useState("");

  /* After selecting the cat menu, enter cat menu id and cat menu name into state. */
  const handleChangeCatMenu = (event: any) => {
    setSelectedCatMenuTitle(event.name);
    const filterDepartment = menuNodeList.filter(
      (data: any) => data.id === event.id,
    );
    setDepartmentList(filterDepartment[0].department);
  };

  useEffect(() => {
    if (!_.isEmpty(menuNodeList)) {
      const menu: any = Object.values(menuNodeList);
      setSelectedCatMenuTitle(menu[0].name);
      const filterDepartment = menu[0].department;
      setDepartmentList(filterDepartment);
    }
  }, [menuNodeList]);

  const handleClickLinkedId = (e: any) => {
    const filterId: any = [];
    e.map((data: any) => {
      filterId.push(`${data.departmentId}*${data.linkedId}`);
    });
    const removeId = linkedIdList.filter((data: any) =>
      filterId.includes(data),
    );
    setRevertExpand(filterId[0]);

    setRemovedLinkedIdList((prevList: any) => [...prevList, ...removeId]);
  };

  const handleUpdateIdList = (event: any, product: any) => {
    if (event) {
      event.stopPropagation();
    }
    const removeId = removedLinkedIdList.filter(
      (data: any) => data !== `${product.departmentId}*${product.linkedId}`,
    );
    setIsOpenMappingModal(false);
    setRemovedLinkedIdList(removeId);
    setIsColorChange(`${product.departmentId}*${product.linkedId}`);
  };

  const handleOpenMappingModal = (
    event: any,
    category: any,
    item: any,
    categoryOrItem: string,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setCategoryName(category.name);
    setPrinterMappingItemName(item.name);
    setSelectedPrinterMappingItemOrCategoryId(item.id);
    setType(categoryOrItem);
    setIsOpenMappingModal(true);
  };

  const classes = useStyles();
  const maxWidthCustom = useMediaQuery("(max-width: 1280px)");
  return (
    <>
      <Grid container spacing={2} className={classes.root}>
        {!_.isEmpty(menuNodeList) && (
          <Grid
            item
            xs={12}
            className={
              maxWidthCustom
                ? classes.boxStyleOffline
                : classes.boxStyleOfflineLargeScreen
            }
          >
            <div
              style={
                maxWidthCustom
                  ? { display: "flex", justifyContent: "end", width: "100%" }
                  : { display: "flex" }
              }
            >
              {selectedCatMenuTitle && (
                <CategoryMenuDropDown
                  categoryMenuList={menuNodeList}
                  selectedCatMenuTitle={selectedCatMenuTitle}
                  handleChangeCatMenu={handleChangeCatMenu}
                />
              )}

              {departmentList && (
                <CatMenuPopUp
                  setAnchorEl={setAnchorEl}
                  anchorEl={anchorEl}
                  departmentList={departmentList}
                />
              )}
            </div>
          </Grid>
        )}
      </Grid>
      {!_.isEmpty(menuNodeList) ? (
        departmentList.map((category: any, index: any) => {
          return (
            <div>
              <Typography
                align="left"
                className={classes.categoryTitle}
                id={category.name}
              >
                {category.name}
                <Button
                  style={{ marginLeft: "8px" }}
                  onClick={(event) => {
                    handleOpenMappingModal(
                      event,
                      category,
                      category,
                      "category",
                    );
                  }}
                >
                  <PrintIcon />
                </Button>
              </Typography>
              <Grid
                container
                spacing={2}
                style={{
                  marginTop: index === 0 && maxWidthCustom ? "70px" : "0px",
                }}
              >
                {Object.values(category.product).map((data: any) =>
                  data.length === 1 ||
                  removedLinkedIdList.includes(
                    `${data[0].departmentId}*${data[0].linkedId}`,
                  ) ? (
                    data.map((data1: any, index: any) => (
                      <Grid
                        item
                        md={6}
                        xs={12}
                        lg={4}
                        style={{ marginTop: "10px", marginBottom: "6px" }}
                      >
                        <CardCommon
                          backgroundColor={"default"}
                          style={{ borderRadius: "10px" }}
                        >
                          <CategoryMenuList
                            item={data1}
                            removedLinkedIdList={removedLinkedIdList}
                            handleUpdateIdList={handleUpdateIdList}
                            index={index}
                            data={data}
                            isColorChange={isColorChange}
                            revertExpand={revertExpand}
                            handleOpenMappingModal={handleOpenMappingModal}
                            category={category}
                          />
                        </CardCommon>
                      </Grid>
                    ))
                  ) : (
                    <Grid item md={6} xs={12} lg={4}>
                      <div style={{ marginTop: "14px", marginBottom: "6px" }}>
                        <CategoryMenuListStrip
                          item={data}
                          handleClickLinkedId={handleClickLinkedId}
                          isColorChange={isColorChange}
                        />
                      </div>
                    </Grid>
                  ),
                )}
              </Grid>
            </div>
          );
        })
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "16%",
          }}
        >
          <Typography variant="h3">No Menus Available.</Typography>
        </div>
      )}
      {isOpenMappingModal && (
        <MappingModal
          isOpen={isOpenMappingModal}
          setIsOpen={setIsOpenMappingModal}
          setError={setError}
          printerMappingItemName={printerMappingItemName}
          selectedPrinterMappingItemOrCategoryId={
            selectedPrinterMappingItemOrCategoryId
          }
          type={type}
          selectedCatMenuTitle={selectedCatMenuTitle}
          categoryName={categoryName}
        />
      )}
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(MenuInfoPage, Authorities.MENU_READ);
